import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Formik, Form } from "formik";
import { gql, useQuery, useMutation } from "urql";
import { Dropdown, DropdownButton, Alert } from "react-bootstrap";
import { startCase, sortBy, cloneDeep } from "lodash";
import { Link, useNavigate, useLocation } from "react-router-dom";
import stableStringify from "json-stable-stringify";

import { recursivelyRemoveKey } from "../../../../common/src";

import { useAlertTimeOut, usePrevious } from "../../common/Utils";
import { SubmitButton, TextField, SelectField, MultipleSelectField, NumberField, CheckBoxField } from "../../components/form";
import { AttachmentForm } from "../AttachmentForm";
import { AgreementInput, AgreementType, CurrencyEnum, PartyExternalAccountType, PartyType } from "../../types.generated";
import { emptyObjectId } from "../../../../common/src";
import { useUpsertAgreementsMutation } from "../AgreementPage.generated";
import { formikUrqlErrorFormater } from "../../common/formik-urql-error-helper";

export const externalAccountTypes = Object.values(PartyExternalAccountType);

const GET_CUSTODY_ACCOUNT = gql`
    query getPartyExternalAccount($cid: GraphQLObjectId, $id: GraphQLObjectId) {
        partyExternalAccount(clientId: $cid, externalAccountId: $id) {
            _id
            name
            custodian {
                _id
                accounts {
                    _id
                    name
                }
            }
            variationMarginCurrencies
            initialMarginCurrencies
            principalCurrencies
            custodianAccountNumber
            custodianId
            description
            emirReporting
            roundingAmount
            minimumTransferAmount
            noCollateralRequirement
            threshold
            type
            settlementOnVMInstrument
            separatedInitialMargin
            baseCurrency
            clearingHousesIds
            clearingHouses {
                _id
                name
            }
            clearingBroker {
                _id
                name
            }
            clearingBrokerId
            agreement {
                _id
                attachments {
                    fileId
                    clientId
                    fileName
                    mimeType
                    mD5
                    updateTimestamp
                }
                client {
                    _id
                    name
                }
                clientId
                counterParties {
                    _id
                    name
                }
                counterPartyIds
                description
                parties {
                    _id
                    name
                }
                partyIds
                status
                type
            }
        }
    }
`;

const GET_DATA = gql`
    query getData($_id: GraphQLObjectId) {
        client: party(_id: $_id) {
            name
            _id
            types
            instruments {
                _id
                name
                longName
                externalAccountId
                currency
                productType
            }
            accounts {
                _id
                name
            }
        }
        custodians: parties(filter: { typeIn: [Custodian] }) {
            name
            _id
            accounts {
                _id
                name
            }
        }
        clearingHouses: parties(filter: { typeIn: [ClearingHouse] }) {
            name
            _id
        }
        clearingBrokers: parties(filter: { typeIn: [ClearingBroker] }) {
            name
            _id
        }
    }
`;

const CREATE_UPDATE_CUSTODY_ACCOUNT = gql`
    mutation CreateUpdateExternalAccount($input: CreateUpdateExternalAccountInput!, $partyId: GraphQLObjectId!) {
        createUpdateExternalAccount(input: $input, partyId: $partyId) {
            _id
            name
            custodian {
                _id
                accounts {
                    _id
                    name
                }
            }
            variationMarginCurrencies
            initialMarginCurrencies
            principalCurrencies
            custodianAccountNumber
            custodianId
            description
            emirReporting
            roundingAmount
            minimumTransferAmount
            noCollateralRequirement
            threshold
            type
            settlementOnVMInstrument
            separatedInitialMargin
            baseCurrency
            clearingHousesIds
            clearingHouses {
                _id
                name
            }
            clearingBroker {
                _id
                name
            }
            clearingBrokerId
            agreement {
                _id
                attachments {
                    fileId
                    clientId
                    fileName
                    mimeType
                    mD5
                    updateTimestamp
                }
                client {
                    _id
                    name
                }
                clientId
                counterParties {
                    _id
                    name
                }
                counterPartyIds
                description
                parties {
                    _id
                    name
                }
                partyIds
                status
                type
            }
        }
    }
`;

function resetValuesFunc(type) {
    return {
        _id: null,
        custodianAccountNumber: "",
        custodianId: emptyObjectId,
        clearingBrokerId: emptyObjectId,
        emirReporting: false,
        roundingAmount: 0,
        minimumTransferAmount: 0,
        noCollateralRequirement: false,
        threshold: 0,
        type,
        variationMarginCurrencies: [],
        principalCurrencies: [],
        initialMarginCurrencies: [],
        create: null,
        clearingHousesIds: [],
        settlementOnVMInstrument: false,
        separatedInitialMargin: false,
        baseCurrency: "SEK",
        agreement: { attachments: [] }
    };
}

interface PropsType {
    id?: string;
    cid?: string;
    type?: any;
    // eslint-disable-next-line @typescript-eslint/ban-types
    onCreate?: Function;
    // eslint-disable-next-line @typescript-eslint/ban-types
    onUpdate?: Function;
}

export function ExternalAccountForm(props: PropsType): React.ReactElement {
    const previousProps = usePrevious(props);

    const id = props.id ? props.id : emptyObjectId;
    const cid = props.cid ? props.cid : emptyObjectId;

    const navigate = useNavigate();
    const location = useLocation();
    const [{ fetching: getExternalAccountLoading, error: getExternalAccountError, data: getExternalAccountData }, refetch] = useQuery({
        query: GET_CUSTODY_ACCOUNT,
        variables: { cid: cid, id: id },
        requestPolicy: "network-only",
        pause: !id
    });
    const [{ fetching: loading, error: getDataError, data }] = useQuery({
        query: GET_DATA,
        requestPolicy: "network-only",
        variables: { _id: cid }
    });

    const [_, upsertMutation] = useUpsertAgreementsMutation();

    const [values, setValues] = useState(null);
    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    useAlertTimeOut(alert, setAlert, 5);

    const [__stateCreateUpdate, createUpdateExternalAccount] = useMutation(CREATE_UPDATE_CUSTODY_ACCOUNT);

    const callBackOnChangeAttachments = useCallback(
        (attachments) => {
            if (
                getExternalAccountData &&
                getExternalAccountData.partyExternalAccount &&
                getExternalAccountData.partyExternalAccount.agreement
            ) {
                const agreement = getExternalAccountData.partyExternalAccount.agreement;
                attachments = recursivelyRemoveKey(attachments, "__typename");
                const input: AgreementInput = {
                    _id: agreement._id,
                    clientId: agreement.clientId,
                    counterPartyIds: agreement.counterPartyIds,
                    description: agreement.description,
                    partyIds: agreement.partyIds,
                    status: agreement.status,
                    type: agreement.type,
                    attachments: attachments
                };
                upsertMutation({ input: input }).then(() => {
                    refetch();
                });
            }
        },
        [getExternalAccountData, refetch, upsertMutation]
    );

    useEffect(() => {
        if (previousProps && stableStringify(props) !== stableStringify(previousProps)) {
            setValues(null);
        } else if (values === null && !props.id) {
            setValues(resetValuesFunc(props.type));
        } else if (values === null && getExternalAccountData && !getExternalAccountLoading && !getExternalAccountError) {
            setValues(getExternalAccountData.partyExternalAccount);
        } else if (
            values &&
            values._id &&
            getExternalAccountData &&
            getExternalAccountData.partyExternalAccount &&
            values.agreement &&
            getExternalAccountData.partyExternalAccount.agreement &&
            values.agreement.attachments.length !== getExternalAccountData.partyExternalAccount.agreement.attachments.length
        ) {
            setValues(getExternalAccountData.partyExternalAccount);
        }
    }, [getExternalAccountData, getExternalAccountError, getExternalAccountLoading, previousProps, props, values]);
    if (loading || getExternalAccountLoading) return <div>Loading</div>;

    if (getExternalAccountError) return <div>{"Error: " + getExternalAccountError.message}</div>;

    if (getDataError) return <div>{"Error: " + getDataError.message}</div>;

    if (values === null || !values || (values && !externalAccountTypes.includes(values.type))) return <div></div>;

    const allData = cloneDeep(data);

    let custodiansAndClient =
        allData.client && allData.client.types && allData.client.types.includes(PartyType.Custodian)
            ? allData.custodians
            : allData.custodians.concat([allData.client]);
    custodiansAndClient = sortBy(custodiansAndClient, "name");
    custodiansAndClient.unshift({ _id: emptyObjectId, name: "None" });

    const clearingHouses = sortBy(allData.clearingHouses, "name");
    clearingHouses.unshift({ _id: emptyObjectId, name: "None" });

    const clearingBrokers = sortBy(allData.clearingBrokers, "name");
    clearingBrokers.unshift({ _id: emptyObjectId, name: "None" });

    const currencies = Object.keys(CurrencyEnum).sort();
    currencies.sort((d1, d2) => (d1 < d2 ? -1 : 1));

    const createUpdateInput = [
        "_id",
        "custodianId",
        "clearingBrokerId",
        "variationMarginCurrencies",
        "principalCurrencies",
        "initialMarginCurrencies",
        "minimumTransferAmount",
        "noCollateralRequirement",
        "threshold",
        "roundingAmount",
        "emirReporting",
        "custodianAccountNumber",
        "type",
        "create",
        "clearingHousesIds",
        "settlementOnVMInstrument",
        "separatedInitialMargin",
        "baseCurrency",
        "agreement"
    ];

    return (
        <div id="externalaccountform" className="externalaccountform form">
            <Formik
                enableReinitialize={true}
                validateOnMount={true}
                initialValues={values}
                validate={(validateValues) => {
                    validateValues.clientId = props.cid;

                    const { type } = values;
                    const errors = {};

                    const requiredFields = ["type", "custodianId"];
                    if (!validateValues.variationMarginCurrencies && type === PartyExternalAccountType.Isda) {
                        requiredFields.push("variationMarginCurrencies");
                    }

                    if (!validateValues.custodianAccountNumber && type === PartyExternalAccountType.SecuritiesAccount) {
                        requiredFields.push("custodianAccountNumber");
                    }

                    if (type === PartyExternalAccountType.ClearingAccount || type === PartyExternalAccountType.OmnibusClearingAccount) {
                        requiredFields.push("custodianAccountNumber");
                    }

                    if (type === PartyExternalAccountType.OmnibusClearingAccount) {
                        requiredFields.push("clearingHousesIds");
                    }

                    if (type === PartyExternalAccountType.ClearingAccount) {
                        requiredFields.push("clearingBrokerId");
                    }

                    requiredFields.forEach((d) => {
                        if (
                            !validateValues[d] ||
                            validateValues[d] === emptyObjectId ||
                            (Array.isArray(validateValues[d]) && validateValues[d].length < 1)
                        ) {
                            errors[d] = "Required";
                        }
                    });
                    //console.log("validateValues", validateValues);
                    return Object.keys(errors).length > 0 ? errors : {};
                }}
                onSubmit={async (submitValues, { setSubmitting, setErrors }) => {
                    //console.log("submitValues: ", submitValues);
                    const input = { ...submitValues };
                    const partyId = input.clientId;
                    delete input.clientId;
                    //Remove fields from values that are not relevant

                    for (const field of Object.keys(input)) {
                        if (!createUpdateInput.includes(field)) {
                            delete input[field];
                        }
                    }

                    if (!input.settlementOnVMInstrument) {
                        input.settlementOnVMInstrument = false;
                    }

                    if (!input.separatedInitialMargin) {
                        input.separatedInitialMargin = false;
                    }
                    delete input.agreement;

                    delete input.custodian;
                    if (input._id !== null) {
                        input.create = false;
                        await createUpdateExternalAccount({ input: input, partyId: partyId })
                            .then((result) => {
                                if ("error" in result && result.error) {
                                    const message = formikUrqlErrorFormater(result.error, setErrors);
                                    setAlert({ color: "danger", visible: true, message });
                                } else {
                                    setAlert({
                                        color: "success",
                                        visible: true,
                                        message: `The external account has been updated successfully!`
                                    });
                                    props.onUpdate(partyId);
                                    setValues(result.data.createUpdateExternalAccount);
                                }
                            })
                            .catch((error) => {
                                setAlert({ color: "danger", visible: true, message: error.toString() });
                            })
                            .finally(() => {
                                refetch();
                                setSubmitting(false);
                            });
                    } else {
                        input.create = true;
                        await createUpdateExternalAccount({ input: input, partyId: partyId })
                            .then((result) => {
                                if ("error" in result && result.error) {
                                    const message = formikUrqlErrorFormater(result.error, setErrors);
                                    setAlert({ color: "danger", visible: true, message });
                                } else {
                                    setAlert({
                                        color: "success",
                                        visible: true,
                                        message: `The external account has been created successfully!`
                                    });
                                    props.onUpdate(partyId);
                                    const path = location.pathname.split("/");
                                    path.pop();
                                    path.push(result.data.createUpdateExternalAccount._id);
                                    navigate(path.join("/"), { replace: true });
                                    setValues(result.data.createUpdateExternalAccount);
                                }
                            })
                            .catch((error) => {
                                setAlert({ color: "danger", visible: true, message: error.toString() });
                            })
                            .finally(() => {
                                refetch();
                                setSubmitting(false);
                            });
                    }
                }}
            >
                {({ isSubmitting, values, errors }) => {
                    const { type } = values;
                    if (Object.keys(errors).length > 0) {
                        console.log(errors);
                    }
                    return (
                        <Fragment>
                            <div className="" style={{ float: "right" }}>
                                <header>
                                    <h4>{startCase(type)}</h4>
                                </header>
                                <div>
                                    <div>
                                        <Form autoComplete="off">
                                            <div className="form-row">
                                                <div
                                                    className={
                                                        type === PartyExternalAccountType.Equity ||
                                                        type === PartyExternalAccountType.CashAccount
                                                            ? "col-12"
                                                            : "col-5"
                                                    }
                                                >
                                                    <div>
                                                        <SelectField
                                                            name="custodianId"
                                                            label={
                                                                type === PartyExternalAccountType.CashAccount
                                                                    ? "Bank*"
                                                                    : type === PartyExternalAccountType.FundAccount ||
                                                                        type === PartyExternalAccountType.ShareRegistry ||
                                                                        type === PartyExternalAccountType.SecuritiesAccount ||
                                                                        type === PartyExternalAccountType.Equity
                                                                      ? "Custodian*"
                                                                      : "Counterparty*"
                                                            }
                                                            options={custodiansAndClient.map((d) => ({ key: d._id, value: d.name }))}
                                                            className=""
                                                            disabled={values._id ? true : false}
                                                        />
                                                    </div>
                                                    <div>
                                                        {AgreementType[type] ? (
                                                            <NumberField
                                                                name="minimumTransferAmount"
                                                                label="MTA"
                                                                className=""
                                                                disabled={false}
                                                            />
                                                        ) : null}
                                                    </div>
                                                    <div>
                                                        {AgreementType[type] ? (
                                                            <NumberField
                                                                name="roundingAmount"
                                                                label="Rounding amount"
                                                                className=""
                                                                disabled={false}
                                                            />
                                                        ) : null}
                                                    </div>
                                                    <div>
                                                        {AgreementType[type] ? (
                                                            <NumberField name="threshold" label="Threshold" className="" disabled={false} />
                                                        ) : null}
                                                    </div>
                                                    {type === PartyExternalAccountType.OmnibusClearingAccount ? (
                                                        <MultipleSelectField
                                                            name="clearingHousesIds"
                                                            label="Clearing houses*"
                                                            options={clearingHouses.map((d) => ({ key: d._id, value: d.name }))}
                                                            className=""
                                                            disabled={false}
                                                            size={3}
                                                        />
                                                    ) : null}
                                                    <div>
                                                        {AgreementType[type] ? (
                                                            <SelectField
                                                                name="baseCurrency"
                                                                label="Base currency*"
                                                                options={currencies}
                                                                className=""
                                                                disabled={false}
                                                            />
                                                        ) : null}
                                                    </div>
                                                    <div>
                                                        {type === PartyExternalAccountType.ClearingAccount ? (
                                                            <SelectField
                                                                name="clearingBrokerId"
                                                                label="Clearing broker*"
                                                                options={clearingBrokers.map((d) => ({ key: d._id, value: d.name }))}
                                                                className=""
                                                                disabled={false}
                                                                size={3}
                                                            />
                                                        ) : null}
                                                    </div>
                                                    <div>
                                                        {values._id ? (
                                                            <TextField name="_id" label="ID*" type="text" className="" disabled={true} />
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="col-7">
                                                    {type === PartyExternalAccountType.SecuritiesAccount ||
                                                    type === PartyExternalAccountType.ClearingAccount ||
                                                    type === PartyExternalAccountType.OmnibusClearingAccount ? (
                                                        <TextField
                                                            name="custodianAccountNumber"
                                                            label={
                                                                type === PartyExternalAccountType.SecuritiesAccount
                                                                    ? "Custodian account nr*"
                                                                    : "Counterparty account nr*"
                                                            }
                                                            type="text"
                                                            className=""
                                                            disabled={false}
                                                        />
                                                    ) : null}
                                                    {type === PartyExternalAccountType.FundAccount ||
                                                    type === PartyExternalAccountType.ShareRegistry ? (
                                                        <TextField
                                                            name="custodianAccountNumber"
                                                            label="Custodian account nr"
                                                            type="text"
                                                            className=""
                                                            disabled={false}
                                                        />
                                                    ) : null}
                                                    {AgreementType[type] ? (
                                                        <MultipleSelectField
                                                            name="variationMarginCurrencies"
                                                            label="Variation margin currencies"
                                                            options={currencies}
                                                            className=""
                                                            disabled={false}
                                                            size={3}
                                                        />
                                                    ) : null}
                                                    {type === PartyExternalAccountType.Gmsla ? (
                                                        <MultipleSelectField
                                                            name="principalCurrencies"
                                                            label="Principal currencies"
                                                            options={currencies}
                                                            className=""
                                                            disabled={false}
                                                            size={3}
                                                        />
                                                    ) : null}
                                                    {type === PartyExternalAccountType.ClearingAccount ||
                                                    type === PartyExternalAccountType.OmnibusClearingAccount ? (
                                                        <MultipleSelectField
                                                            name="initialMarginCurrencies"
                                                            label="Initial margin currencies"
                                                            options={currencies}
                                                            className=""
                                                            disabled={false}
                                                            size={3}
                                                        />
                                                    ) : null}
                                                    {type === PartyExternalAccountType.ClearingAccount ||
                                                    type === PartyExternalAccountType.OmnibusClearingAccount ? (
                                                        <CheckBoxField
                                                            className=""
                                                            name="separatedInitialMargin"
                                                            label="Separated initial margin"
                                                            disabled={isSubmitting}
                                                        />
                                                    ) : null}
                                                    {AgreementType[type] ? (
                                                        <CheckBoxField
                                                            className=""
                                                            name="settlementOnVMInstrument"
                                                            label="Settl. amount on VM instr."
                                                            disabled={isSubmitting}
                                                        />
                                                    ) : null}
                                                    {type === PartyExternalAccountType.Isda ? (
                                                        <div className="row-col">
                                                            <CheckBoxField
                                                                name="emirReporting"
                                                                label="Emir reporting"
                                                                className="text-center"
                                                                disabled={false}
                                                            />
                                                            <CheckBoxField
                                                                name="noCollateralRequirement"
                                                                label="No collateral requirement"
                                                                className="text-center"
                                                                disabled={false}
                                                            />
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            {alert.visible ? (
                                                <Alert
                                                    style={{ marginTop: "10px" }}
                                                    variant={alert.color}
                                                    onClose={onDismissAlert}
                                                    dismissible
                                                >
                                                    {alert.message}
                                                </Alert>
                                            ) : null}

                                            <div className="d-inline-flex align-items-baseline">
                                                <SubmitButton
                                                    disabled={isSubmitting || Object.keys(errors).length > 0}
                                                    label={values._id ? "Save" : "Create"}
                                                />

                                                <a
                                                    href={`/transactionitems?clientIds=${cid}&externalAccount.name=${values.name}`}
                                                    className="ms-4"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    Transactions
                                                </a>
                                            </div>
                                        </Form>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="me-auto mt-2 mb-2">
                                                {values.agreement && values.agreement._id ? (
                                                    <Link to={"/agreements/" + values.agreement._id}>Agreement</Link>
                                                ) : (
                                                    "Agreement"
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {values.agreement ? (
                                        <AttachmentForm
                                            clientId={props.cid}
                                            attachments={
                                                values.agreement && values.agreement.attachments ? values.agreement.attachments : []
                                            }
                                            onChange={callBackOnChangeAttachments}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </Fragment>
                    );
                }}
            </Formik>
        </div>
    );
}
export function NewExternalAccountButton({ page }: { page: string }): React.ReactElement {
    const navigate = useNavigate();
    return (
        <DropdownButton title="New external account" id="visibility" align="end" size="sm">
            {externalAccountTypes.map((type, i) => (
                <Dropdown.Item key={i} as="button" onClick={() => navigate(`/${page}/new` + type.toLowerCase())}>
                    {type}
                </Dropdown.Item>
            ))}
        </DropdownButton>
    );
}
