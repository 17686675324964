import React, { Fragment, useMemo } from "react";
import { gql } from "urql";
import { keyBy } from "lodash";
import { Link } from "react-router-dom";

import { userHaveAccessTo } from "../../common/Permissions";
import { PermissionAssetEnum, UserStatusEnum } from "../../types.generated";
import { useGetMeQuery, useGetUsersQuery } from "./AdminUsersPage.generated";
import { SelectColumnFilter, SelectColumnFilterArrayOfIds, SelectColumnFilterType } from "../../components/react-table/ReactTableFilters";
import { ReactTable } from "../../components/react-table/ReactTable";

export const getMe = gql`
    query getMe {
        me {
            _id
            personalNumber
            roles {
                _id
                assets
                clientIds
                permissionType
                name
            }
        }
    }
`;

export const getUsers = gql`
    query getUsers {
        users {
            _id
            clientIds
            clients {
                _id
                name
            }
            status
            personalNumber
            name
            company
            frontendRoleId
            frontendRole {
                _id
                name
            }
            email
            description
            phoneNumber
            lastLoginTimestamp
            lastBankIdLoginTimestamp
            lastPasswordUpdate
            roleIds
            roles {
                _id
                name
                assets
                permissionType
                updateTimestamp
                roleType
                clientIds
                clients {
                    name
                }
            }
        }

        roles {
            _id
            name
        }
    }
`;

export function AdminUsersPage(): React.ReactElement {
    const [{ fetching: loadingMe, error: errorMe, data: getMeData }] = useGetMeQuery();
    const [{ fetching: loading, error, data }] = useGetUsersQuery({ requestPolicy: "network-only" });

    const columns = useMemo(() => {
        let rolesById: Record<string, { _id: string; name: string }> = {};
        const clientsById: Record<string, { _id: string; name: string }> = {};

        if (data) {
            rolesById = keyBy(data.roles, "_id");

            data.users.forEach((user) => {
                user.clients.forEach((client) => {
                    clientsById[client._id] = client;
                });
            });
        }
        return [
            {
                header: "Id",
                accessorKey: "_id",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/admin/users/${row.original._id}`}>{cellProps.getValue()}</Link>;
                }
            },
            {
                header: "Status",
                accessorKey: "status",
                filter: SelectColumnFilterType(UserStatusEnum)
            },
            {
                header: "Clients",
                accessorKey: "clientIds",
                cell: (cellProps) => {
                    const value = cellProps.getValue();
                    if (value) {
                        return (
                            <div className="col">
                                {value.map((id) => {
                                    const client = clientsById[id];
                                    if (client) {
                                        return (
                                            <Link className="row" key={id} to={`/parties/${id}`}>
                                                {clientsById[id].name}
                                            </Link>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                            </div>
                        );
                    } else {
                        return null;
                    }
                },
                filter: SelectColumnFilterArrayOfIds(Object.values(clientsById)),
                size: 100
            },
            {
                header: "Personal number",
                accessorKey: "personalNumber"
            },
            {
                header: "Name",
                accessorKey: "name"
            },
            {
                header: "Company",
                accessorKey: "company",
                filter: SelectColumnFilter
            },
            {
                header: "Frontend role",
                accessorKey: "frontendRoleId",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    const value = cellProps.getValue();
                    if (row.original.frontendRole) {
                        return <Link to={`/admin/roles/${value}`}>{rolesById[value].name}</Link>;
                    } else {
                        return null;
                    }
                }
            },
            {
                header: "Roles",
                accessorKey: "roleIds",
                cell: (cellProps) => {
                    const value = cellProps.getValue();
                    if (value) {
                        return (
                            <div className="col">
                                {value.map((id) => {
                                    const role = rolesById[id];
                                    if (role) {
                                        return (
                                            <Link className="row" key={id} to={`/admin/roles/${id}`}>
                                                {rolesById[id].name}
                                            </Link>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                            </div>
                        );
                    } else {
                        return null;
                    }
                },
                filter: SelectColumnFilterArrayOfIds(Object.values(rolesById)),
                size: 200
            },
            {
                header: "Last login timestamp",
                accessorKey: "lastLoginTimestamp"
            },
            {
                header: "Last bankId login timestamp",
                accessorKey: "lastBankIdLoginTimestamp"
            },
            {
                header: "Last password update",
                accessorKey: "lastPasswordUpdate"
            }
        ];
    }, [data]);

    if (loadingMe || loading) return <h3>loading..</h3>;
    if (errorMe) return <pre>{JSON.stringify(errorMe, null, 2)}</pre>;
    if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>;

    if (!userHaveAccessTo("Any", PermissionAssetEnum.Admin, getMeData.me.roles)) {
        return <div />;
    }

    let { users } = data;
    users = users ? users : [];

    return (
        <div>
            <Fragment>
                <p>Number of users: {users.length}</p>
                <ReactTable columns={columns} data={users} defaultHiddenColumns={["personalNumber"]} />
            </Fragment>
        </div>
    );
}
