import React, { useState, useEffect, ReactElement, Fragment, useMemo } from "react";
import { gql, useQuery, useMutation } from "urql";
import { useParams, useNavigate, useLocation, Link } from "react-router-dom";
import { cloneDeep, sortBy } from "lodash";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { Alert, Button } from "react-bootstrap";

import { DateHelper, emptyObjectId } from "../../../common/src";
import { validIsin } from "../../../common/src/isin-validator";
import { Svgs } from "../../../components/src";

import { YesNoModal } from "../components/YesNoModal";
import {
    TextField,
    NumberField,
    SelectField,
    CheckboxItem,
    SubmitButton,
    CopyClipboardButton,
    SearchListField,
    SearchMultipleSelectField
} from "../components/form";
import { Page } from "../components/Page";
import { isValidMongoDBObjectID, useAlertTimeOut } from "../common/Utils";
import { recursivelyRemoveKey } from "../../../common/src/utils/FormatFunctions";
import {
    InstrumentCategory,
    CurrencyEnum,
    InstrumentProductTypeEnum,
    InstrumentStatusEnum,
    InstrumentTypeEnum,
    InstrumentModelTypeEnum,
    CollectionNameEnum,
    InstrumentProductTypeEnumDescriptions
} from "../types.generated";
import { SYSTEM_PARTY_ID } from "../Constants";
import { formikUrqlErrorFormater } from "../common/formik-urql-error-helper";
import { defaultAlias } from "../components/alias";
import { MarkDownField } from "../components/form/MarkDownField";

const defaultFormData = {
    name: "  ",
    clientIds: [SYSTEM_PARTY_ID],
    bloombergDailyDownload: false,
    bloombergTicker: "",
    bloombergQuoteMultiplier: 1.0,
    category: InstrumentCategory.None,
    cic: "",
    creditRatings: [],
    aliases: [],
    currency: CurrencyEnum.SEK,
    exchange: "",
    mic: "",
    isin: "",
    benchmarkId: emptyObjectId,
    issuerId: emptyObjectId,
    issuerProgramId: emptyObjectId,
    longName: "",
    placeOfSettlementId: emptyObjectId,
    productType: InstrumentProductTypeEnum.None,
    quantityDecimals: 0,
    status: InstrumentStatusEnum.Confirmed
};

const GET_PARTIES = gql`
    query {
        creditRatingAgencies: parties(filter: { typeIn: CreditRatingAgency }) {
            _id
            name
        }
        issuers: parties(filter: { typeIn: Issuer }) {
            _id
            name
        }
        places: parties(filter: { typeIn: PlaceOfSettlement }) {
            _id
            name
        }
        clients: parties(filter: { typeIn: [Client] }) {
            _id
            name
        }
    }
`;

const GET_ISSUERPROGRAMS = gql`
    query {
        issuerprograms {
            _id
            name
            issuerId
        }
    }
`;

const GET_ALIASES = gql`
    query {
        parties {
            _id
            name
        }
    }
`;

export const GET_INSTRUMENT = gql`
    query instrument($id: GraphQLObjectId!, $includeVersions: Boolean = false) {
        instrument(_id: $id) {
            _id
            clientIds
            name
            benchmarkId
            bloombergDailyDownload
            bloombergQuoteMultiplier
            bloombergTicker
            category
            cic
            creditRatings {
                creditRatingAgencyId
                grade
            }
            aliases {
                collection
                documentId
                key
                value
                comment
            }
            currency
            exchange
            mic
            isin
            issuerId
            issuerProgramId
            longName
            placeOfSettlementId
            productType
            quantityDecimals
            type
            status
            modelType
            maturityDate
            updateTimestamp
            updateUserInfo {
                name
            }
            description
            versions @include(if: $includeVersions)
            extensions {
                _id
                clientId
                client {
                    _id
                    name
                }
                key
                value
                comment
            }
            model {
                _t
                accountId
                clientId
                expiryDate
                legs {
                    _t
                    calendars
                    call
                    creditSpread
                    currency
                    dayAdjustment
                    dayCount
                    exercise
                    expiry
                    fixingType
                    flatAdjustment
                    issuerId
                    longStub
                    maturityDate
                    maturityPeriod
                    multiplier
                    notional
                    payLeg
                    rate
                    rateIndexId
                    recoveryRate
                    relativeAdjustment
                    rollingDate
                    rollingPeriod
                    settlementType
                    spread
                    startDate
                    startDateOffset
                    startPeriod
                    strike
                    ultimateForwardRate
                    underlyingId
                    volatility
                    zeroFloor
                    cashFlows {
                        endDate
                        exDate
                        fixings {
                            endDate
                            date
                            rate
                            startDate
                        }
                        notional
                        payDate
                        startDate
                    }
                    underlyings {
                        instrumentId
                        weight
                        startPrice
                        quanto
                    }
                }
                objectId
                objectType
                notionalScaling
                quoteCurrency
                quoteType
                startDate
            }
        }

        timeseries(filter: { instrumentIdIn: [$id] }) {
            description
            type
            _id
        }

        lastValuations(instrumentIds: [$id]) {
            _id
            date
        }
    }
`;

const GET_INSTRUMENTS = gql`
    query {
        instruments {
            _id
            isin
            name
            longName
            bloombergTicker
            currency
        }
    }
`;

const CREATE_INSTRUMENT = gql`
    mutation createInstrument($input: CreateInstrumentInput!) {
        createInstrument(input: $input) {
            _id
        }
    }
`;

const UPDATE_INSTRUMENT = gql`
    mutation updateInstrument($input: UpdateInstrumentInput!) {
        updateInstrument(input: $input) {
            _id
        }
    }
`;

const DELETE_INSTRUMENT = gql`
    mutation deleteInstrument($_id: GraphQLObjectId!) {
        deleteInstrument(_id: $_id)
    }
`;

export function InstrumentPage(): ReactElement {
    const { id } = useParams<"id">();
    const navigate = useNavigate();
    const location = useLocation();

    const [formData, setFormData] = useState(cloneDeep(defaultFormData));
    const [modal, setModal] = useState({ showModal: false, payload: null });

    const isCreateMode = !!(id && id.toString() === "new");

    const [{ fetching: loadingInstruments, data: instrumentsData, error: errorInstruments }] = useQuery({
        query: GET_INSTRUMENTS,
        requestPolicy: "cache-first"
    });

    const [{ fetching: loadingParties, data: parties, error: errorParties }] = useQuery({
        query: GET_PARTIES,
        requestPolicy: "cache-first"
    });

    const [{ fetching: loadingIssuerPrograms, data: issuerprogramData, error: errorIssuerprograms }] = useQuery({
        query: GET_ISSUERPROGRAMS,
        requestPolicy: "cache-first"
    });

    const [{ fetching: loadingAliases, data: dataAliases, error: errorAliases }] = useQuery({
        query: GET_ALIASES,
        requestPolicy: "cache-first"
    });

    const [{ fetching: loading, data, error }, refetch] = useQuery({
        query: GET_INSTRUMENT,
        variables: { id },
        requestPolicy: "network-only",
        pause: isCreateMode
    });

    const [_, createInstrument] = useMutation(CREATE_INSTRUMENT);
    const [__, updateInstrument] = useMutation(UPDATE_INSTRUMENT);
    const [___, deleteInstrument] = useMutation(DELETE_INSTRUMENT);

    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    useAlertTimeOut(alert, setAlert, 5);

    useEffect(() => {
        if (id && id !== "new" && data && data.instrument) {
            const instrument = {
                _id: data.instrument._id,
                benchmarkId: data.instrument.benchmarkId,
                clientIds: data.instrument.clientIds,
                issuerId: data.instrument.issuerId,
                issuerProgramId: data.instrument.issuerProgramId,
                name: data.instrument.name,
                bloombergDailyDownload: data.instrument.bloombergDailyDownload,
                bloombergTicker: data.instrument.bloombergTicker,
                bloombergQuoteMultiplier: parseFloat(data.instrument.bloombergQuoteMultiplier),
                category: data.instrument.category,
                cic: data.instrument.cic,
                creditRatings: data.instrument.creditRatings || [],
                currency: data.instrument.currency,
                exchange: data.instrument.exchange,
                mic: data.instrument.mic,
                isin: data.instrument.isin,
                longName: data.instrument.longName,
                placeOfSettlementId: data.instrument.placeOfSettlementId,
                productType: data.instrument.productType,
                type: data.instrument.type,
                quantityDecimals: data.instrument.quantityDecimals,
                status: data.instrument.status,
                description: data.instrument.description || "",
                aliases: data.instrument.aliases || []
            };
            setFormData(instrument);
        }
    }, [id, data]);

    const aliasOptions: { _id: string; name: string }[] = useMemo(() => {
        let options = [{ _id: "000000000000000000000000", name: CollectionNameEnum.None }];
        if (dataAliases) {
            options = [{ _id: "000000000000000000000000", name: CollectionNameEnum.None }, ...dataAliases.parties];
        }
        return options;
    }, [dataAliases]);

    const [jsonString, setJsonInput] = useState("");
    const [jsonValidation, setJsonValidation] = useState({ valid: true, message: "" });
    const onDismissValidationAlert = () => setJsonValidation({ valid: true, message: "" });

    if (loading || loadingParties || loadingIssuerPrograms || loadingInstruments || loadingAliases)
        return (
            <div className="loader">
                <Svgs.Loader />
            </div>
        );

    if (error) return <pre>{JSON.stringify(error, null, 2)}</pre>;
    if (errorParties) return <pre>{JSON.stringify(errorParties, null, 2)}</pre>;
    if (errorIssuerprograms) return <pre>{JSON.stringify(errorIssuerprograms, null, 2)}</pre>;
    if (errorInstruments) return <pre>{JSON.stringify(errorInstruments, null, 2)}</pre>;
    if (errorAliases) return <pre>{JSON.stringify(errorAliases, null, 2)}</pre>;

    if (id !== "new" && !data.instrument) {
        return <div>Instrument not found</div>;
    }

    if (!isCreateMode && data && data.instrument) {
        if (!isValidMongoDBObjectID(id)) {
            return <Page header={"id: ''" + id + "'' is not a valid id format"} />;
        }
    }

    const creditRatingAgencies = parties.creditRatingAgencies;
    const issuers = cloneDeep(parties.issuers);
    const places = cloneDeep(parties.places);
    const clients = cloneDeep(parties.clients);

    let instruments = [];
    if (!loadingInstruments && instrumentsData) {
        instruments = cloneDeep(instrumentsData.instruments);
    }

    // ensure empty objects
    if (!issuers.map((i) => i._id).includes(emptyObjectId)) {
        issuers.unshift({ _id: emptyObjectId, name: "None" }); // ensure issuer None
    }
    if (!places.map((i) => i._id).includes(emptyObjectId)) {
        places.unshift({ _id: emptyObjectId, name: "None" }); // ensure places None
    }

    if (!instruments.map((i) => i._id).includes(emptyObjectId)) {
        instruments.unshift({ _id: emptyObjectId, name: "None" }); // ensure instrument None
    }

    const issuerprogramById = {};
    let issuerprograms = [];
    for (let i = 0; i < issuerprogramData.issuerprograms.length; i++) {
        const issuerprogram = issuerprogramData.issuerprograms[i];
        if (formData && formData.issuerId === issuerprogram.issuerId) {
            issuerprogramById[issuerprogram._id] = { name: issuerprogram.name, _id: issuerprogram._id };
            issuerprograms.push({ value: issuerprogram.name, name: issuerprogram.name, _id: issuerprogram._id, key: issuerprogram._id });
        }
    }

    issuerprograms = sortBy(issuerprograms, "name");
    issuerprograms = [{ key: emptyObjectId, name: "None", value: "None", _id: emptyObjectId }, ...issuerprograms];

    const cleanJsonInput = (input) => {
        let result = cloneDeep(input);
        result = recursivelyRemoveKey(result, "__typename");
        if (result.isin === "") {
            result.isin = null;
        }
        delete result.updateTimestamp;
        delete result.updateUserInfo;

        delete result.modelType; // Field "modelType" is not defined by type UpdateInstrumentInput
        delete result.maturityDate; // Field "maturityDate" is not defined by type UpdateInstrumentInput.
        delete result.extensions; // Field "maturityDate" is not defined by type UpdateInstrumentInput.
        return result;
    };

    const validateJson = (json) => {
        try {
            const j = JSON.parse(json);
            const valid = typeof j === "object";
            if (jsonValidation.valid !== valid) {
                setJsonValidation({ valid: true, message: "" });
            }
        } catch (e) {
            setJsonValidation({ valid: false, message: "Invalid json: " + e.toString() });
        }
    };

    const handleJsonChange = (e) => {
        setJsonInput(e.target.value);
        validateJson(e.target.value);
    };

    const handleJsonSubmit = async (e) => {
        e.preventDefault();

        let input = JSON.parse(jsonString);

        // clean JSON
        input = cleanJsonInput(input);

        // update mutation
        await updateInstrument({ input })
            .then((result) => {
                if ("error" in result && result.error) {
                    const message = formikUrqlErrorFormater(result.error);
                    setAlert({ color: "danger", visible: true, message });
                } else {
                    refetch();
                    setAlert({
                        color: "success",
                        visible: true,
                        message: `'Update json' succeed. The instrument '${input.isin || input.name}' is updated!`
                    });
                }
                return true;
            })
            .catch((error) => {
                setAlert({ color: "danger", visible: true, message: error.toString() });
            });
    };

    const handleJsonReset = () => {
        const ins = cleanJsonInput(cloneDeep(data.instrument));
        const json = JSON.stringify(ins, null, 2);
        setJsonInput(json);
        validateJson(json);
    };

    const defaultCreditRating = {
        creditRatingAgencyId: creditRatingAgencies && creditRatingAgencies.length > 0 ? creditRatingAgencies[0]._id : "",
        grade: ""
    };

    const aliasLabel = (collection: CollectionNameEnum, documentId: string) => {
        if (collection === CollectionNameEnum.Party) {
            return <Link to={"/parties/" + documentId}>{collection}</Link>;
        } else {
            return collection;
        }
    };

    const startDate = DateHelper.dateToString(DateHelper.dateAddDays(new Date(), -365));

    return (
        <div>
            {modal.showModal ? (
                <YesNoModal
                    warningText={"Are you sure you want to delete instrument with id " + modal.payload + "?"}
                    modal={{
                        showModal: modal.showModal,
                        payload: modal.payload
                    }}
                    setModal={setModal}
                    onYes={() => {
                        deleteInstrument({ _id: modal.payload })
                            .then((result) => {
                                if ("error" in result && result.error) {
                                    const message = formikUrqlErrorFormater(result.error);
                                    setAlert({ color: "danger", visible: true, message });
                                } else {
                                    refetch();
                                    setAlert({
                                        color: "success",
                                        visible: true,
                                        message: `Instrument with id '${modal.payload}' was updated and deleted successfully!`
                                    });
                                }
                                return true;
                            })
                            .catch((error) => {
                                setAlert({ color: "danger", visible: true, message: error.toString() });
                            });
                    }}
                />
            ) : null}

            <div className="container page">
                <h1>Instrument</h1>
                <div className="row">
                    {formData ? (
                        <Formik
                            enableReinitialize={true}
                            initialValues={formData}
                            validationSchema={Yup.object({
                                name: Yup.string().required().min(2),
                                isin: Yup.string()
                                    .nullable()
                                    .test("test-isin", "Invalid ISIN format", function (value) {
                                        if (!value) {
                                            return true;
                                        }
                                        const result = validIsin(value, { checkCountryCode: false });
                                        if (result[0]) {
                                            return true;
                                        } else {
                                            return this.createError({ message: result[1].message });
                                        }
                                    }),
                                bloombergQuoteMultiplier: Yup.number(),
                                creditRatings: Yup.array().of(
                                    Yup.object().shape({
                                        creditRatingAgencyId: Yup.string().required(),
                                        grade: Yup.string().matches(/^[0-9aA-zZ\-+]+$/, "Only  0-9aA-zZ\\-\\+ allowed")
                                    })
                                ),
                                mic: Yup.string().test("mic-length", "mic must be 0 or 4 characters", function (mic: string) {
                                    if (!mic || mic.length === 0 || mic.length === 4) {
                                        return true;
                                    }
                                    return false;
                                })
                            })}
                            validate={(validateValues) => {
                                const errors = {};

                                if (validateValues.name.startsWith(" ")) {
                                    errors["name"] = "Must not start with space";
                                }

                                const result = Object.keys(errors).length > 0 ? errors : {};
                                //console.error("validate", result);
                                return result;
                            }}
                            onSubmit={async (submitValues, { setSubmitting, setErrors }) => {
                                let input = submitValues;
                                input.mic = input.mic || "";
                                if (isCreateMode) {
                                    let newInstrId;
                                    delete input["_id"];

                                    await createInstrument({ input })
                                        .then((result) => {
                                            if ("error" in result && result.error) {
                                                const message = formikUrqlErrorFormater(result.error, setErrors);
                                                setAlert({ color: "danger", visible: true, message });
                                            } else {
                                                newInstrId = result.data.createInstrument._id;
                                                setAlert({
                                                    color: "success",
                                                    visible: true,
                                                    message: `New instrument '${input.name}' created successfully!`
                                                });
                                                // redirect to edit instrument page on success
                                                if (newInstrId) {
                                                    const path = location.pathname.split("/");
                                                    path.pop();
                                                    path.push(newInstrId);
                                                    navigate(path.join("/"), { replace: true });
                                                }
                                            }
                                            return true;
                                        })
                                        .catch((error) => {
                                            setAlert({ color: "danger", visible: true, message: error.toString() });
                                        })
                                        .finally(() => {
                                            setSubmitting(false);
                                        });
                                } else {
                                    input = cleanJsonInput(input);
                                    delete input["model"];

                                    if (input.status && input.status === "Deleted") {
                                        setModal({ showModal: true, payload: input["_id"] });
                                    } else {
                                        await updateInstrument({ input })
                                            .then((result) => {
                                                if ("error" in result && result.error) {
                                                    const message = formikUrqlErrorFormater(result.error, setErrors);
                                                    setAlert({ color: "danger", visible: true, message });
                                                } else {
                                                    refetch();
                                                    setAlert({
                                                        color: "success",
                                                        visible: true,
                                                        message: `The instrument '${input.isin || input.name}' updated successfully!`
                                                    });
                                                }
                                                return true;
                                            })
                                            .catch((error) => {
                                                setAlert({ color: "danger", visible: true, message: error.toString() });
                                            })
                                            .finally(() => {
                                                setSubmitting(false);
                                            });
                                    }
                                }
                            }}
                        >
                            {({ isSubmitting, values }) => (
                                <div className="col-12">
                                    <Form autoComplete="off" className="form">
                                        <div className="form-row">
                                            <div className="col-lg-8 col-md-8 col-sm-9 col-xs-12">
                                                <div className="form-row">
                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                        <TextField
                                                            name="name"
                                                            label="Name"
                                                            type="text"
                                                            className=""
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-7">
                                                        <TextField
                                                            name="longName"
                                                            label="Long name"
                                                            type="text"
                                                            className=""
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                    <div className="col-xs-12 col-sm-12 col-md-3 col-lg-2">
                                                        <SelectField
                                                            name="currency"
                                                            label="Currency"
                                                            options={Object.keys(CurrencyEnum).sort()}
                                                            className=""
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                    <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                                        <SelectField
                                                            name="status"
                                                            label="Status"
                                                            options={Object.keys(InstrumentStatusEnum).sort()}
                                                            className=""
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                                        <SelectField
                                                            name="category"
                                                            label="Category (used by accounting)"
                                                            options={Object.keys(InstrumentCategory).sort()}
                                                            className=""
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>

                                                    <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                                        <SelectField
                                                            name="productType"
                                                            label="Product type"
                                                            options={Object.keys(InstrumentProductTypeEnum).sort()}
                                                            tooltips={InstrumentProductTypeEnumDescriptions}
                                                            className=""
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>

                                                    <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2">
                                                        <SelectField
                                                            name="type"
                                                            label="Type"
                                                            options={Object.keys(InstrumentTypeEnum).sort()}
                                                            className=""
                                                            disabled={isSubmitting || (id && id !== "new")}
                                                        />
                                                    </div>
                                                    <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                                                        <NumberField
                                                            name="quantityDecimals"
                                                            label="Quantity decimals"
                                                            className=""
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="form-row">
                                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                        <SelectField
                                                            name="placeOfSettlementId"
                                                            label={
                                                                values.placeOfSettlementId !== emptyObjectId ? (
                                                                    <Link to={"/parties/" + values.placeOfSettlementId}>
                                                                        Place of settlement
                                                                    </Link>
                                                                ) : (
                                                                    "Place of settlement"
                                                                )
                                                            }
                                                            options={places.map((d) => ({ key: d._id, value: d.name }))}
                                                            className=""
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>

                                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                        <SearchListField
                                                            className=""
                                                            name="benchmarkId"
                                                            label={<Link to={"/instruments/" + values.benchmarkId}>Benchmark</Link>}
                                                            options={instruments}
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                        <SearchListField
                                                            className=""
                                                            name="issuerId"
                                                            label={<Link to={"/parties/" + values.issuerId}>Issuer</Link>}
                                                            options={issuers}
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                        <SelectField
                                                            className=""
                                                            name="issuerProgramId"
                                                            label={
                                                                <Link to={"/issuerprograms/" + values.issuerProgramId}>Issuer program</Link>
                                                            }
                                                            options={issuerprograms}
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-12 col-xl-6 mt-3">
                                                        <h5>Credit ratings</h5>
                                                        <FieldArray
                                                            name="creditRatings"
                                                            render={(arrayHelpers) => (
                                                                <div>
                                                                    <Fragment>
                                                                        {values.creditRatings && values.creditRatings.length > 0 ? (
                                                                            values.creditRatings.map((item, index) => (
                                                                                <div key={index}>
                                                                                    <div className="form-row">
                                                                                        <SelectField
                                                                                            className="col-6"
                                                                                            name={`creditRatings[${index}].creditRatingAgencyId`}
                                                                                            label={null}
                                                                                            options={creditRatingAgencies.map((d) => ({
                                                                                                key: d._id,
                                                                                                value: d.name
                                                                                            }))}
                                                                                            disabled={false}
                                                                                        />
                                                                                        <TextField
                                                                                            className="col-4"
                                                                                            name={`creditRatings[${index}].grade`}
                                                                                            label={null}
                                                                                            disabled={false}
                                                                                        />
                                                                                        <div className="col-2">
                                                                                            <Button
                                                                                                className="btn-danger btn-sm"
                                                                                                type="button"
                                                                                                onClick={() => arrayHelpers.remove(index)}
                                                                                                style={{ height: "1.75rem" }}
                                                                                            >
                                                                                                -
                                                                                            </Button>
                                                                                            <Button
                                                                                                className="btn-sm ms-1"
                                                                                                type="button"
                                                                                                onClick={() =>
                                                                                                    arrayHelpers.insert(
                                                                                                        index,
                                                                                                        defaultCreditRating
                                                                                                    )
                                                                                                }
                                                                                                style={{ height: "1.75rem" }}
                                                                                            >
                                                                                                +
                                                                                            </Button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                        ) : (
                                                                            <Button
                                                                                className="btn-sm"
                                                                                type="button"
                                                                                onClick={() => arrayHelpers.push(defaultCreditRating)}
                                                                            >
                                                                                Add New
                                                                            </Button>
                                                                        )}
                                                                    </Fragment>
                                                                </div>
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-xl-6 mt-3">
                                                        <h5>Description</h5>
                                                        <MarkDownField name="description" label="Click to edit" height={200} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-xl-6 mt-3">
                                                        <h5>Aliases</h5>
                                                        <FieldArray
                                                            name="aliases"
                                                            render={(arrayHelpers) => (
                                                                <Fragment>
                                                                    {values.aliases && values.aliases.length > 0 ? (
                                                                        values.aliases.map((alias, index) => (
                                                                            <div key={index} className="form-group form-row">
                                                                                <div className="col-10">
                                                                                    <fieldset>
                                                                                        <legend>{index + 1}</legend>
                                                                                        <SelectField
                                                                                            name={`aliases[${index}].collection`}
                                                                                            label="Collection"
                                                                                            className="text-success"
                                                                                            options={["None", "Party"]}
                                                                                            disabled={isSubmitting}
                                                                                            size={5}
                                                                                        />
                                                                                        <SearchListField
                                                                                            className=""
                                                                                            name={`aliases[${index}].documentId`}
                                                                                            label={aliasLabel(
                                                                                                alias.collection,
                                                                                                alias.documentId
                                                                                            )}
                                                                                            options={aliasOptions}
                                                                                            disabled={false}
                                                                                        />
                                                                                        <TextField
                                                                                            className=""
                                                                                            name={`aliases[${index}].key`}
                                                                                            label="Key"
                                                                                            disabled={false}
                                                                                        />
                                                                                        <TextField
                                                                                            className=""
                                                                                            name={`aliases[${index}].value`}
                                                                                            label="Value"
                                                                                            disabled={false}
                                                                                        />
                                                                                        <TextField
                                                                                            className=""
                                                                                            name={`aliases[${index}].comment`}
                                                                                            label="Comment"
                                                                                            disabled={false}
                                                                                        />
                                                                                    </fieldset>
                                                                                </div>
                                                                                <div className="col-2">
                                                                                    <Button
                                                                                        className="me-1 mt-2 btn-danger btn-sm"
                                                                                        type="button"
                                                                                        onClick={() => arrayHelpers.remove(index)}
                                                                                    >
                                                                                        -
                                                                                    </Button>
                                                                                    <Button
                                                                                        className="btn-sm mt-2"
                                                                                        type="button"
                                                                                        onClick={() =>
                                                                                            arrayHelpers.insert(index, defaultAlias)
                                                                                        }
                                                                                    >
                                                                                        +
                                                                                    </Button>
                                                                                </div>
                                                                            </div>
                                                                        ))
                                                                    ) : (
                                                                        <Button
                                                                            className="btn-sm ms-1"
                                                                            type="button"
                                                                            onClick={() => arrayHelpers.push(defaultAlias)}
                                                                        >
                                                                            +
                                                                        </Button>
                                                                    )}
                                                                </Fragment>
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-12 col-xl-6 mt-3"></div>
                                                </div>
                                                {data && data.instrument && Array.isArray(data.instrument.extensions) ? (
                                                    <div className="row">
                                                        <div className="col-12 col-xl-6 mt-3">
                                                            <Link to={"/extensions"}>
                                                                <h5>Client specific extensions</h5>
                                                            </Link>
                                                            {data.instrument.extensions.map((extension) => {
                                                                return (
                                                                    <div
                                                                        key={extension._id}
                                                                        style={{
                                                                            border: "1px solid #ddd",
                                                                            borderRadius: "5px",
                                                                            padding: "10px",
                                                                            marginBottom: "10px",
                                                                            backgroundColor: "#f9f9f9"
                                                                        }}
                                                                    >
                                                                        <div style={{ marginTop: "5px" }}>
                                                                            <strong>{"_id: "}</strong>
                                                                            <Link to={"/extensions/" + extension._id}>{extension._id}</Link>
                                                                        </div>
                                                                        <div style={{ marginTop: "5px" }}>
                                                                            <strong>{"client: "}</strong>
                                                                            <Link to={"/parties/" + extension.clientId}>
                                                                                {extension.client.name}
                                                                            </Link>
                                                                        </div>
                                                                        <div style={{ marginTop: "5px" }}>
                                                                            <strong>Key:</strong> {extension.key}
                                                                        </div>
                                                                        <div>
                                                                            <strong>Value:</strong> {extension.value}
                                                                        </div>
                                                                        <div>
                                                                            <strong>Comment:</strong> {extension.comment}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-3 col-xs-12">
                                                <SearchMultipleSelectField
                                                    name="clientIds"
                                                    label="Clients (owner)"
                                                    className="w-100"
                                                    disabled={isSubmitting}
                                                    options={clients.map((d) => ({ key: d._id, value: d.name, text: d.name }))}
                                                />

                                                <div className="form-row">
                                                    <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                                                        <TextField
                                                            name="bloombergTicker"
                                                            label="Bloomberg ticker"
                                                            type="text"
                                                            className=""
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                                        <NumberField
                                                            name="bloombergQuoteMultiplier"
                                                            label="Multiplier"
                                                            type="number"
                                                            className=""
                                                            disabled={isSubmitting}
                                                            min={0}
                                                            max={10000}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                                                        <TextField
                                                            name="exchange"
                                                            label="Exchange"
                                                            type="text"
                                                            className=""
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>

                                                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                                                        <TextField
                                                            name="mic"
                                                            label="MIC"
                                                            type="text"
                                                            className=""
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                        <TextField
                                                            name="isin"
                                                            label={
                                                                <a
                                                                    href={"https://search.gleif.org/#/search/simpleSearch=" + values.isin}
                                                                    target={"_blank"}
                                                                    rel="noreferrer"
                                                                >
                                                                    {"Isin"}
                                                                </a>
                                                            }
                                                            type="text"
                                                            className=""
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                                        <TextField
                                                            name="cic"
                                                            label="Cic"
                                                            type="text"
                                                            className=""
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 mt-3">
                                                        <CheckboxItem name="bloombergDailyDownload" label="Bloomberg daily download" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {alert.visible ? (
                                            <Alert variant={alert.color} onClose={onDismissAlert} dismissible>
                                                {alert.message}
                                            </Alert>
                                        ) : null}

                                        <SubmitButton
                                            disabled={isSubmitting}
                                            label={isCreateMode ? "Create" : "Update"}
                                            className="btn btn-primary btn-lg"
                                        />
                                    </Form>
                                </div>
                            )}
                        </Formik>
                    ) : null}
                </div>

                <hr />

                {data && data.instrument ? (
                    <div>
                        <div className="row form-group mt-3">
                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                <Link to={"/timeseries?instrumentId=" + data.instrument._id}>
                                    <h4>Timeseries</h4>
                                </Link>
                                {data.timeseries && data.timeseries.length > 0 ? (
                                    <div className="mt-2">
                                        {data.timeseries.map((timeseries, index) => {
                                            return (
                                                <div key={index}>
                                                    <Link to={"/timeseries/" + timeseries._id}>{timeseries.type}</Link>
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : null}
                                <div className="mt-2">
                                    <Link to={"/timeseries/new?instrumentId=" + data.instrument._id}>{"New timeseries"}</Link>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                <h4>Valuation</h4>
                                <div className="mt-2">
                                    <Link to={"/valuations?instrumentIds=" + data.instrument._id}>{"Valuations"}</Link>
                                </div>
                                {data && data.lastValuations && data.lastValuations.length > 0 ? (
                                    <div className="mt-2">
                                        <Link to={"/valuation/" + data.lastValuations[0]._id}>
                                            {"Last: " + data.lastValuations[0].date}
                                        </Link>
                                    </div>
                                ) : null}
                                <div className="mt-2">
                                    <Link to={"/valuation/add?instrumentIdIn=" + data.instrument._id}>{"Add valuation"}</Link>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                <h4>Transactions</h4>
                                <div className="mt-2">
                                    <Link to={"/transactions?qInstrumentId=" + data.instrument._id + "&qStartDate=" + startDate}>
                                        {"Transactions"}
                                    </Link>
                                </div>
                                <div className="mt-2">
                                    <Link to={"/instrumentpositions?instrumentIds=" + data.instrument._id}>{"Positions"}</Link>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                <h4>Custodian Position</h4>
                                <div className="mt-2">
                                    <Link to={"/reconciliation/custodianpositions?instrumentIdIn=" + data.instrument._id}>
                                        {"Custodian Position"}
                                    </Link>
                                </div>
                            </div>

                            {data && data.instrument && data && data.instrument.modelType === InstrumentModelTypeEnum.PortfolioSwapX ? (
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                    <h4>Swap constituents</h4>
                                    <div className="mt-2">
                                        <Link to={"/swapconstituents/" + data.instrument._id}>{"Swap constituents"}</Link>
                                    </div>
                                </div>
                            ) : null}

                            <div className="col-lg-5 col-md-5 col-sm-0 col-xs-0">&nbsp;</div>
                        </div>

                        <hr />

                        <div className="row form-group h-100">
                            <div className="col-lg-6 mt-3">
                                <div className="row">
                                    <div className="col-xs-8 col-md-8 col-lg-8">
                                        <h4>update json</h4>
                                    </div>
                                    <div className="col-xs-4 col-md-4 col-lg-4s">
                                        <CopyClipboardButton
                                            id="source-json"
                                            text={JSON.stringify(cleanJsonInput(data.instrument), null, 2)}
                                        ></CopyClipboardButton>
                                    </div>
                                </div>
                                <div className="border">
                                    <pre>{JSON.stringify(data.instrument, null, 2)}</pre>
                                </div>
                            </div>
                            <div className="col-lg-6 mt-3 h-100">
                                <div className="row">
                                    <div className="col-xs-8 col-md-8 col-lg-8">
                                        <h4>update json</h4>
                                    </div>
                                    <div className="col-xs-4 col-md-4 col-lg-4">
                                        <CopyClipboardButton id="target-json" text={jsonString}></CopyClipboardButton>
                                    </div>
                                </div>
                                <form className="h-100">
                                    <div className="border h-100">
                                        <textarea
                                            className="form-control h-100"
                                            id="jsonInstrument"
                                            name="jsonInstrument"
                                            rows={45}
                                            value={jsonString}
                                            onChange={handleJsonChange}
                                        />
                                    </div>
                                    <button
                                        type="submit"
                                        className="btn btn-danger btn-sm mt-2"
                                        onClick={handleJsonSubmit}
                                        disabled={!jsonValidation.valid || !jsonString}
                                    >
                                        Update instrument
                                    </button>
                                    <button type="button" className="btn btn-info btn-sm mt-2 ms-2" onClick={handleJsonReset}>
                                        Init
                                    </button>

                                    {!jsonValidation.valid ? (
                                        <Alert
                                            className="mt-3"
                                            color={jsonValidation.valid ? "success" : "danger"}
                                            onClose={onDismissValidationAlert}
                                            dismissible
                                        >
                                            {jsonValidation.message}
                                        </Alert>
                                    ) : null}
                                </form>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
}
