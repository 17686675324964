import React from "react";
import { Routes, Route, Outlet, useLocation } from "react-router-dom";
import classnames from "classnames";

import { ContextProvider } from "./contexts/ContextProvider";
import { REACT_APP_NAME } from "./env";

import { JournalEntriesForm } from "./containers/accounting/JournalEntriesForm";
import { AddValuationPage } from "./containers/AddValuationPage";
import { AumPage } from "./containers/AumPage";
import { BrokerTransactionsCompletePage } from "./containers/BrokerTransactionsPage";
import { BrokerConfirmationsCompletePage } from "./containers/BrokerConfirmationsPage";
import { ChangePasswordPage } from "./containers/ChangePasswordPage";
import { CollateralPage } from "./containers/collateral/CollateralPage";
import { CorporateActionsCompletePage } from "./containers/CorporateActionsPage";
import { SwiftCorporateActionsPage } from "./containers/SwiftCorporateActionsPage";
import { CorporateActionPage } from "./containers/CorporateActionPage";
import { DealBlotterPage } from "./containers/DealBlotterPage";
import { InstrumentPage } from "./containers/InstrumentPage";
import { InstrumentsPage } from "./containers/InstrumentsPage";
import { FundInfoPage } from "./containers/FundInfoPage";
import { LimitV3Page } from "./containers/LimitV3Page";
import { PartiesPage } from "./containers/PartiesPage";
import { PortfolioManagerPage } from "./containers/pm/PortfolioManagerPage";
import { PortfolioManagerAccounting } from "./containers/accounting/PortfolioManagerAccounting";
import { PositionCashBalancePage } from "./containers/PositionCashBalancePage";
import { PositionFundUnitsPage } from "./containers/PositionFundUnitsPage";
import { ReconciliationPage } from "./containers/ReconciliationPage";
import { PositionReconciliationPage } from "./containers/PositionReconciliationPage";
import { ReportPage } from "./containers/ReportPage";
import { ReportsPage } from "./containers/ReportsPage";
import { SlashPage } from "./containers/SlashPage";
import { StatsPage } from "./containers/StatsPage";
import { InstrumentPositionsPage } from "./containers/InstrumentPositionsPage";
import { TAccountChartPage } from "./containers/accounting/TAccountChartPage";
import { TimeSeriesPage } from "./containers/timeseries/TimeSeriesPage";
import { TokenPage } from "./containers/TokenPage";
import { TransactionPage } from "./containers/TransactionPage";
import { VersionsPage } from "./containers/VersionsPage";
import { TransactionsPage } from "./containers/TransactionsPage";
import { TransferAgentOrdersPage } from "./containers/TransferAgentOrdersPage";
import { ValuationPage } from "./containers/ValuationPage";
import { ValuationsPage } from "./containers/ValuationsPage";
import { AdminUsersPage } from "./containers/admin/AdminUsersPage";
import { AdminUserPage } from "./containers/admin/AdminUserPage";
import { AdminRolesPage } from "./containers/admin/AdminRolesPage";
import { AdminRolePage } from "./containers/admin/AdminRolePage";
import { CalculateNavPage } from "./containers/nav-v2/CalculateNavPage";
import { NavPage as Nav2Page } from "./containers/nav-v2/NavPage";
import { NavsPage } from "./containers/nav-v2/NavsPage";
import { ScreensPage } from "./containers/ScreensPage";
import { ScreenPage } from "./containers/ScreenPage";
import { EsgPage } from "./containers/EsgPage";
import { IssuerProgramsPage } from "./containers/IssuerProgramsPage";
import { IssuerProgramPage } from "./containers/IssuerProgramPage";
import { PartyPage } from "./containers/party/PartyPage";
import { PdfViewPage } from "./containers/PdfViewPage";
import { PdfViewPages } from "./containers/PdfViewPages";
import { TransferAgentOrdersSummaryPage } from "./containers/TransferAgentOrdersSummaryPage";
import { TimeSeriesEditorPage } from "./containers/timeseries/TimeSeriesEditorPage";
import { MiniTransactionPage } from "./containers/MiniTransactionPage";
import { TicketsPage } from "./containers/TicketsPage";
import { TicketPage } from "./containers/TicketPage";
import { StatementsReportingPage } from "./containers/accounting/statementsreporting/StatementsReportingPage";
import { SwapLab2Page } from "./containers/swaplab2/SwapLab2Page";
import { ValuationMappingsPage } from "./containers/ValuationMappingsPage";
import { ValuationMappingPage } from "./containers/ValuationMappingPage";
import { PerformancePage } from "./containers/performance/PerformancePage";
import { PortfolioRiskAndLimits } from "./containers/start/PortfolioRiskAndLimits";
import { OperationsTabPage } from "./containers/start/OperationsTabPage";
import { ClientPage } from "./containers/ClientPage";
import { ComparePage } from "./containers/ComparePage";
import { PositionsGroupedPage } from "./containers/PositionsGroupedPage";
import { BankAccountTransactionsPage } from "./containers/BankAccountTransactionsPage";
import { CalendarsPage } from "./containers/CalendarsPage";
import { CalendarPage } from "./containers/CalendarPage";
import { CurveDefinitionPage } from "./containers/CurveDefinitionPage";
import { CurveDefinitionsPage } from "./containers/CurveDefinitionsPage";
import { AgreementsPage } from "./containers/AgreementsPage";
import { AgreementPage } from "./containers/AgreementPage";
import { ValuationComparePage } from "./containers/swaplab2/ValuationComparePage";
import { NotesPage } from "./containers/note/NotesPage";
import { NotePage } from "./containers/note/NotePage";
import { EsgNotePage } from "./containers/note/EsgNotePage";
import { DurationRiskDocPage } from "./containers/swaplab2/DurationRiskDocPage";
import { TermsOfServicePage } from "./containers/TermsOfServicePage";
import { TransactionItemsPage } from "./containers/TransactionItemsPage";
import { CustodianPositionsPage } from "./containers/CustodianPositionsPage";
import { CustodianPositionPage } from "./containers/CustodianPositionPage";
import { TestPage } from "./containers/TestPage";
import { ShareRegisterPage } from "./containers/ShareRegisterPage";
import { QuotesPage } from "./containers/QuotesPage";
import { QuotePage } from "./containers/QuotePage";
import { PricesPage } from "./containers/PricesPage";
import { PricePage } from "./containers/PricePage";
import { BankAccountReconciliationPage } from "./containers/BankAccountsReconciliationPage";
import { SwedishFinancialAuthoritiesPage } from "./containers/reporting/SwedishFinancialAuthoritiesPage";
import { SwedishFiClassificationPage } from "./containers/reporting/SwedishFiClassificationPage";
import { AnnualReportPage } from "./containers/reporting/AnnualReportPage";
import { AnnualReportsPage } from "./containers/reporting/AnnualReportsPage";
import { SideNav } from "./containers/SideNav";
import { Nav } from "./components/Nav";
import { CustomersPage } from "./containers/customer/CustomersPage";
import { DownloadFile } from "./components/form/DownloadFile";
import { EdgeAlert } from "./components/form/EdgeAlert";
import { AdminCleanPerformanceCachePage } from "./containers/admin/AdminCleanPerformanceCachePage";
import { JobRunPage } from "./containers/scheduler/JobRunPage";
import { JobsPage } from "./containers/scheduler/JobsPage";
import { JobPage as Job2Page } from "./containers/scheduler/JobPage";
import { CostPage } from "./containers/backoffice/CostPage";
import { CostsPage } from "./containers/backoffice/CostsPage";
import { MonthlyCostsPage } from "./containers/backoffice/MonthlyCostsPage";
import { SwapConstituentsPage } from "./containers/SwapConstituentsPage";
import { RegisterExtractsPage } from "./containers/RegisterExtractsPage";
import { RegisterExtractPage } from "./containers/RegisterExtractPage";
import { PortfolioManagerAccounting2 } from "./containers/accounting/PortfolioManagerAccounting2";
import { ExtensionsPage } from "./containers/ExtensionsPage";
import { ExtensionPage } from "./containers/ExtensionPage";

function capitalizeFirstLetter(s: string): string {
    if (s) {
        return s.charAt(0).toUpperCase() + s.slice(1);
    } else {
        return "";
    }
}

const NoMatch = () => {
    const location = useLocation();
    return (
        <div>
            <h3>
                No match for <code>{location.pathname}</code>
            </h3>
        </div>
    );
};

const ProtectedLayout = (): React.ReactElement => {
    return (
        <div>
            <SideNav />
            <header>
                <EdgeAlert />
                <Nav title={capitalizeFirstLetter(REACT_APP_NAME) + " Portal"} />
            </header>
            <Outlet />
        </div>
    );
};

interface ILayoutProps {
    fullWidth?: boolean;
    fullHeight?: boolean;
    children?: React.ReactElement;
}
const Layout = ({ children, fullWidth = false, fullHeight = false }: ILayoutProps): React.ReactElement => {
    return (
        <div className={classnames({ container: !fullWidth && !fullHeight, "container-fluid": fullWidth, "h-100": fullHeight })}>
            {children}
        </div>
    );
};

export const App = (): React.ReactElement => {
    return (
        <ContextProvider>
            <div className="app">
                <Routes>
                    <Route path="/" element={<ProtectedLayout />}>
                        <Route path="/" element={<SlashPage />} />
                        <Route
                            path="/client"
                            element={
                                <Layout fullWidth>
                                    <ClientPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/client/:clientDashName"
                            element={
                                <Layout fullWidth>
                                    <ClientPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/aum"
                            element={
                                <Layout fullWidth fullHeight>
                                    <AumPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/calculatenav"
                            element={
                                <Layout fullWidth>
                                    <CalculateNavPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/calculatenav/:fundDashName"
                            element={
                                <Layout fullWidth>
                                    <CalculateNavPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/navs"
                            element={
                                <Layout fullWidth>
                                    <NavsPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/nav2/:id"
                            element={
                                <Layout fullWidth>
                                    <Nav2Page />
                                </Layout>
                            }
                        />
                        <Route
                            path="/fundinfo"
                            element={
                                <Layout fullWidth={false}>
                                    <FundInfoPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/fundinfo/:clientDashName"
                            element={
                                <Layout fullWidth={false}>
                                    <FundInfoPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/parties/:id"
                            element={
                                <Layout fullWidth>
                                    <PartyPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/parties/:id/:tabId"
                            element={
                                <Layout fullWidth>
                                    <PartyPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/parties/:id/:tabId/:itemId"
                            element={
                                <Layout fullWidth>
                                    <PartyPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/parties"
                            element={
                                <Layout fullWidth>
                                    <PartiesPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/screens"
                            element={
                                <Layout fullWidth>
                                    <ScreensPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/screens/:id"
                            element={
                                <Layout fullWidth={false}>
                                    <ScreenPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/shareregister"
                            element={
                                <Layout fullWidth>
                                    <ShareRegisterPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/shareregister/:tabId"
                            element={
                                <Layout fullWidth>
                                    <ShareRegisterPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/esg"
                            element={
                                <Layout>
                                    <EsgPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/esg/:clientDashName"
                            element={
                                <Layout>
                                    <EsgPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/esgnotes"
                            element={
                                <Layout>
                                    <NotesPage esgOnly={true} routeToNew={"/esgnotes/new"} routeToNewText={"New ESG note"} />
                                </Layout>
                            }
                        />
                        <Route
                            path="/esgnotes/:id"
                            element={
                                <Layout fullWidth={true} fullHeight={true}>
                                    <EsgNotePage routeToNotes={"/esgnotes"} routeToNotesText={"All ESG notes"}></EsgNotePage>
                                </Layout>
                            }
                        />
                        <Route
                            path="/issuerprograms"
                            element={
                                <Layout fullWidth>
                                    <IssuerProgramsPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/issuerprograms/:id"
                            element={
                                <Layout fullWidth={false}>
                                    <IssuerProgramPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/reconciliation"
                            element={
                                <Layout fullWidth={false}>
                                    <ReconciliationPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/reconciliation/custodianpositions"
                            element={
                                <Layout fullWidth>
                                    <CustodianPositionsPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/reconciliation/custodianpositions/:id"
                            element={
                                <Layout fullWidth={false}>
                                    <CustodianPositionPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/positionreconciliation"
                            element={
                                <Layout fullWidth>
                                    <PositionReconciliationPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/reconcile/brokertransactions/:type/:id"
                            element={
                                <Layout fullWidth>
                                    <BrokerTransactionsCompletePage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/reconcile/brokertransactions"
                            element={
                                <Layout fullWidth>
                                    <BrokerTransactionsCompletePage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/brokerconfirmations"
                            element={
                                <Layout fullWidth>
                                    <BrokerConfirmationsCompletePage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/brokerconfirmations/:type/:id"
                            element={
                                <Layout fullWidth>
                                    <BrokerConfirmationsCompletePage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/reconcile/corporateactions/:type/:id"
                            element={
                                <Layout fullWidth>
                                    <CorporateActionsCompletePage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/reconcile/corporateactions"
                            element={
                                <Layout fullWidth>
                                    <CorporateActionsCompletePage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/corporateaction/:id"
                            element={
                                <Layout fullWidth={false}>
                                    <CorporateActionPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/swiftcorporateactions"
                            element={
                                <Layout fullWidth>
                                    <SwiftCorporateActionsPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/swiftcorporateactions/:type/:id"
                            element={
                                <Layout fullWidth>
                                    <SwiftCorporateActionsPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/transferagentorders/summary"
                            element={
                                <Layout fullWidth>
                                    <TransferAgentOrdersSummaryPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/transferagentorders"
                            element={
                                <Layout fullWidth>
                                    <TransferAgentOrdersPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/transferagentorders/:id"
                            element={
                                <Layout fullWidth>
                                    <TransferAgentOrdersPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/reconcile/cashbalance"
                            element={
                                <Layout fullWidth>
                                    <PositionCashBalancePage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/reconcile/cashbalance/:clientDashName/:id"
                            element={
                                <Layout fullWidth>
                                    <PositionCashBalancePage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/reporting/annualreports"
                            element={
                                <Layout fullWidth>
                                    <AnnualReportsPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/reporting/annualreports/:id"
                            element={
                                <Layout fullWidth>
                                    <AnnualReportPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/backoffice/costs"
                            element={
                                <Layout fullWidth>
                                    <CostsPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/backoffice/costs/:id"
                            element={
                                <Layout>
                                    <CostPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/reconcile/bankaccounts/:clientDashName"
                            element={
                                <Layout fullWidth>
                                    <BankAccountReconciliationPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/reconcile/bankaccounts"
                            element={
                                <Layout fullWidth>
                                    <BankAccountReconciliationPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/reconcile/bankaccounts/:clientDashName/:id"
                            element={
                                <Layout fullWidth>
                                    <BankAccountReconciliationPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/reconcile/cashbalance/:clientDashName"
                            element={
                                <Layout fullWidth>
                                    <PositionCashBalancePage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/bankaccounttransactions"
                            element={
                                <Layout fullWidth={false}>
                                    <BankAccountTransactionsPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/calendars"
                            element={
                                <Layout fullWidth>
                                    <CalendarsPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/calendars/:id"
                            element={
                                <Layout fullWidth>
                                    <CalendarPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/reconcile/collateral"
                            element={
                                <Layout fullWidth>
                                    <CollateralPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/reconcile/collateral/:clientDashName"
                            element={
                                <Layout fullWidth>
                                    <CollateralPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/reconcile/collateral/:clientDashName/:id"
                            element={
                                <Layout fullWidth>
                                    <CollateralPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/reconcile/fundunits"
                            element={
                                <Layout fullWidth={false}>
                                    <PositionFundUnitsPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/token"
                            element={
                                <Layout fullWidth={false}>
                                    <TokenPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/transaction/:id"
                            element={
                                <Layout fullWidth={false}>
                                    <TransactionPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/versions"
                            element={
                                <Layout fullWidth={true}>
                                    <VersionsPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/versions/:type"
                            element={
                                <Layout fullWidth={true}>
                                    <VersionsPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/versions/:type/:id"
                            element={
                                <Layout fullWidth={false}>
                                    <VersionsPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/valuation/add"
                            element={
                                <Layout fullWidth={false}>
                                    <AddValuationPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/swapconstituents/:instrumentId"
                            element={
                                <Layout fullWidth={false}>
                                    <SwapConstituentsPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/instruments/:id"
                            element={
                                <Layout fullWidth={false}>
                                    <InstrumentPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/instruments"
                            element={
                                <Layout fullWidth>
                                    <InstrumentsPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/tickets/:id"
                            element={
                                <Layout fullWidth={false}>
                                    <TicketPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/tickets"
                            element={
                                <Layout fullWidth={false}>
                                    <TicketsPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/jobs"
                            element={
                                <Layout fullWidth={false}>
                                    <JobsPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/job/:id"
                            element={
                                <Layout fullWidth={false}>
                                    <Job2Page />
                                </Layout>
                            }
                        />
                        <Route
                            path="/jobrun/:id"
                            element={
                                <Layout>
                                    <JobRunPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/journalentries/:id"
                            element={
                                <Layout fullWidth>
                                    <JournalEntriesForm />
                                </Layout>
                            }
                        />
                        <Route
                            path="/journalentries/:clientId/:accountingPeriod"
                            element={
                                <Layout fullWidth>
                                    <JournalEntriesForm />
                                </Layout>
                            }
                        />
                        <Route
                            path="/journalentries/:clientId/:accountingPeriod/:maxNumber"
                            element={
                                <Layout fullWidth>
                                    <JournalEntriesForm />
                                </Layout>
                            }
                        />
                        <Route
                            path="/attachment/pdf/:id"
                            element={
                                <Layout fullWidth={false}>
                                    <PdfViewPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/attachment/pdf/:id/pages"
                            element={
                                <Layout fullWidth={false}>
                                    <PdfViewPages />
                                </Layout>
                            }
                        />
                        <Route
                            path="/journalentries/:clientId/:accountingPeriod/:maxNumber/:type"
                            element={
                                <Layout fullWidth>
                                    <JournalEntriesForm />
                                </Layout>
                            }
                        />
                        <Route
                            path="/journalentries/:clientId/:accountingPeriod/:maxNumber/:type/:journalEntryId"
                            element={
                                <Layout fullWidth>
                                    <JournalEntriesForm />
                                </Layout>
                            }
                        />
                        <Route
                            path="/limitreportv3"
                            element={
                                <Layout fullWidth={false}>
                                    <LimitV3Page />
                                </Layout>
                            }
                        />
                        <Route
                            path="/limitreportv3/:clientDashName"
                            element={
                                <Layout fullWidth={false}>
                                    <LimitV3Page />
                                </Layout>
                            }
                        />
                        <Route
                            path="/reporting/quarterly"
                            element={
                                <Layout fullWidth={false}>
                                    <SwedishFinancialAuthoritiesPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/reporting/quarterly/:clientDashName"
                            element={
                                <Layout fullWidth={false}>
                                    <SwedishFinancialAuthoritiesPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/reporting/classification"
                            element={
                                <Layout fullWidth={false}>
                                    <SwedishFiClassificationPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/report/:id"
                            element={
                                <Layout fullWidth>
                                    <ReportPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/reports"
                            element={
                                <Layout fullWidth>
                                    <ReportsPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/stats"
                            element={
                                <Layout fullWidth={false}>
                                    <StatsPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/testtaccountchart"
                            element={
                                <Layout fullWidth={false}>
                                    <TAccountChartPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/taccountcharts/:id/:tAccountIndex/:tAccountMappingIndex"
                            element={
                                <Layout fullWidth>
                                    <TAccountChartPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/taccountcharts/:id"
                            element={
                                <Layout fullWidth>
                                    <TAccountChartPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/taccountcharts"
                            element={
                                <Layout fullWidth>
                                    <TAccountChartPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/test"
                            element={
                                <Layout fullWidth={false}>
                                    <TestPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/instrumentpositions"
                            element={
                                <Layout fullWidth={false}>
                                    <InstrumentPositionsPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/financialstatements"
                            element={
                                <Layout fullWidth>
                                    <StatementsReportingPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/financialstatements/:tab"
                            element={
                                <Layout fullWidth>
                                    <StatementsReportingPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/financialstatements/:tab/:portfolioTransactionId"
                            element={
                                <Layout fullWidth>
                                    <StatementsReportingPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/accountingpm/:id/:endDate/:export"
                            element={
                                <Layout fullWidth>
                                    <PortfolioManagerAccounting />
                                </Layout>
                            }
                        />
                        <Route
                            path="/accountingpm/:id/:endDate"
                            element={
                                <Layout fullWidth>
                                    <PortfolioManagerAccounting />
                                </Layout>
                            }
                        />
                        <Route
                            path="/accountingpm/:id"
                            element={
                                <Layout fullWidth>
                                    <PortfolioManagerAccounting />
                                </Layout>
                            }
                        />
                        <Route
                            path="/accountingpm"
                            element={
                                <Layout fullWidth>
                                    <PortfolioManagerAccounting />
                                </Layout>
                            }
                        />
                        <Route
                            path="/accountingpm2/:id/:endDate/:export"
                            element={
                                <Layout fullWidth>
                                    <PortfolioManagerAccounting2 />
                                </Layout>
                            }
                        />
                        <Route
                            path="/accountingpm2/:id/:endDate"
                            element={
                                <Layout fullWidth>
                                    <PortfolioManagerAccounting2 />
                                </Layout>
                            }
                        />
                        <Route
                            path="/accountingpm2/:id"
                            element={
                                <Layout fullWidth>
                                    <PortfolioManagerAccounting2 />
                                </Layout>
                            }
                        />
                        <Route
                            path="/accountingpm2"
                            element={
                                <Layout fullWidth>
                                    <PortfolioManagerAccounting2 />
                                </Layout>
                            }
                        />
                        <Route
                            path="/timeseries/:id"
                            element={
                                <Layout fullWidth={false}>
                                    <TimeSeriesEditorPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/timeseries"
                            element={
                                <Layout fullWidth>
                                    <TimeSeriesPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/transactions/:id"
                            element={
                                <Layout fullWidth>
                                    <TransactionsPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/transactions/:type/:id"
                            element={
                                <Layout fullWidth>
                                    <TransactionsPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/transactions"
                            element={
                                <Layout fullWidth>
                                    <TransactionsPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/transactionitems/:id"
                            element={
                                <Layout fullWidth>
                                    <TransactionItemsPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/registerextracts"
                            element={
                                <Layout fullWidth>
                                    <RegisterExtractsPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/registerextracts/:id"
                            element={
                                <Layout fullWidth>
                                    <RegisterExtractPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/transactionitems"
                            element={
                                <Layout fullWidth>
                                    <TransactionItemsPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/dealblotter/:id"
                            element={
                                <Layout fullWidth>
                                    <DealBlotterPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/dealblotter"
                            element={
                                <Layout fullWidth>
                                    <DealBlotterPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/minitransactions/:id"
                            element={
                                <Layout fullWidth={false}>
                                    <MiniTransactionPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/minitransactions"
                            element={
                                <Layout fullWidth={false}>
                                    <MiniTransactionPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/pm/:id/:tab/:type/:transactionId"
                            element={
                                <Layout fullWidth>
                                    <PortfolioManagerPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/pm/:id/:tab"
                            element={
                                <Layout fullWidth>
                                    <PortfolioManagerPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/pm/:id"
                            element={
                                <Layout fullWidth>
                                    <PortfolioManagerPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/pm"
                            element={
                                <Layout fullWidth>
                                    <PortfolioManagerPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/valuation/:id"
                            element={
                                <Layout fullWidth={false}>
                                    <ValuationPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/valuations"
                            element={
                                <Layout fullWidth={false}>
                                    <ValuationsPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/changepassword"
                            element={
                                <Layout fullWidth={false}>
                                    <ChangePasswordPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/admin/users"
                            element={
                                <Layout fullWidth>
                                    <AdminUsersPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/admin/cleancache"
                            element={
                                <Layout fullWidth>
                                    <AdminCleanPerformanceCachePage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/admin/users/new"
                            element={
                                <Layout fullWidth={false}>
                                    <AdminUserPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/admin/users/:id"
                            element={
                                <Layout fullWidth={false}>
                                    <AdminUserPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/admin/roles"
                            element={
                                <Layout fullWidth>
                                    <AdminRolesPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/admin/roles/new"
                            element={
                                <Layout fullWidth={false}>
                                    <AdminRolePage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/admin/roles/:id"
                            element={
                                <Layout fullWidth={false}>
                                    <AdminRolePage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/swaplab2"
                            element={
                                <Layout fullWidth={false}>
                                    <SwapLab2Page />
                                </Layout>
                            }
                        />
                        <Route
                            path="/swaplab2/compare"
                            element={
                                <Layout fullWidth>
                                    <ValuationComparePage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/valuationmappings"
                            element={
                                <Layout fullWidth>
                                    <ValuationMappingsPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/valuationmapping/:id"
                            element={
                                <Layout fullWidth={false}>
                                    <ValuationMappingPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/performance"
                            element={
                                <Layout fullWidth>
                                    <PerformancePage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/performance/:clientDashName"
                            element={
                                <Layout fullWidth>
                                    <PerformancePage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/performance/:clientDashName/:tabId"
                            element={
                                <Layout fullWidth>
                                    <PerformancePage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/positions"
                            element={
                                <Layout fullWidth>
                                    <PositionsGroupedPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/positions/:clientDashName"
                            element={
                                <Layout fullWidth>
                                    <PositionsGroupedPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/portfoliorisk"
                            element={
                                <Layout fullWidth>
                                    <PortfolioRiskAndLimits />
                                </Layout>
                            }
                        />
                        <Route
                            path="/portfoliorisk/:clientDashName"
                            element={
                                <Layout fullWidth>
                                    <PortfolioRiskAndLimits />
                                </Layout>
                            }
                        />
                        <Route
                            path="/portfoliorisk/:clientDashName/:tabId"
                            element={
                                <Layout fullWidth>
                                    <PortfolioRiskAndLimits />
                                </Layout>
                            }
                        />
                        <Route
                            path="/compare"
                            element={
                                <Layout fullWidth>
                                    <ComparePage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/operations"
                            element={
                                <Layout fullWidth>
                                    <OperationsTabPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/operations/:clientDashName"
                            element={
                                <Layout fullWidth>
                                    <OperationsTabPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/operations/:tabId"
                            element={
                                <Layout fullWidth>
                                    <OperationsTabPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/operations/:clientDashName/:tabId"
                            element={
                                <Layout fullWidth>
                                    <OperationsTabPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/operations/:clientDashName/:tabId/:transId"
                            element={
                                <Layout fullWidth>
                                    <OperationsTabPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/curvedefinitions"
                            element={
                                <Layout fullWidth={false}>
                                    <CurveDefinitionsPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/curvedefinitions/:id"
                            element={
                                <Layout fullWidth={false}>
                                    <CurveDefinitionPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/agreements"
                            element={
                                <Layout fullWidth={false}>
                                    <AgreementsPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/agreements/:id"
                            element={
                                <Layout fullWidth={false}>
                                    <AgreementPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/notes"
                            element={
                                <Layout fullWidth={false}>
                                    <NotesPage esgOnly={false} routeToNew={"/notes/new"} routeToNewText={"New note"} />
                                </Layout>
                            }
                        />
                        <Route
                            path="/notes/:id"
                            element={
                                <Layout fullWidth={true} fullHeight={true}>
                                    <NotePage routeToNotes={"/notes"} routeToNotesText={"All notes"}></NotePage>
                                </Layout>
                            }
                        />
                        <Route
                            path="/riskdocs"
                            element={
                                <Layout fullWidth={false}>
                                    <DurationRiskDocPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/quotes"
                            element={
                                <Layout fullWidth={false}>
                                    <QuotesPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/quotes/:id"
                            element={
                                <Layout fullWidth={false}>
                                    <QuotePage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/prices/:id"
                            element={
                                <Layout fullWidth={true} fullHeight={true}>
                                    <PricePage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/prices"
                            element={
                                <Layout fullWidth={false}>
                                    <PricesPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/termsofservice"
                            element={
                                <Layout fullWidth={false}>
                                    <TermsOfServicePage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/backoffice/customers/:id"
                            element={
                                <Layout fullWidth={true} fullHeight={true}>
                                    <CustomersPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/backoffice/customers"
                            element={
                                <Layout fullWidth={true}>
                                    <CustomersPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/monthlycosts"
                            element={
                                <Layout fullWidth={true}>
                                    <MonthlyCostsPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/extensions"
                            element={
                                <Layout fullWidth={true}>
                                    <ExtensionsPage />
                                </Layout>
                            }
                        />
                        <Route
                            path="/extensions/:id"
                            element={
                                <Layout fullWidth={true}>
                                    <ExtensionPage />
                                </Layout>
                            }
                        />
                        <Route path="/files/:fileId" element={<DownloadFile />} />
                        <Route
                            path="*"
                            element={
                                <Layout fullWidth={false}>
                                    <NoMatch />
                                </Layout>
                            }
                        />
                    </Route>
                </Routes>
            </div>
        </ContextProvider>
    );
};

/*
TODO: Should put this in later on. Best based not on NODE_ENV
TODO: but on permissions from api
if (NODE_ENV === "production") {
    // This is to turn off console.log etc in prod
    [
        "assert",
        "clear",
        "count",
        "debug",
        "dir",
        "dirxml",
        "error",
        "exception",
        "group",
        "groupCollapsed",
        "groupEnd",
        "info",
        "log",
        "profile",
        "profileEnd",
        "table",
        "time",
        "timeEnd",
        "timeStamp",
        "trace",
        "warn"
    ].forEach((methodName) => {
        // eslint-disable-next-line no-console
        console[methodName] = () => {
            // noop
        };
    });
}
*/
