import React, { Fragment, useEffect, useState } from "react";
import { Grid, Column } from "../../../../components/src";
import { sortBy, cloneDeep, keyBy, round, groupBy } from "lodash";
import { numberFormatFun, numberFormat } from "../../../../common/src";
import { gql, useQuery } from "urql";
import { Link, useLocation } from "react-router-dom";
import stableStringify from "json-stable-stringify";

import { miniTransactionTypes } from "../MiniTransactionForm";
import {
    AgreementType,
    CurrencyEnum,
    Instrument,
    MiniTransaction,
    Party,
    ReconciledExposureCollateralType,
    ReconcileTypeEnum,
    TransactionType
} from "../../types.generated";
import { groupClientGridData } from "./CollateralFunctions";
import { SecuritiesCollateralGrid } from "./SecuritiesCollateralGrid";
import { NetExposureGrid } from "./NetExposureGrid";
import { TooltipLabel } from "../../components/TooltipLabel";

const GET_MINITRANSACTIONS = gql`
    query miniTransaction(
        $instrumentIds: [GraphQLObjectId!]
        $clientIds: [GraphQLObjectId!]
        $type: [TransactionType!]
        $startDate: GraphQLDateString
        $endDate: GraphQLDateString
    ) {
        miniTransactions(
            filter: {
                instrumentIdsIn: $instrumentIds
                statusNotIn: [Deleted]
                clientIds: $clientIds
                startDate: $startDate
                endDate: $endDate
            }
            type: $type
        ) {
            _id
            clientId
            client {
                name
                instruments {
                    _id
                    externalAccountId
                    productType
                    name
                }
                externalAccounts {
                    _id
                    type
                    name
                }
            }
            uniqueTradeId
            brokerTradeId
            instrumentId
            type
            settlementAmount
            tradeTimestamp
            valueDate
            amount
            currency
            secondCurrency
            secondInstrumentId
            secondAmount
            initialMargin
            loan
        }
    }
`;

const GET_FX_VALUATIONS = gql`
    query fxValuations($date: GraphQLSwedenDate) {
        fxValuations(date: $date) {
            name
            price
        }
    }
`;

interface GridInput {
    reconciledExposureCollateral: ReconciledExposureCollateralType[];
    clientIds: string[];
    endDate: string;
    partiesById: Record<string, Partial<Party>>;
    collateralInstrumentIds: Record<string, Partial<Instrument>[]>;
    clientDashName?: string;
}

export const CollateralGrid = ({
    reconciledExposureCollateral,
    clientIds,
    endDate,
    partiesById,
    collateralInstrumentIds,
    clientDashName
}: GridInput): React.ReactElement => {
    const location = useLocation();
    const [fxValuationsByCurrency, setFxValuationsByCurrency] = useState({});
    const [thisMonthsMarginCalls, setThisMonthsMarginCalls] = useState([]);
    const [exposureCollateralGrouped, setExposureCollateralGrouped] = useState({});
    const [nettingSetsGroupedByExternalAccount, setNettingSetsGroupedByExternalAccount] = useState({});
    const [variationMarginSummary, setVariationMarginSummary] = useState([]);
    const [nettingSetsGroupedBaseCurrency, setNettingSetsGroupedBaseCurrency] = useState({});

    // Polling margin call transactions this month (needed for margin calls table)

    const [{ data: marginCallsData, error: errorMonthlyMarginCalls, fetching: loadingMonthlyMarginCalls }] = useQuery({
        query: GET_MINITRANSACTIONS,
        variables: {
            collateralInstrumentIds: collateralInstrumentIds[clientIds[0]],
            clientIds,
            type: [TransactionType.Insert, TransactionType.Transfer],
            startDate: endDate ? endDate.substring(0, 7) + "-01" : null,
            endDate: endDate ? endDate.substring(0, 7) + "-31" : null
        },
        pause: clientIds.length !== 1 || !endDate || !collateralInstrumentIds[clientIds[0]] || !collateralInstrumentIds[clientIds[0]].length
    });

    const [{ data: fxValuationsData, fetching: loadingFx, error: errorFx }] = useQuery({
        query: GET_FX_VALUATIONS,
        variables: { date: endDate }
    });

    useEffect(() => {
        const nettingSetsGroupedBaseCcy: Record<string, ReconciledExposureCollateralType[]> = {};

        if (fxValuationsData && fxValuationsData.fxValuations && !Object.keys(fxValuationsByCurrency).length) {
            const fxValuations = cloneDeep(fxValuationsData.fxValuations);
            for (const fxVal of fxValuationsData.fxValuations) {
                fxValuations.push({ name: fxVal.name.substring(0, 3) + fxVal.name.substring(0, 3), price: 1 });
            }
            fxValuations.push({ name: CurrencyEnum.SEK + CurrencyEnum.SEK, price: 1 });

            setFxValuationsByCurrency(keyBy(fxValuations, "name"));
        }

        if (Object.keys(fxValuationsByCurrency).length) {
            const reconciledExposureCollateralByClientId: Record<string, ReconciledExposureCollateralType[]> = {};
            const reconciledNettingSetByClientId: Record<string, ReconciledExposureCollateralType[]> = {};

            for (const reconciledItem of reconciledExposureCollateral) {
                if (reconciledItem.reconcileType === ReconcileTypeEnum.NettingSet) {
                    if (reconciledNettingSetByClientId[reconciledItem.clientId]) {
                        reconciledNettingSetByClientId[reconciledItem.clientId].push(reconciledItem);
                    } else {
                        reconciledNettingSetByClientId[reconciledItem.clientId] = [reconciledItem];
                    }
                } else if (reconciledItem.reconcileType === ReconcileTypeEnum.ExposureCollateral) {
                    if (reconciledExposureCollateralByClientId[reconciledItem.clientId]) {
                        reconciledExposureCollateralByClientId[reconciledItem.clientId].push(reconciledItem);
                    } else {
                        reconciledExposureCollateralByClientId[reconciledItem.clientId] = [reconciledItem];
                    }
                } else if (reconciledItem.reconcileType === ReconcileTypeEnum.NettingSetBaseCurrency) {
                    if (nettingSetsGroupedBaseCcy[reconciledItem.clientId]) {
                        nettingSetsGroupedBaseCcy[reconciledItem.clientId].push(reconciledItem);
                    } else {
                        nettingSetsGroupedBaseCcy[reconciledItem.clientId] = [reconciledItem];
                    }
                }
            }

            if (stableStringify(nettingSetsGroupedBaseCurrency) !== stableStringify(nettingSetsGroupedBaseCcy)) {
                setNettingSetsGroupedBaseCurrency(nettingSetsGroupedBaseCcy);
            }

            if (clientIds.length === 1) {
                const clientGridData = groupClientGridData(
                    groupBy(reconciledExposureCollateralByClientId[clientIds[0]], (t) => t.externalAccount.name),
                    reconciledNettingSetByClientId[clientIds[0]],
                    endDate,
                    fxValuationsByCurrency
                );
                if (stableStringify(exposureCollateralGrouped) !== stableStringify(clientGridData.exposureCollateralGrouped)) {
                    setExposureCollateralGrouped(clientGridData.exposureCollateralGrouped);
                }
                if (
                    stableStringify(nettingSetsGroupedByExternalAccount) !==
                    stableStringify(clientGridData.nettingSetsGroupedByExternalAccount)
                ) {
                    setNettingSetsGroupedByExternalAccount(clientGridData.nettingSetsGroupedByExternalAccount);
                }
                if (stableStringify(variationMarginSummary) !== stableStringify(clientGridData.variationMarginSummary)) {
                    setVariationMarginSummary(clientGridData.variationMarginSummary);
                }
            }

            const insertTransferTransactions: Record<string, MiniTransaction[] | any>[] = [];

            if (marginCallsData && marginCallsData.miniTransactions && clientIds.length === 1) {
                marginCallsData.miniTransactions.forEach((trans) => {
                    const transaction = cloneDeep(trans);
                    transaction.hash = "#";
                    const tradeDate = transaction.tradeTimestamp.toString().substring(0, 10);
                    transaction.tradeDate = tradeDate;

                    transaction.client.instruments.forEach((instrument) => {
                        if (transaction.instrumentId.toString() === instrument._id.toString()) {
                            transaction.externalAccountId = instrument.externalAccountId;
                            transaction.instrumentProductType = instrument.productType;
                            transaction.instrumentName = instrument.name;
                            transaction.instrumentId = instrument._id;
                            transaction.externalAccountType = null;
                            transaction.client.externalAccounts.forEach((externalAccount) => {
                                if (transaction.externalAccountId.toString() === externalAccount._id.toString()) {
                                    transaction.externalAccountType = externalAccount.type;
                                    transaction.externalAccountName = externalAccount.name;
                                }
                            });
                        } else if (
                            transaction.secondInstrumentId &&
                            transaction.secondInstrumentId.toString() === instrument._id.toString()
                        ) {
                            transaction.secondExternalAccountId = instrument.externalAccountId;
                            transaction.secondInstrumentProductType = instrument.productType;
                            transaction.secondInstrumentName = instrument.name;
                            transaction.secondInstrumentId = instrument._id;
                            transaction.secondExternalAccountType = null;
                            transaction.client.externalAccounts.forEach((externalAccount) => {
                                if (transaction.secondExternalAccountId.toString() === externalAccount._id.toString()) {
                                    transaction.secondExternalAccountType = externalAccount.type;
                                    transaction.secondExternalAccountName = externalAccount.name;
                                }
                            });
                        }
                    });

                    // Making sure every day variation margin transfers for clearingAccounts does not show
                    if (
                        transaction.type === TransactionType.Insert &&
                        transaction.externalAccountType &&
                        AgreementType[transaction.externalAccountType] &&
                        transaction.externalAccountType !== AgreementType.ClearingAccount
                    ) {
                        transaction.settlementAmount = -transaction.amount;
                        if (insertTransferTransactions.indexOf(transaction) === -1) {
                            insertTransferTransactions.push(transaction);
                        }
                    } else if (
                        transaction.type === TransactionType.Transfer &&
                        transaction.externalAccountType &&
                        AgreementType[transaction.externalAccountType] &&
                        transaction.externalAccountType !== AgreementType.ClearingAccount
                    ) {
                        transaction.settlementAmount = transaction.amount;
                        if (insertTransferTransactions.indexOf(transaction) === -1) {
                            insertTransferTransactions.push(transaction);
                        }
                    } else if (
                        transaction.type === TransactionType.Transfer &&
                        transaction.secondExternalAccountType &&
                        AgreementType[transaction.secondExternalAccountType] &&
                        transaction.secondExternalAccountType !== AgreementType.ClearingAccount
                    ) {
                        transaction.instrumentName = transaction.secondInstrumentName;
                        transaction.externalAccountId = transaction.secondExternalAccountId;
                        transaction.settlementAmount = transaction.secondAmount;
                        transaction.currency = transaction.secondCurrency;
                        if (insertTransferTransactions.indexOf(transaction) === -1) {
                            insertTransferTransactions.push(transaction);
                        }
                    }
                });
            }

            if (
                clientIds.length === 1 &&
                (!insertTransferTransactions.length ||
                    (insertTransferTransactions.length && clientIds[0] === insertTransferTransactions[0].clientId)) &&
                (thisMonthsMarginCalls.length !== insertTransferTransactions.length ||
                    (thisMonthsMarginCalls.length && clientIds[0] !== thisMonthsMarginCalls[0].clientId))
            ) {
                setThisMonthsMarginCalls(insertTransferTransactions);
            }
        }
    }, [
        clientIds,
        endDate,
        exposureCollateralGrouped,
        fxValuationsByCurrency,
        fxValuationsData,
        marginCallsData,
        nettingSetsGroupedBaseCurrency,
        nettingSetsGroupedByExternalAccount,
        reconciledExposureCollateral,
        thisMonthsMarginCalls,
        variationMarginSummary
    ]);

    if (loadingFx || loadingMonthlyMarginCalls) return <div>Loading valuations and transactions</div>;
    if (errorMonthlyMarginCalls) return <div>ErrorMonthlyMarginCalls ${errorMonthlyMarginCalls.message}`</div>;
    if (errorFx) return <div>ErrorFxValuations ${errorFx.message}`</div>;

    return (
        <Fragment>
            {nettingSetsGroupedBaseCurrency && Object.keys(nettingSetsGroupedBaseCurrency).length ? (
                <div className="row">
                    <div className="col">
                        {clientIds.map((clientId) => (
                            <div className="mt-3" key={clientId}>
                                {clientIds.length === 1 &&
                                nettingSetsGroupedBaseCurrency[clientId] &&
                                nettingSetsGroupedBaseCurrency[clientId].length > 0 ? (
                                    <h1>
                                        {partiesById[clientId] ? (
                                            <Link to={"/parties/" + partiesById[clientId]._id}>{partiesById[clientId].name}</Link>
                                        ) : (
                                            ""
                                        )}
                                    </h1>
                                ) : null}
                                {nettingSetsGroupedBaseCurrency[clientId] && nettingSetsGroupedBaseCurrency[clientId].length > 0 ? (
                                    <Grid
                                        header={
                                            clientIds.length === 1 &&
                                            nettingSetsGroupedBaseCurrency[clientId] &&
                                            nettingSetsGroupedBaseCurrency[clientId].length > 0 ? (
                                                "Summary base ccy"
                                            ) : (
                                                <Link
                                                    to={
                                                        partiesById[clientId] && endDate
                                                            ? "/reconcile/collateral/" +
                                                              partiesById[clientId].name.replace(/ /g, "-") +
                                                              "?endDate=" +
                                                              endDate
                                                            : partiesById[clientId]
                                                              ? "/reconcile/collateral/" + partiesById[clientId].name.replace(/ /g, "-")
                                                              : "/reconcile/collateral/Summary"
                                                    }
                                                >
                                                    {partiesById[clientId] ? partiesById[clientId].name + " - summary" : "Summary base ccy"}
                                                </Link>
                                            )
                                        }
                                        data={nettingSetsGroupedBaseCurrency[clientId]}
                                        sortable
                                        tableClassName="table-xs"
                                    >
                                        <Column
                                            field="externalAccountId"
                                            title="Netting set"
                                            className="grid-column-sticky"
                                            format={(value, item) => {
                                                return item ? (
                                                    <Link to={"/parties/" + clientId + "/externalAccounts/" + value}>
                                                        {item.externalAccount.name}
                                                    </Link>
                                                ) : (
                                                    item.externalAccount.name
                                                );
                                            }}
                                        />
                                        <Column field="baseCurrency" title="Base ccy" />

                                        <Column
                                            field="exposure"
                                            title={
                                                <TooltipLabel
                                                    text={"Pos exposure"}
                                                    content={
                                                        "Position exposure. Total value on derivatives in this netting set. Positive sign means valuation on derivatives is positive from our perspective."
                                                    }
                                                />
                                            }
                                            format={(value) => {
                                                return numberFormat(Number(value), "# ##0");
                                            }}
                                            className={"right nowrap"}
                                        />

                                        <Column
                                            field="variationMarginPosted"
                                            title={
                                                <TooltipLabel
                                                    text={"VM posted"}
                                                    content={
                                                        "Total value on assets and cash posted as variation margin. Positive sign means we posted."
                                                    }
                                                />
                                            }
                                            format={(value) => {
                                                return numberFormat(-Number(value), "# ##0");
                                            }}
                                            className={"right nowrap"}
                                        />
                                        <Column
                                            field="netExposure"
                                            title={
                                                <TooltipLabel
                                                    text={"VM net"}
                                                    content={
                                                        'Variation margin net exposure. ("Exposure" + "VM Posted"). Same as VM Call but does not consider thresholds and roundings. Positive sign means we have exposure on counterparty.'
                                                    }
                                                />
                                            }
                                            format={(value) => {
                                                return numberFormat(Number(value), "# ##0");
                                            }}
                                            className={"right font-weight-bold"}
                                        />

                                        <Column
                                            field="marginCall"
                                            title={
                                                <TooltipLabel
                                                    text={"VM call"}
                                                    content={
                                                        "Variation margin call. If value is negative we can expect a margin call from the counterparty. Value considers thresholds and roundings from agreement."
                                                    }
                                                />
                                            }
                                            format={(value, item) => {
                                                if (
                                                    (item &&
                                                        item.marginCall !== 0 &&
                                                        item.agreementType !== AgreementType.OmnibusClearingAccount &&
                                                        item.agreementType !== AgreementType.ClearingAccount) ||
                                                    (item &&
                                                        item.agreementType === AgreementType.OmnibusClearingAccount &&
                                                        item.marginCall > 0)
                                                ) {
                                                    if (Math.abs(item.counterpartyMarginCall + item.marginCall) > 1000000) {
                                                        return (
                                                            <TooltipLabel
                                                                text={numberFormat(Number(value), "# ##0")}
                                                                content="Margin call, difference between our and counterparty's call is > 1 000 000, please check."
                                                            />
                                                        );
                                                    } else {
                                                        return (
                                                            <TooltipLabel
                                                                text={numberFormat(Number(value), "# ##0")}
                                                                content="Margin call, we agree on the amount with counterparty."
                                                            />
                                                        );
                                                    }
                                                } else {
                                                    return numberFormat(Number(value), "# ##0");
                                                }
                                            }}
                                            className={(d) => {
                                                if (
                                                    (d &&
                                                        d.marginCall !== 0 &&
                                                        d.agreementType !== AgreementType.OmnibusClearingAccount &&
                                                        d.agreementType !== AgreementType.ClearingAccount) ||
                                                    (d && d.agreementType === AgreementType.OmnibusClearingAccount && d.marginCall > 0)
                                                ) {
                                                    if (Math.abs(d.counterpartyMarginCall + d.marginCall) > 1000000) {
                                                        return "right nowrap btn-sm btn-danger text-white";
                                                    } else {
                                                        return "right nowrap btn-sm btn-warning";
                                                    }
                                                } else {
                                                    return "right nowrap";
                                                }
                                            }}
                                        />
                                        <Column
                                            field="initialMarginRequirement"
                                            title={
                                                <TooltipLabel
                                                    text={"IM requirement"}
                                                    content="Initial margin requirement based on clearing broker report. Positive sign means required for us to post IM."
                                                />
                                            }
                                            format={(value, item) => {
                                                if (item && item.missingReport) {
                                                    return "Missing report";
                                                } else {
                                                    return numberFormat(Number(value), "# ##0");
                                                }
                                            }}
                                            className={(d) => {
                                                if (d && d.missingReport) {
                                                    return "right nowrap btn-sm btn-info";
                                                } else {
                                                    return "right";
                                                }
                                            }}
                                        />
                                        <Column
                                            field="counterpartyInitialMarginPosted"
                                            title={
                                                <TooltipLabel
                                                    text={"IM posted"}
                                                    content={
                                                        "Initial margin posted. Value includes hair cuts. Positive sign means we posted."
                                                    }
                                                />
                                            }
                                            format={(value) => {
                                                return numberFormat(-Number(value), "# ##0");
                                            }}
                                            className={"right nowrap"}
                                        />
                                        <Column
                                            field="counterpartyInitialMarginNetRequirement"
                                            title={
                                                <TooltipLabel
                                                    text={"IM net"}
                                                    content={
                                                        'Initial margin net exposure. ("IM posted" - "IM requirement"). If value is negative we can expect a margin call from the counterparty.'
                                                    }
                                                />
                                            }
                                            format={(value, item) => {
                                                if (item && item.counterpartyInitialMarginNetRequirement > 0) {
                                                    if (
                                                        Math.abs(
                                                            item.counterpartyInitialMarginNetRequirement + item.initialMarginNetRequirement
                                                        ) > 1000000
                                                    ) {
                                                        return (
                                                            <TooltipLabel
                                                                text={numberFormat(-Number(value), "# ##0")}
                                                                content="Margin call, difference between our and counterparty's call is > 1 000 000, please check."
                                                            />
                                                        );
                                                    } else {
                                                        return (
                                                            <TooltipLabel
                                                                text={numberFormat(-Number(value), "# ##0")}
                                                                content="Margin call, we agree on the amount with counterparty."
                                                            />
                                                        );
                                                    }
                                                } else {
                                                    return numberFormat(-Number(value), "# ##0");
                                                }
                                            }}
                                            className={(d) => {
                                                if (d && d.counterpartyInitialMarginNetRequirement > 0) {
                                                    if (
                                                        Math.abs(
                                                            d.counterpartyInitialMarginNetRequirement + d.initialMarginNetRequirement
                                                        ) > 1000000
                                                    ) {
                                                        return "right nowrap btn-sm btn-danger text-white";
                                                    } else {
                                                        return "right nowrap btn-sm btn-warning";
                                                    }
                                                } else {
                                                    return "font-weight-bold";
                                                }
                                            }}
                                        />
                                        <Column
                                            field="interestRateYieldDeltaSum"
                                            title={
                                                <TooltipLabel
                                                    text={"IR delta total"}
                                                    content="Interest rate delta. Value change for 1bp change down in rates. Positive value in our favour. Total value in base currency."
                                                />
                                            }
                                            format={numberFormatFun("# ##0")}
                                            className={"right nowrap"}
                                        />
                                        <Column
                                            field="creditYieldDeltaSum"
                                            title={
                                                <TooltipLabel
                                                    text={"CR delta total"}
                                                    content="Credit delta. Value change for 1bp change down in credit spreads. Positive value in our favour. Total value in base currency."
                                                />
                                            }
                                            format={numberFormatFun("# ##0")}
                                            className={"right nowrap"}
                                        />
                                    </Grid>
                                ) : null}
                            </div>
                        ))}
                    </div>
                </div>
            ) : null}
            {clientIds.length === 1 && variationMarginSummary && variationMarginSummary.length > 0 ? (
                <div>
                    <Grid header={"Variation margin summary per ccy"} data={variationMarginSummary} sortable tableClassName="table-xs">
                        <Column
                            field="externalAccountId"
                            title="Netting set"
                            className="grid-column-sticky"
                            format={(value, item) => {
                                return item ? (
                                    <Link to={"/parties/" + clientIds[0] + "/externalAccounts/" + value}>{item.externalAccount.name}</Link>
                                ) : (
                                    item.externalAccount.name
                                );
                            }}
                        />
                        <Column field="currency" title="Ccy" />
                        <Column
                            field="exposure"
                            title={
                                <TooltipLabel
                                    text={"Pos exposure"}
                                    content={
                                        "Position exposure. Total value on derivatives in this netting set. Positive sign means valuation on derivatives is positive from our perspective."
                                    }
                                />
                            }
                            format={(value) => {
                                return numberFormat(Number(value), "# ##0");
                            }}
                            className={"right nowrap"}
                        />

                        <Column
                            field="variationMarginPosted"
                            title={
                                <TooltipLabel
                                    text={"VM posted"}
                                    content={"Total value on assets and cash posted as variation margin. Positive sign means we posted."}
                                />
                            }
                            format={(value) => {
                                return numberFormat(-Number(value), "# ##0");
                            }}
                            className={"right nowrap"}
                        />
                        <Column
                            field="netExposure"
                            title={
                                <TooltipLabel
                                    text={"VM net"}
                                    content={
                                        'Variation margin net exposure. ("Exposure" + "VM Posted"). Same as VM Call but does not consider thresholds and roundings. Positive sign means we have exposure on counterparty.'
                                    }
                                />
                            }
                            format={(value) => {
                                return numberFormat(Number(value), "# ##0");
                            }}
                            className={"right font-weight-bold"}
                        />
                        <Column
                            field="interestRateYieldDeltaSum"
                            title={
                                <TooltipLabel
                                    text={"IR delta local ccy"}
                                    content="Interest rate delta. Value change for 1bp change down in rates. Positive value in our favour. Value in local currency."
                                />
                            }
                            format={numberFormatFun("# ##0")}
                            className={"right nowrap"}
                        />
                        <Column
                            field="creditYieldDeltaSum"
                            title={
                                <TooltipLabel
                                    text={"CR delta local ccy"}
                                    content="Credit delta. Value change for 1bp change down in credit spreads. Positive value in our favour. Value in local currency."
                                />
                            }
                            format={numberFormatFun("# ##0")}
                            className={"right nowrap"}
                        />
                    </Grid>
                </div>
            ) : null}

            {clientIds.length === 1 ? (
                <div>
                    {Object.keys(nettingSetsGroupedByExternalAccount).map((externalAccountName) => (
                        <div key={externalAccountName}>
                            <br />
                            <h3>
                                {nettingSetsGroupedByExternalAccount[externalAccountName].variationMargin.length ? (
                                    <Link
                                        to={
                                            "/parties/" +
                                            clientIds[0] +
                                            "/externalAccounts/" +
                                            nettingSetsGroupedByExternalAccount[externalAccountName].variationMargin[0].externalAccountId
                                        }
                                    >
                                        {<u>{externalAccountName}</u>}
                                    </Link>
                                ) : nettingSetsGroupedByExternalAccount[externalAccountName].initialMargin.length ? (
                                    <Link
                                        to={
                                            "/parties/" +
                                            clientIds[0] +
                                            "/externalAccounts/" +
                                            nettingSetsGroupedByExternalAccount[externalAccountName].initialMargin[0].externalAccountId
                                        }
                                    >
                                        {<u>{externalAccountName}</u>}
                                    </Link>
                                ) : (
                                    <u>{externalAccountName}</u>
                                )}
                            </h3>
                            {exposureCollateralGrouped[externalAccountName] &&
                            exposureCollateralGrouped[externalAccountName].exposure.length ? (
                                <Grid
                                    header={"Position and valuation reconciliation"}
                                    data={exposureCollateralGrouped[externalAccountName].exposure}
                                    sortable
                                    tableClassName="table-xs"
                                >
                                    <Column
                                        field="name"
                                        className="grid-column-sticky nowrap maxwidth150px left"
                                        format={(__, item) => {
                                            return item && item.instrumentId && item.instrument.name ? (
                                                <Link to={"/instruments/" + item.instrumentId}>{item.instrument.name}</Link>
                                            ) : (
                                                item.name
                                            );
                                        }}
                                    />
                                    <Column
                                        field="name"
                                        title="Isin"
                                        format={(_, item) => {
                                            return item && item.instrument && item.instrument.isin ? (
                                                <Link to={"/instruments/" + item.instrumentId}>{item.instrument.isin}</Link>
                                            ) : (
                                                ""
                                            );
                                        }}
                                    />
                                    <Column field="currency" title="Ccy" />
                                    <Column field="quantity" title="Our qty" format={numberFormatFun("# ##0")} className={"right"} />
                                    <Column
                                        field="counterpartyQuantity"
                                        title="Cpty qty"
                                        format={(value, item) => {
                                            if (item && item.missingReport && item.name !== "Sum" && item.name !== "Sum base ccy") {
                                                return "Missing report";
                                            } else {
                                                return numberFormat(Number(value), "# ##0");
                                            }
                                        }}
                                        className={(item) => {
                                            if (item && item.missingReport && item.name !== "Sum" && item.name !== "Sum base ccy") {
                                                return "right nowrap btn-sm btn-info";
                                            } else {
                                                return "right";
                                            }
                                        }}
                                    />
                                    <Column
                                        field="quantityDifference"
                                        title={<TooltipLabel text={"Difference"} content="Our qty - Cpty qty" />}
                                        format={(value, item) => {
                                            if (item && item.missingReport && item.name !== "Sum" && item.name !== "Sum base ccy") {
                                                return "-";
                                            } else if (
                                                item &&
                                                item.name !== "Sum" &&
                                                item.name !== "Sum base ccy" &&
                                                round(item.quantityDifference) !== 0
                                            ) {
                                                return (
                                                    <TooltipLabel
                                                        text={numberFormat(Number(value), "# ##0")}
                                                        content="Difference tolerance is 0, please check."
                                                    />
                                                );
                                            } else {
                                                return numberFormat(Number(value), "# ##0");
                                            }
                                        }}
                                        className={(d) => {
                                            if (
                                                d &&
                                                d.name !== "Sum" &&
                                                d.name !== "Sum base ccy" &&
                                                round(d.quantityDifference) !== 0 &&
                                                !d.missingReport
                                            ) {
                                                return "right nowrap btn-sm btn-danger text-white";
                                            } else {
                                                return "right";
                                            }
                                        }}
                                    />
                                    <Column field="exposure" format={numberFormatFun("# ##0")} className={"right"} title="Our valuation" />
                                    <Column
                                        field="counterpartyExposure"
                                        title="Cpty valuation"
                                        format={(value, item) => {
                                            if (item && item.missingReport) {
                                                return "Missing report";
                                            } else if (item && !item.counterpartyExposure) {
                                                return (
                                                    <TooltipLabel
                                                        text={numberFormat(-Number(value), "# ##0")}
                                                        content="Counterparty exposure is missing, please check."
                                                    />
                                                );
                                            } else {
                                                return numberFormat(-Number(value), "# ##0");
                                            }
                                        }}
                                        className={(d) => {
                                            if (d && d.missingReport) {
                                                return "right nowrap btn-sm btn-info";
                                            } else if (d && !d.counterpartyExposure) {
                                                return "right btn-sm btn-danger text-white";
                                            } else {
                                                return "right";
                                            }
                                        }}
                                    />
                                    <Column
                                        field="exposureDifference"
                                        title={<TooltipLabel text={"Difference"} content="Our valuation - Cpty valuation" />}
                                        format={(value, item) => {
                                            if (item && item.missingReport) {
                                                return "-";
                                            } else {
                                                return numberFormat(Number(value), "# ##0");
                                            }
                                        }}
                                        className={
                                            "right"
                                            /*(d) => {
                                            if ((d && Math.abs(d.exposureDifference) > 1000000) || (d && !d.exposureDifference && d.exposureDifference !== 0)) {
                                             return "right btn-sm btn-danger text-white";
                                            } else {
                                            return "right";
                                            }
                                        }*/
                                        }
                                    />
                                    <Column
                                        field="valuationDate"
                                        title="Our valuation date"
                                        format={(value, item) => {
                                            if (
                                                item &&
                                                item.valuationDate !== endDate &&
                                                item.name !== "Sum" &&
                                                item.name !== "Sum base ccy"
                                            ) {
                                                return (
                                                    <TooltipLabel
                                                        text={value}
                                                        content="Valuation date does not match with chosen end date, please check."
                                                    />
                                                );
                                            } else {
                                                return value;
                                            }
                                        }}
                                        className={(d) => {
                                            if (d && d.valuationDate !== endDate && d.name !== "Sum" && d.name !== "Sum base ccy") {
                                                return "right btn-sm btn-danger text-white";
                                            } else {
                                                return "right";
                                            }
                                        }}
                                    />
                                    <Column
                                        field="counterpartyValuationDate"
                                        title="Cpty valuation date"
                                        format={(value, item) => {
                                            if (item && item.missingReport && item.name !== "Sum" && item.name !== "Sum base ccy") {
                                                return "Missing report";
                                            } else if (
                                                item &&
                                                item.counterpartyValuationDate !== endDate &&
                                                item.name !== "Sum" &&
                                                item.name !== "Sum base ccy"
                                            ) {
                                                return (
                                                    <TooltipLabel
                                                        text={value}
                                                        content="Cpty valuation date does not match with chosen end date, please check."
                                                    />
                                                );
                                            } else {
                                                return value;
                                            }
                                        }}
                                        className={(d) => {
                                            if (d && d.missingReport && d.name !== "Sum" && d.name !== "Sum base ccy") {
                                                return "right nowrap btn-sm btn-info";
                                            }
                                            if (
                                                d &&
                                                d.counterpartyValuationDate !== endDate &&
                                                d.name !== "Sum" &&
                                                d.name !== "Sum base ccy"
                                            ) {
                                                return "right btn-sm btn-danger text-white";
                                            } else {
                                                return "right";
                                            }
                                        }}
                                    />
                                </Grid>
                            ) : null}
                            {exposureCollateralGrouped[externalAccountName] &&
                            exposureCollateralGrouped[externalAccountName].variationMarginCollateralSecurities.length ? (
                                <SecuritiesCollateralGrid
                                    header={"Variation margin collateral securities"}
                                    data={exposureCollateralGrouped[externalAccountName].variationMarginCollateralSecurities}
                                    endDate={endDate}
                                />
                            ) : null}
                            {nettingSetsGroupedByExternalAccount[externalAccountName] &&
                            nettingSetsGroupedByExternalAccount[externalAccountName].variationMargin.length ? (
                                <NetExposureGrid
                                    header={"Variation margin reconciliation"}
                                    data={nettingSetsGroupedByExternalAccount[externalAccountName].variationMargin}
                                />
                            ) : null}
                            {exposureCollateralGrouped[externalAccountName] &&
                            exposureCollateralGrouped[externalAccountName].initialMarginCollateralSecurities.length ? (
                                <SecuritiesCollateralGrid
                                    header={"Initial margin collateral securities quantity and valuation reconciliation"}
                                    data={exposureCollateralGrouped[externalAccountName].initialMarginCollateralSecurities}
                                    endDate={endDate}
                                />
                            ) : null}
                            {nettingSetsGroupedByExternalAccount[externalAccountName] &&
                            nettingSetsGroupedByExternalAccount[externalAccountName].initialMargin.length ? (
                                <NetExposureGrid
                                    header={"Initial margin reconciliation"}
                                    data={nettingSetsGroupedByExternalAccount[externalAccountName].initialMargin}
                                />
                            ) : null}
                            <br />
                        </div>
                    ))}
                </div>
            ) : null}
            {clientIds.length === 1 ? (
                <div className="row">
                    <div className="col">
                        <Grid
                            header={"Margin Calls this month"}
                            data={sortBy(thisMonthsMarginCalls, "valueDate").reverse()}
                            sortable
                            tableClassName="table-xs"
                        >
                            <Column
                                field="hash"
                                title="Id"
                                format={(value, item) => {
                                    return miniTransactionTypes.find((d) => d === item.type) ? (
                                        <Link to={"/reconcile/collateral/" + clientDashName + "/" + item._id + location.search}>
                                            {value}
                                        </Link>
                                    ) : (
                                        value
                                    );
                                }}
                            />
                            <Column field="instrumentName" title="Instrument name" className="grid-column-sticky" />
                            <Column field="currency" title="Ccy" />
                            <Column field="settlementAmount" title="Amount" format={numberFormatFun("# ##0")} className={"right"} />
                            <Column field="tradeDate" title="Trade date" className={"right"} />
                            <Column field="valueDate" title="Value date" className={"right"} />
                        </Grid>
                    </div>
                </div>
            ) : null}
        </Fragment>
    );
};
