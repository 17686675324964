import React, { useMemo } from "react";
import { Link } from "react-router-dom";

import { useQuery } from "urql";
import { NoteReference, NoteAssetEnum, NoteTag, NoteFilterInput, CollectionNameEnum } from "../../types.generated";

import { Button } from "react-bootstrap";
import { ReactTable } from "../../components/react-table/ReactTable";
import { SelectColumnFilter, SelectColumnFilterArrayOfIds, SelectColumnFilterType } from "../../components/react-table/ReactTableFilters";

import { GET_NOTES, GET_OPTIONS } from "./queries";
import { keyBy, orderBy, sortBy } from "lodash";

export interface NotesPageProps {
    esgOnly: boolean;
    routeToNew: string;
    routeToNewText: string;
}

export const NotesPage = ({ esgOnly, routeToNew, routeToNewText }: NotesPageProps): React.ReactElement => {
    const filter: NoteFilterInput = esgOnly ? { assetIn: [NoteAssetEnum.Esg] } : null;
    const [{ fetching, error, data }] = useQuery({ query: GET_NOTES, variables: { filter }, requestPolicy: "cache-and-network" });

    const [{ data: dataOptions, fetching: fetchingOptions, error: errorOptions }] = useQuery({
        query: GET_OPTIONS,
        requestPolicy: "cache-and-network"
    });

    const { refOptions: _, docNameById } = useMemo(() => {
        if (dataOptions) {
            const newRefOptions: Record<string, { _id: string; name: string }[]> = {};

            const parties = sortBy(dataOptions.parties, "name");
            const instruments = sortBy(dataOptions.instruments, "name");
            const issuerProgramOptionsExtended = dataOptions.issuerprograms.map((issuerProgram) => {
                issuerProgram.shortName = issuerProgram.shortName ? issuerProgram.shortName : issuerProgram.name;
                issuerProgram.name = issuerProgram.issuer
                    ? issuerProgram.issuer.name + " " + issuerProgram.shortName
                    : issuerProgram.shortName;
                return issuerProgram;
            });
            const issuerprograms = sortBy(issuerProgramOptionsExtended, "name");
            const screens = sortBy(dataOptions.screens, "name");

            parties.push({ _id: "000000000000000000000000", name: "None" });
            instruments.push({ _id: "000000000000000000000000", name: "None" });
            issuerprograms.push({ _id: "000000000000000000000000", name: "None" });
            screens.push({ _id: "000000000000000000000000", name: "None" });

            newRefOptions[CollectionNameEnum.Instrument] = instruments;
            newRefOptions[CollectionNameEnum.IssuerProgram] = issuerprograms;
            newRefOptions[CollectionNameEnum.Party] = parties;
            newRefOptions[CollectionNameEnum.Screen] = screens;
            newRefOptions[CollectionNameEnum.None] = [{ _id: "000000000000000000000000", name: "None" }];

            let docNameById: Record<string, { _id: string; name: string }> = {};
            for (const collection of Object.keys(newRefOptions)) {
                docNameById = { ...docNameById, ...keyBy(newRefOptions[collection], "_id") };
            }

            return { refOptions: newRefOptions, docNameById: docNameById };
        }
        return { refOptions: {}, docNameById: {} };
    }, [dataOptions]);

    const { table, updateUsers } = useMemo(() => {
        const updateUsers: Record<string, { _id: string; name: string }> = {};
        const table: any[] = [];
        if (data && data.notes) {
            data.notes.forEach((note) => {
                if (!updateUsers[note.updateUserInfo._id]) {
                    updateUsers[note.updateUserInfo._id] = note.updateUserInfo;
                }

                const row = { ...note };

                const collection = {};
                const documentId = {};
                const documentName = {};

                note.refs.forEach((ref: NoteReference) => {
                    if (ref.collection) {
                        collection[ref.collection] = ref.collection;
                    }

                    if (ref.documentId) {
                        documentId[ref.documentId] = ref.documentId;
                        const d = docNameById[ref.documentId];
                        documentName[d ? d.name : ""] = d ? d.name : "";
                    }
                });
                row["collection"] = Object.keys(collection).join(", ");
                row["documentId"] = Object.keys(documentId).join(", ");
                row["documentName"] = Object.keys(documentName).join(", ");

                if (esgOnly) {
                    row["Date"] = "";
                    row["EsgArea"] = "";
                    row["EsgActivity"] = "";

                    note.tags.forEach((tag: NoteTag) => {
                        if (tag.type === "Date") {
                            row["Date"] = tag.value;
                        }
                        if (tag.type === "EsgArea") {
                            row["EsgArea"] = tag.value;
                        }
                        if (tag.type === "EsgActivity") {
                            row["EsgActivity"] = tag.value;
                        }
                    });
                } else {
                    const date = {};
                    const type = {};
                    const value = {};

                    note.tags.forEach((tag: NoteTag) => {
                        if (tag.type === "Date") {
                            date[tag.value] = tag.value;
                        }
                        if (tag.type) {
                            type[tag.type] = tag.type;
                        }
                        if (tag.value && tag.type !== "Date") {
                            value[tag.value] = tag.value;
                        }
                    });
                    row["date"] = Object.keys(date).join(", ");
                    row["type"] = Object.keys(type).join(", ");
                    row["value"] = Object.keys(value).join(", ");
                }

                table.push(row);
            });
        }
        let sortByString = "date";

        if (esgOnly) {
            sortByString = "Date";
        }

        const sortedTable = orderBy(table, sortByString, "desc");
        return { table: sortedTable, updateUsers };
    }, [data, docNameById, esgOnly]);

    const columns = useMemo(() => {
        const clientsById: Record<string, { _id: string; name: string }> = {};

        if (data) {
            data.notes.forEach((note) => {
                note.clients.forEach((client) => {
                    clientsById[client._id] = client;
                });
            });
        }

        const columns = [];

        columns.push({
            header: "Id",
            accessorKey: "_id",
            size: 15,
            cell: (cellProps) => {
                const { row } = cellProps;
                if (row.original.asset === NoteAssetEnum.Esg) {
                    return <Link to={`/esgnotes/${row.original._id}`}>#</Link>;
                } else {
                    return <Link to={`/notes/${row.original._id}`}>#</Link>;
                }
            }
        });

        columns.push({
            header: "Clients",
            accessorKey: "clientIds",
            cell: (cellProps) => {
                const value = cellProps.getValue();
                if (value) {
                    return (
                        <div className="col">
                            {value.map((id) => {
                                const client = clientsById[id];
                                if (client) {
                                    return (
                                        <Link className="row" key={id} to={`/parties/${id}`}>
                                            {clientsById[id].name}
                                        </Link>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </div>
                    );
                } else {
                    return null;
                }
            },
            filter: SelectColumnFilterArrayOfIds(Object.values(clientsById)),
            size: 100
        });

        columns.push({
            header: "Asset",
            accessorKey: "asset",
            size: 30,
            filter: SelectColumnFilterType(NoteAssetEnum)
        });

        if (esgOnly) {
            columns.push({
                header: "Date",
                accessorKey: "Date",
                size: 40
            });

            columns.push({
                header: "Esg area",
                accessorKey: "EsgArea",
                size: 60,
                filter: SelectColumnFilter
            });

            columns.push({
                header: "Esg activity",
                accessorKey: "EsgActivity",
                size: 60,
                filter: SelectColumnFilter
            });
        } else {
            columns.push({
                header: "Title",
                accessorKey: "title",
                size: 100,
                filter: SelectColumnFilter,
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/notes/${row.original._id}`}>{row.original.title}</Link>;
                }
            });
            columns.push({
                header: "Date",
                accessorKey: "date",
                size: 40
            });

            columns.push({
                header: "Type",
                accessorKey: "type",
                size: 60
            });

            columns.push({
                header: "Value",
                accessorKey: "value",
                size: 60
            });
        }
        columns.push({
            header: "Collection",
            accessorKey: "collection",
            size: 60,
            filter: SelectColumnFilter
        });

        columns.push({
            header: "Document name",
            accessorKey: "documentName",
            size: 80
        });
        columns.push({
            header: "Updated by",
            accessorKey: "updateUserId",
            size: 50,
            cell: (cellProps) => {
                const { row } = cellProps;
                if (row.original.updateUserInfo) {
                    return <div>{row.original.updateUserInfo.name}</div>;
                } else {
                    return null;
                }
            },
            filter: SelectColumnFilterArrayOfIds(Object.values(updateUsers))
        });

        return columns;
    }, [esgOnly, updateUsers]);

    if (fetching || fetchingOptions) return <p>Loading</p>;
    if (error) return <p>error notes: {JSON.stringify(error, null, 2)}</p>;
    if (errorOptions) return <p>error options: {JSON.stringify(errorOptions, null, 2)}</p>;

    if (!data || !("notes" in data)) {
        return <div></div>;
    }

    return (
        <div className="container">
            <Button
                type="button"
                className="btn-sm mb-3"
                onClick={() => {
                    const win = window.open(routeToNew, "_self");
                    win.focus();
                }}
            >
                {routeToNewText}
            </Button>
            <ReactTable columns={columns} data={table} />
        </div>
    );
};
