import React, { ReactElement } from "react";
import { gql, useMutation } from "urql";
import { AccountingRun } from "../../types.generated";

const CREATE_ACCOUNTING_RUN2 = gql`
    mutation CreateAccountingRun2($input: CreateAccountingRunInput2!) {
        createAccountingRun2(input: $input) {
            _id
            accountingPeriod
            number
            status
            type
            clientId
            endDate
            masterTAccountChartId
            clientTAccountChartId
            roundingDecimals
            createUserId
        }
    }
`;

interface ICreateAccountingRunButton2 {
    confirmedRun: AccountingRun;
    input: any;
    onCreated: () => void;
    onError: (msg: string) => void;
}

export const CreateAccountingRunButton2 = ({ input, confirmedRun, onCreated, onError }: ICreateAccountingRunButton2): ReactElement => {
    const [__stateCreate2, executeCreateAccountingRun2] = useMutation(CREATE_ACCOUNTING_RUN2);
    const disableCreateMode = confirmedRun && confirmedRun._id && !(input.endDate > confirmedRun.endDate);
    return (
        <>
            <div>
                {disableCreateMode ? (
                    <div>
                        <i style={{ color: "red" }}>
                            {"Confirmed accounting run exists for " + confirmedRun.endDate + ", choose a later date."}
                        </i>
                    </div>
                ) : null}
            </div>
            <div>
                <button
                    type="button"
                    onClick={async () => {
                        delete input._id;
                        await executeCreateAccountingRun2({ input })
                            .then((result) => {
                                if (result.error) {
                                    onError(result.error.toString());
                                } else {
                                    onCreated();
                                }
                            })
                            .catch((error) => {
                                onError(error.message);
                            });
                    }}
                    className="save btn btn-primary btn-sm"
                    disabled={disableCreateMode ? true : false}
                >
                    Create accounting run
                </button>
            </div>
        </>
    );
};
