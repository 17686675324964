import React, { Fragment, useMemo, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { gql, useQuery } from "urql";
import stableStringify from "json-stable-stringify";

import { DateHelper } from "../../../common/src";

import { usePrevious, useQueryArgs } from "../common/Utils";
import { DateForm } from "../common/dateForm";
import { SelectColumnFilter } from "../components/react-table/ReactTableFilters";
import { ReactTable } from "../components/react-table/ReactTable";

export const getCustodianPositions = gql`
    query getCustodianPositions($date: GraphQLDateString, $instrumentIdIn: [GraphQLObjectId!]) {
        custodianPositions(date: $date, instrumentIdIn: $instrumentIdIn, include: [Automatic, Manual]) {
            _id
            clientId
            client {
                name
            }
            custodianId
            custodian {
                name
            }
            date
            isin
            instrument {
                _id
                name
            }
            accountNumber
            cashAccountNumber
            currency
            price
            quantity
            updateUserId
            updateUserInfo {
                name
            }
        }
    }
`;

export const CustodianPositionsPage = (): React.ReactElement => {
    const yesterday = DateHelper.dateAddDays(new Date(), -1).toISOString().substring(0, 10);

    const { queryArgs } = useQueryArgs();
    const previousQueryArgs = usePrevious(queryArgs);

    const [variables, setVariables] = useState(null);

    const [{ fetching, error, data }] = useQuery({
        query: getCustodianPositions,
        requestPolicy: "cache-and-network",
        variables: variables,
        pause: !queryArgs
    });

    useEffect(() => {
        if (queryArgs && stableStringify(previousQueryArgs) !== stableStringify(queryArgs)) {
            const variables = {
                instrumentIdIn: queryArgs.instrumentIdIn,
                date: queryArgs.instrumentIdIn ? null : queryArgs.date ? queryArgs.date : yesterday
            };
            setVariables(variables);
        }
    }, [queryArgs, previousQueryArgs, yesterday]);

    const columns = useMemo(
        () => [
            {
                header: "Id",
                accessorKey: "_id",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/reconciliation/custodianpositions/${row.original._id}`}>{row.original._id}</Link>;
                },
                size: 30
            },
            {
                header: "Client",
                id: "client.name",
                accessorKey: "client.name",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/parties/${row.original.clientId}`}>{cellProps.getValue()}</Link>;
                },
                filter: SelectColumnFilter,
                size: 75
            },
            {
                header: "Custodian",
                id: "custodian.name",
                accessorKey: "custodian.name",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/parties/${row.original.custodianId}`}>{cellProps.getValue()}</Link>;
                },
                filter: SelectColumnFilter,
                size: 75
            },
            {
                header: "Instrument",
                id: "instrument.name",
                accessorKey: "instrument.name",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    if (row.original.instrument) {
                        return <Link to={`/instruments/${row.original.instrument._id}`}>{cellProps.getValue()}</Link>;
                    } else {
                        return <div>None</div>;
                    }
                }
            },
            {
                header: "Isin",
                accessorKey: "isin"
            },
            {
                header: "Date",
                accessorKey: "date",
                filter: SelectColumnFilter
            },
            {
                header: "Long name",
                accessorKey: "longName"
            },
            {
                header: "Account number",
                accessorKey: "accountNumber",
                filter: SelectColumnFilter
            },
            {
                header: "Currency",
                accessorKey: "currency",
                filter: SelectColumnFilter
            },
            {
                header: "Price",
                accessorKey: "price",
                filterFn: "startsWith"
            },
            {
                header: "Quantity",
                id: "quantity",
                accessorKey: "quantity",
                filterFn: "startsWith"
            },
            {
                header: "Updated by",
                id: "updateUserInfo.name",
                accessorKey: "updateUserInfo.name",
                cell: (cellProps) => {
                    return <span>{cellProps.getValue()}</span>;
                },
                filter: SelectColumnFilter
            }
        ],
        []
    );

    if (fetching) return <p>Loading</p>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;

    return (
        <Fragment>
            <div className="row">
                <div className="col d-flex">
                    <DateForm defaultDateString={yesterday} dateName={"date"}></DateForm>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Link className="float-end" to={"/reconciliation/custodianpositions/new"}>
                        New
                    </Link>
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <p>Number of custodian positions: {data.custodianPositions.length}</p>
                    <ReactTable
                        columns={columns}
                        data={data.custodianPositions}
                        defaultHiddenColumns={[]}
                        exportToXlsxFile="CustodianPositions.xlsx"
                    />
                </div>
            </div>
        </Fragment>
    );
};
