import { PermissionTypeEnum, PermissionAssetEnum, Me } from "../types.generated";

export const isUserSingleClient = (roles: any[]): boolean => {
    let result = true;
    for (let i = 0; i < roles.length; i++) {
        if (roles[i].clientIds.length === 0 || roles[i].clientIds.length > 1) {
            result = false;
            break;
        }
    }
    return result;
};

// this code is horrible from a performance perspective
export const userHaveAccessTo = (
    clientId: string,
    asset: PermissionAssetEnum,
    roles: any[],
    permissionType: PermissionTypeEnum = PermissionTypeEnum.Read
): boolean => {
    for (let i = 0; i < roles.length; i++) {
        const role = roles[i];
        if (role.assets.includes(PermissionAssetEnum.Admin.toString())) {
            return true; // Admin for any
        }
    }

    for (let i = 0; i < roles.length; i++) {
        const role = roles[i];
        if (permissionType !== PermissionTypeEnum.Read && role.permissionType !== permissionType.toString()) continue;
        if (clientId === "Any") {
            // [] means access to all assets, except Admin
            if (role.assets.length === 0 || role.assets.includes(asset.toString())) {
                return true;
            }
        } else {
            // [] means access to all clientIds
            if (role.clientIds.length === 0 || role.clientIds.includes(clientId)) {
                // [] means access to all assets, except Admin
                if (asset === PermissionAssetEnum.Admin) {
                    if (role.assets.includes(asset.toString())) {
                        return true;
                    } else {
                        return false;
                    }
                } else if (role.assets.length === 0 || role.assets.includes(asset.toString())) {
                    return true;
                }
            }
        }
    }

    return false;
};

export const userIsSuperAdmin = (me: Me): boolean => {
    for (let i = 0; i < me.roles.length; i++) {
        const role = me.roles[i];

        if (role.clientIds.length === 0 && role.assets.includes(PermissionAssetEnum.Admin)) {
            return true; // Admin for all clients
        }
    }
    return false;
};
