/* eslint-disable */
import type * as Types from '../../types.generated';

import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type GetMeQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMeQuery = { __typename?: 'Query', me?: { __typename?: 'Me', _id: string, personalNumber: string, roles?: Array<{ __typename?: 'Role', _id: string, assets: Array<Types.PermissionAssetEnum>, clientIds: Array<string>, permissionType: Types.PermissionTypeEnum, name: string }> | null } | null };

export type GetUsersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetUsersQuery = { __typename?: 'Query', users?: Array<{ __typename?: 'User', _id: string, clientIds: Array<string>, status: Types.UserStatusEnum, personalNumber: string, name: string, company: string, frontendRoleId: string, email?: string | null, description?: string | null, phoneNumber?: string | null, lastLoginTimestamp?: any | null, lastBankIdLoginTimestamp?: any | null, lastPasswordUpdate?: any | null, roleIds: Array<string>, clients: Array<{ __typename?: 'PartyInfo', _id: string, name: string }>, frontendRole: { __typename?: 'Role', _id: string, name: string }, roles: Array<{ __typename?: 'Role', _id: string, name: string, assets: Array<Types.PermissionAssetEnum>, permissionType: Types.PermissionTypeEnum, updateTimestamp?: any | null, roleType: Types.RoleTypeEnum, clientIds: Array<string>, clients: Array<{ __typename?: 'RoleClientInfo', name?: string | null }> }> } | null> | null, roles?: Array<{ __typename?: 'Role', _id: string, name: string } | null> | null };


export const GetMeDocument = gql`
    query getMe {
  me {
    _id
    personalNumber
    roles {
      _id
      assets
      clientIds
      permissionType
      name
    }
  }
}
    `;

export function useGetMeQuery(options?: Omit<Urql.UseQueryArgs<GetMeQueryVariables>, 'query'>) {
  return Urql.useQuery<GetMeQuery, GetMeQueryVariables>({ query: GetMeDocument, ...options });
};
export const GetUsersDocument = gql`
    query getUsers {
  users {
    _id
    clientIds
    clients {
      _id
      name
    }
    status
    personalNumber
    name
    company
    frontendRoleId
    frontendRole {
      _id
      name
    }
    email
    description
    phoneNumber
    lastLoginTimestamp
    lastBankIdLoginTimestamp
    lastPasswordUpdate
    roleIds
    roles {
      _id
      name
      assets
      permissionType
      updateTimestamp
      roleType
      clientIds
      clients {
        name
      }
    }
  }
  roles {
    _id
    name
  }
}
    `;

export function useGetUsersQuery(options?: Omit<Urql.UseQueryArgs<GetUsersQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUsersQuery, GetUsersQueryVariables>({ query: GetUsersDocument, ...options });
};