import React, { Fragment, useState, useEffect, ReactElement } from "react";
import { cloneDeep, sortBy } from "lodash";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { Alert, Button } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";

import { recursivelyRemoveKey } from "../../../../../common/src";

import { useAlertTimeOut } from "../../../common/Utils";
import { YesNoModal } from "../../../components/YesNoModal";
import {
    SubmitButton,
    TextField,
    SelectField,
    DateField,
    NullableSelectField,
    SearchListField,
    SearchMultipleSelectField
} from "../../../components/form";
import { usePrevious } from "../../../common/Utils";
import { useGetPartyQuery, useCreatePartyBaseMutation, useUpdatePartyBaseMutation } from "../PartyQuery.generated";
import {
    PartyBaseInput,
    PartyType,
    PartyStandardSettlementInstructionType,
    TimezoneEnum,
    CurrencyEnum,
    AccountingCompanyTypeEnum,
    PartyStatusEnum,
    IssuerTypeEnum,
    CollectionNameEnum,
    AccountingFrequencyEnum,
    SsiTradeType,
    CountryCodeEnum,
    CountryCodeEnumDescriptions
} from "../../../types.generated";
import { SYSTEM_PARTY_ID } from "../../../Constants";
import { formikUrqlErrorFormater } from "../../../common/formik-urql-error-helper";
import { defaultAlias } from "../../../components/alias";
import { MarkDownField } from "../../../components/form/MarkDownField";

interface PartyBaseTabPropsType {
    partyId: string;
}

const defaultPartyBase: PartyBaseInput = {
    _id: "000000000000000000000000",
    clientIds: [SYSTEM_PARTY_ID],
    name: "",
    longName: "",
    issuerType: IssuerTypeEnum.None,
    status: PartyStatusEnum.Confirmed,
    number: 0,
    inLedgerTradeDate: "1900-01-01",
    description: "",
    sustainalyticsId: "",
    types: [],
    standardSettlementInstructions: [],
    creditRatings: [],
    aliases: [],
    accountingCurrency: CurrencyEnum.SEK,
    accountingFrequency: AccountingFrequencyEnum.None,
    accountingCompanyType: AccountingCompanyTypeEnum.None
};

export const PartyBaseTab = ({ partyId }: PartyBaseTabPropsType): ReactElement => {
    const previousPartyId: string = usePrevious(partyId);
    const navigate = useNavigate();

    const [{ fetching: loading, error, data }, refetch] = useGetPartyQuery({
        variables: { _id: partyId === "new" ? "000000000000000000000000" : partyId },
        requestPolicy: "cache-and-network"
    });

    const [modal, setModal] = useState({ showModal: false, payload: null });
    const [currentParty, setCurrentParty] = useState(cloneDeep(null));
    const [_, createPartyBase] = useCreatePartyBaseMutation();
    const [__, updatePartyBase] = useUpdatePartyBaseMutation();
    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    useAlertTimeOut(alert, setAlert, 5);

    useEffect(() => {
        if (previousPartyId && previousPartyId !== partyId) {
            setCurrentParty(null);
            refetch();
        } else if (partyId === "new" && !currentParty) {
            setCurrentParty(cloneDeep(defaultPartyBase));
        } else if (data) {
            if (currentParty === null) {
                if (partyId !== "new" && data.party) {
                    const partyData = cloneDeep(data.party);
                    if (!Object.keys(AccountingFrequencyEnum).includes(partyData.accountingFrequency)) {
                        partyData.accountingFrequency = AccountingFrequencyEnum.None;
                    }
                    setCurrentParty(partyData);
                } else {
                    setCurrentParty(cloneDeep(defaultPartyBase));
                }
            }
        }
    }, [currentParty, data, partyId, previousPartyId, refetch]);

    if (loading) return <div>Loading</div>;

    if (error) return <div>{"Error: " + error.message}</div>;

    if (!currentParty) return <div></div>;

    const isEdit = currentParty._id === "000000000000000000000000" ? false : true;

    const defaultSettlementInstruction = {
        agentBusinessIdentifierCode: "-",
        beneficiaryBusinessIdentifierCode: "-",
        iban: "",
        securityAccount: "",
        buyerSellerSafe: "",
        placeOfSettlementId: data.placesOfSettlement && data.placesOfSettlement.length > 0 ? data.placesOfSettlement[0]._id : "",
        type: Object.values(PartyStandardSettlementInstructionType)[0],
        tradeType: SsiTradeType.Security
    };

    const defaultCreditRating = {
        creditRatingAgencyId: data.creditRatingAgencies && data.creditRatingAgencies.length > 0 ? data.creditRatingAgencies[0]._id : "",
        grade: ""
    };

    const parties = sortBy(data.parties, "name") as unknown as { _id: string; name: string }[];
    parties.unshift({ _id: "000000000000000000000000", name: "None" });

    const instruments = sortBy(data.instruments, "name") as unknown as { _id: string; name: string }[];
    instruments.unshift({ _id: "000000000000000000000000", name: "None" });

    const aliasOptions = (collection: CollectionNameEnum) => {
        if (collection === CollectionNameEnum.Party) {
            return parties;
        } else if (collection === CollectionNameEnum.Instrument) {
            return instruments;
        } else {
            return [{ _id: "000000000000000000000000", name: "None" }];
        }
    };

    const aliasLabel = (collection: CollectionNameEnum, documentId: string) => {
        if (collection === CollectionNameEnum.Party) {
            return <Link to={"/parties/" + documentId}>{collection}</Link>;
        } else if (collection === CollectionNameEnum.Instrument) {
            return <Link to={"/instruments/" + documentId}>{collection}</Link>;
        } else {
            return collection;
        }
    };

    const clients = data.clients.map((d) => ({ key: d._id, value: d.name, text: d.name }));
    const sortedClients = sortBy(clients, "value");

    return (
        <div>
            {modal.showModal ? (
                <YesNoModal
                    warningText={"Are you sure you want to delete party with id " + modal.payload.partyId + "?"}
                    modal={{
                        showModal: modal.showModal,
                        payload: modal.payload
                    }}
                    setModal={setModal}
                    onYes={() => {
                        updatePartyBase({ partyId: modal.payload.partyId, input: modal.payload.input }).then(() => {
                            setAlert({
                                color: "success",
                                visible: true,
                                message: `The party has been updated and deleted successfully!`
                            });
                            refetch();
                        });
                    }}
                />
            ) : null}
            <div className="form row mb-5">Basic information on Party.</div>
            <div id="partybaseform" className="partybaseform form">
                <Formik
                    enableReinitialize={true}
                    validateOnMount={true}
                    initialValues={currentParty}
                    validate={(validateValues) => {
                        const errors = {};
                        if (validateValues.types.includes("Client") && validateValues.timezone === null) {
                            errors["timezone"] = "timezone is required for clients";
                        }
                        return Object.keys(errors).length > 0 ? errors : {};
                    }}
                    validationSchema={Yup.object({
                        name: Yup.string().required().min(2),
                        longName: Yup.string().required().min(2),
                        clientIds: Yup.array().of(Yup.string().required().min(24)),
                        creditRatings: Yup.array().of(
                            Yup.object().shape({
                                creditRatingAgencyId: Yup.string().required(),
                                grade: Yup.string().matches(/^[0-9aA-zZ\-+]+$/, "Only  0-9aA-zZ\\-\\+ allowed")
                            })
                        ),
                        creditRatingScale: Yup.array().of(Yup.string().matches(/^[0-9aA-zZ\-+]+$/, "Only  0-9aA-zZ\\-\\+ allowed"))
                    })}
                    onSubmit={async (submitValues, { setSubmitting, setErrors }) => {
                        const input: any = recursivelyRemoveKey(submitValues, "__typename");

                        if (isEdit) {
                            delete input.instruments;
                            delete input.issuer;
                            delete input.fundInfo;
                            delete input.externalAccounts;
                            delete input.accounts;

                            if (input.status && input.status === PartyStatusEnum.Deleted) {
                                setModal({ showModal: true, payload: { partyId, input } });
                            } else {
                                await updatePartyBase({ partyId: partyId, input })
                                    .then((result) => {
                                        if ("error" in result && result.error) {
                                            const message = formikUrqlErrorFormater(result.error, setErrors);
                                            setAlert({ color: "danger", visible: true, message });
                                        } else {
                                            setAlert({
                                                color: "success",
                                                visible: true,
                                                message: `The party has been updated successfully!`
                                            });
                                            refetch();
                                        }
                                    })
                                    .catch((error) => {
                                        setAlert({ color: "danger", visible: true, message: error.toString() });
                                    })
                                    .finally(() => {
                                        setSubmitting(false);
                                    });
                            }
                        } else {
                            await createPartyBase({ partyId: "000000000000000000000000", input })
                                .then((result) => {
                                    if ("error" in result && result.error) {
                                        const message = formikUrqlErrorFormater(result.error, setErrors);
                                        setAlert({ color: "danger", visible: true, message });
                                    } else {
                                        navigate("/parties/" + result.data.party._id, { replace: true });
                                    }
                                })
                                .catch((error) => {
                                    setAlert({ color: "danger", visible: true, message: error.toString() });
                                });
                        }
                    }}
                >
                    {({ isSubmitting, values }) => {
                        return (
                            <Fragment>
                                <Form autoComplete="off">
                                    <div className="row">
                                        <div className="col-sm-8">
                                            <div className="form-group form-row">
                                                <MarkDownField
                                                    name="description"
                                                    label="Description"
                                                    className="col-sm-6"
                                                    disabled={isSubmitting}
                                                />
                                                {isEdit ? (
                                                    <TextField name="_id" label="Id" type="text" className="col-sm-6" disabled={true} />
                                                ) : null}
                                                <TextField name="number" label="Number" type="text" className="col-sm-2" disabled={true} />
                                                <DateField
                                                    className="col-sm-4"
                                                    name="inLedgerTradeDate"
                                                    label="In ledger trade date"
                                                    disabled={isSubmitting}
                                                />
                                            </div>
                                            <div className="form-group form-row">
                                                <TextField
                                                    name="name"
                                                    label="Name*"
                                                    type="text"
                                                    className="col-sm-6"
                                                    disabled={isSubmitting}
                                                />
                                                <SearchMultipleSelectField
                                                    name="clientIds"
                                                    label="Clients (owner)"
                                                    className="col-sm-6"
                                                    disabled={isSubmitting}
                                                    options={sortedClients}
                                                />
                                            </div>
                                            <div className="form-group form-row">
                                                <TextField
                                                    name="longName"
                                                    label="Long name"
                                                    type="text"
                                                    className="col-sm-6"
                                                    disabled={isSubmitting}
                                                />
                                                <SelectField
                                                    name="issuerType"
                                                    label="Issuer type"
                                                    options={["None", "Government", "EuCovered", "CreditInstitution"]}
                                                    className="col-sm-3"
                                                    disabled={isSubmitting}
                                                    size={12}
                                                />
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group form-row">
                                                        <TextField
                                                            name="address"
                                                            label="Address"
                                                            type="text"
                                                            className="col-sm-9"
                                                            disabled={isSubmitting}
                                                        />
                                                        <SelectField
                                                            className="col-sm-3"
                                                            name={"country"}
                                                            label={"Country"}
                                                            options={Object.values(CountryCodeEnum).sort()}
                                                            tooltips={CountryCodeEnumDescriptions}
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                    <div className="form-group form-row">
                                                        <TextField
                                                            name="website"
                                                            label="Website"
                                                            type="text"
                                                            className="col-sm-8"
                                                            disabled={isSubmitting}
                                                        />
                                                        <TextField
                                                            name="phoneNumber"
                                                            label="Phone number"
                                                            type="text"
                                                            className="col-sm-4"
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                    <div className="form-group form-row">
                                                        <TextField
                                                            name="ceo"
                                                            label="CEO"
                                                            type="text"
                                                            className="col-sm-12"
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                    <div className="form-group form-row">
                                                        <TextField
                                                            name="complaintsManager"
                                                            label="Complaints manager"
                                                            type="text"
                                                            className="col-sm-12"
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                    <div className="form-group form-row">
                                                        <TextField
                                                            name="accountant"
                                                            label="Accountant"
                                                            type="text"
                                                            className="col-sm-12"
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                    <div className="form-group form-row">
                                                        <TextField
                                                            name="email"
                                                            label="Email"
                                                            type="text"
                                                            className="col-sm-12"
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                    <div className="form-group form-row">
                                                        <TextField
                                                            name="approval"
                                                            label="Approval"
                                                            type="text"
                                                            className="col-sm-6"
                                                            disabled={isSubmitting}
                                                        />
                                                        <NullableSelectField
                                                            name="timezone"
                                                            label="Timezone"
                                                            options={Object.keys(TimezoneEnum)
                                                                .sort()
                                                                .map((option) => ({ key: option, value: option }))}
                                                            className="col-sm-6"
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <label>Types</label>
                                                        <FieldArray
                                                            name="types"
                                                            render={(arrayHelpers) => (
                                                                <Fragment>
                                                                    {values.types && values.types.length > 0 ? (
                                                                        values.types.map((item, index) => (
                                                                            <div key={index} className="form-group form-row">
                                                                                <div className="col">
                                                                                    <SelectField
                                                                                        className=""
                                                                                        name={`types[${index}]`}
                                                                                        label={null}
                                                                                        options={Object.values(PartyType).sort()}
                                                                                        disabled={false}
                                                                                    />
                                                                                </div>
                                                                                <Button
                                                                                    className="me-1 mb-2 btn-danger btn-sm"
                                                                                    type="button"
                                                                                    onClick={() => arrayHelpers.remove(index)}
                                                                                >
                                                                                    -
                                                                                </Button>
                                                                                <Button
                                                                                    className="btn-sm mb-2"
                                                                                    type="button"
                                                                                    onClick={() =>
                                                                                        arrayHelpers.insert(
                                                                                            index,
                                                                                            Object.values(PartyType)[0]
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    +
                                                                                </Button>
                                                                            </div>
                                                                        ))
                                                                    ) : (
                                                                        <Button
                                                                            className="btn-sm ms-1"
                                                                            type="button"
                                                                            onClick={() => arrayHelpers.push(Object.values(PartyType)[0])}
                                                                        >
                                                                            +
                                                                        </Button>
                                                                    )}
                                                                </Fragment>
                                                            )}
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <label>Credit ratings</label>
                                                        <FieldArray
                                                            name="creditRatings"
                                                            render={(arrayHelpers) => (
                                                                <Fragment>
                                                                    {values.creditRatings && values.creditRatings.length > 0 ? (
                                                                        values.creditRatings.map((item, index) => (
                                                                            <div key={index} className="form-group form-row">
                                                                                <div className="col">
                                                                                    <div className="form-row">
                                                                                        <SelectField
                                                                                            className="col-7"
                                                                                            name={`creditRatings[${index}].creditRatingAgencyId`}
                                                                                            label={null}
                                                                                            options={data.creditRatingAgencies.map((d) => ({
                                                                                                key: d._id,
                                                                                                value: d.name
                                                                                            }))}
                                                                                            disabled={false}
                                                                                        />
                                                                                        <TextField
                                                                                            className="col-5"
                                                                                            name={`creditRatings[${index}].grade`}
                                                                                            label={null}
                                                                                            disabled={false}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <Button
                                                                                    className="me-1 mb-2 btn-danger btn-sm"
                                                                                    type="button"
                                                                                    onClick={() => arrayHelpers.remove(index)}
                                                                                >
                                                                                    -
                                                                                </Button>
                                                                                <Button
                                                                                    className="btn-sm mb-2"
                                                                                    type="button"
                                                                                    onClick={() =>
                                                                                        arrayHelpers.insert(index, defaultCreditRating)
                                                                                    }
                                                                                >
                                                                                    +
                                                                                </Button>
                                                                            </div>
                                                                        ))
                                                                    ) : (
                                                                        <Button
                                                                            className="btn-sm ms-1"
                                                                            type="button"
                                                                            onClick={() => arrayHelpers.push(defaultCreditRating)}
                                                                        >
                                                                            +
                                                                        </Button>
                                                                    )}
                                                                </Fragment>
                                                            )}
                                                        />
                                                    </div>

                                                    <div className="form-group">
                                                        <label>Credit rating scale</label>
                                                        <FieldArray
                                                            name="creditRatingScale"
                                                            render={(arrayHelpers) => (
                                                                <Fragment>
                                                                    {values.creditRatingScale && values.creditRatingScale.length > 0 ? (
                                                                        values.creditRatingScale.map((item, index) => (
                                                                            <div key={index} className="form-group form-row">
                                                                                <div className="col">
                                                                                    <TextField
                                                                                        className=""
                                                                                        name={`creditRatingScale[${index}]`}
                                                                                        label={null}
                                                                                        disabled={false}
                                                                                    />
                                                                                </div>
                                                                                <Button
                                                                                    className="me-1 mb-2 btn-danger btn-sm"
                                                                                    type="button"
                                                                                    onClick={() => arrayHelpers.remove(index)}
                                                                                >
                                                                                    -
                                                                                </Button>
                                                                                <Button
                                                                                    className="btn-sm mb-2"
                                                                                    type="button"
                                                                                    onClick={() => arrayHelpers.insert(index, "")}
                                                                                >
                                                                                    +
                                                                                </Button>
                                                                            </div>
                                                                        ))
                                                                    ) : (
                                                                        <Button
                                                                            className="btn-sm ms-1"
                                                                            type="button"
                                                                            onClick={() => arrayHelpers.push("")}
                                                                        >
                                                                            +
                                                                        </Button>
                                                                    )}
                                                                </Fragment>
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <SelectField
                                                            className=""
                                                            name={"accountingCurrency"}
                                                            label="Accounting currency"
                                                            options={Object.values(CurrencyEnum).sort()}
                                                            disabled={isSubmitting}
                                                        />
                                                        <SelectField
                                                            className=""
                                                            name={"accountingFrequency"}
                                                            label="Accounting frequency"
                                                            options={Object.values(AccountingFrequencyEnum).sort()}
                                                            disabled={isSubmitting}
                                                        />
                                                        <SelectField
                                                            className=""
                                                            name={"accountingCompanyType"}
                                                            label="Accounting company type"
                                                            options={Object.values(AccountingCompanyTypeEnum).sort()}
                                                            disabled={isSubmitting}
                                                        />
                                                        <TextField
                                                            className=""
                                                            name={"nace"}
                                                            label={
                                                                <Link to={`//nacev2.com/en`} target="_blank">
                                                                    NACE
                                                                </Link>
                                                            }
                                                            type="text"
                                                            disabled={isSubmitting}
                                                        />
                                                        <TextField
                                                            className=""
                                                            name={"sustainalyticsId"}
                                                            label="Sustainalytics id"
                                                            type="text"
                                                            disabled={isSubmitting}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-12">
                                                    <label style={{ fontWeight: "bold" }}>Standard settlement instructions</label>
                                                    <FieldArray
                                                        name="standardSettlementInstructions"
                                                        render={(arrayHelpers) => (
                                                            <Fragment>
                                                                {values.standardSettlementInstructions &&
                                                                values.standardSettlementInstructions.length > 0 ? (
                                                                    values.standardSettlementInstructions.map((item, index) => (
                                                                        <div key={index} className="form-group form-row">
                                                                            <div className="col-10">
                                                                                <fieldset>
                                                                                    <legend>{index + 1}</legend>
                                                                                    <TextField
                                                                                        name={`standardSettlementInstructions[${index}].agentBusinessIdentifierCode`}
                                                                                        label="Agent business identifier code* (BIC)"
                                                                                        className="text-success"
                                                                                        disabled={false}
                                                                                    />
                                                                                    <TextField
                                                                                        name={`standardSettlementInstructions[${index}].beneficiaryBusinessIdentifierCode`}
                                                                                        label="Beneficiary business identifier code* (BIC)"
                                                                                        className=""
                                                                                        disabled={false}
                                                                                    />
                                                                                    <TextField
                                                                                        name={`standardSettlementInstructions[${index}].iban`}
                                                                                        label="IBAN"
                                                                                        className=""
                                                                                        disabled={false}
                                                                                    />
                                                                                    <SelectField
                                                                                        className=""
                                                                                        name={`standardSettlementInstructions[${index}].placeOfSettlementId`}
                                                                                        label={
                                                                                            <Link
                                                                                                to={
                                                                                                    "/parties/" +
                                                                                                    values.standardSettlementInstructions[
                                                                                                        index
                                                                                                    ].placeOfSettlementId
                                                                                                }
                                                                                            >
                                                                                                Place of settlement*
                                                                                            </Link>
                                                                                        }
                                                                                        options={data.placesOfSettlement.map((d) => ({
                                                                                            key: d._id,
                                                                                            value: d.name
                                                                                        }))}
                                                                                        disabled={false}
                                                                                    />
                                                                                    <TextField
                                                                                        name={`standardSettlementInstructions[${index}].securityAccount`}
                                                                                        label="Security account"
                                                                                        className=""
                                                                                        disabled={false}
                                                                                    />
                                                                                    <TextField
                                                                                        name={`standardSettlementInstructions[${index}].buyerSellerSafe`}
                                                                                        label="Buyer seller safe"
                                                                                        className=""
                                                                                        disabled={false}
                                                                                    />
                                                                                    <SelectField
                                                                                        className=""
                                                                                        name={`standardSettlementInstructions[${index}].type`}
                                                                                        label="Type*"
                                                                                        options={Object.values(
                                                                                            PartyStandardSettlementInstructionType
                                                                                        ).map((d) => ({
                                                                                            key: d,
                                                                                            value: d
                                                                                        }))}
                                                                                        disabled={false}
                                                                                    />{" "}
                                                                                    <SelectField
                                                                                        className=""
                                                                                        name={`standardSettlementInstructions[${index}].tradeType`}
                                                                                        label="Trade type*"
                                                                                        options={Object.values(SsiTradeType).map((d) => ({
                                                                                            key: d,
                                                                                            value: d
                                                                                        }))}
                                                                                        disabled={false}
                                                                                    />
                                                                                </fieldset>
                                                                            </div>
                                                                            <div className="col-2">
                                                                                <Button
                                                                                    className="me-1 mt-2 btn-danger btn-sm"
                                                                                    type="button"
                                                                                    onClick={() => arrayHelpers.remove(index)}
                                                                                >
                                                                                    -
                                                                                </Button>
                                                                                <Button
                                                                                    className="btn-sm mt-2"
                                                                                    type="button"
                                                                                    onClick={() =>
                                                                                        arrayHelpers.insert(
                                                                                            index,
                                                                                            defaultSettlementInstruction
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    +
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                ) : (
                                                                    <Button
                                                                        className="btn-sm ms-1"
                                                                        type="button"
                                                                        onClick={() => arrayHelpers.push(defaultSettlementInstruction)}
                                                                    >
                                                                        +
                                                                    </Button>
                                                                )}
                                                            </Fragment>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="form-group col-8">
                                                    <label style={{ fontWeight: "bold" }}>Aliases</label>
                                                    <FieldArray
                                                        name="aliases"
                                                        render={(arrayHelpers) => (
                                                            <Fragment>
                                                                {values.aliases && values.aliases.length > 0 ? (
                                                                    values.aliases.map((alias, index) => (
                                                                        <div key={index} className="form-group form-row">
                                                                            <div className="col-10">
                                                                                <fieldset>
                                                                                    <legend>{index + 1}</legend>
                                                                                    <SelectField
                                                                                        name={`aliases[${index}].collection`}
                                                                                        label="Collection"
                                                                                        className="text-success"
                                                                                        options={Object.values(CollectionNameEnum)}
                                                                                        disabled={isSubmitting}
                                                                                        size={5}
                                                                                    />
                                                                                    <SearchListField
                                                                                        className=""
                                                                                        name={`aliases[${index}].documentId`}
                                                                                        label={aliasLabel(
                                                                                            alias.collection,
                                                                                            alias.documentId
                                                                                        )}
                                                                                        options={aliasOptions(alias.collection)}
                                                                                        disabled={false}
                                                                                    />
                                                                                    <TextField
                                                                                        className=""
                                                                                        name={`aliases[${index}].key`}
                                                                                        label="Key"
                                                                                        disabled={false}
                                                                                    />
                                                                                    <TextField
                                                                                        className=""
                                                                                        name={`aliases[${index}].value`}
                                                                                        label="Value"
                                                                                        disabled={false}
                                                                                    />
                                                                                    <TextField
                                                                                        className=""
                                                                                        name={`aliases[${index}].comment`}
                                                                                        label="Comment"
                                                                                        disabled={false}
                                                                                    />
                                                                                </fieldset>
                                                                            </div>
                                                                            <div className="col-2">
                                                                                <Button
                                                                                    className="me-1 mt-2 btn-danger btn-sm"
                                                                                    type="button"
                                                                                    onClick={() => arrayHelpers.remove(index)}
                                                                                >
                                                                                    -
                                                                                </Button>
                                                                                <Button
                                                                                    className="btn-sm mt-2"
                                                                                    type="button"
                                                                                    onClick={() => arrayHelpers.insert(index, defaultAlias)}
                                                                                >
                                                                                    +
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                ) : (
                                                                    <Button
                                                                        className="btn-sm ms-1"
                                                                        type="button"
                                                                        onClick={() => arrayHelpers.push(defaultAlias)}
                                                                    >
                                                                        +
                                                                    </Button>
                                                                )}
                                                            </Fragment>
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group form-row ms-1">
                                                <SearchListField
                                                    className=""
                                                    name="parentId"
                                                    label={
                                                        values.parentId !== "000000000000000000000000" ? (
                                                            <Link to={"/parties/" + values.parentId}>Parent</Link>
                                                        ) : (
                                                            <div>Parent</div>
                                                        )
                                                    }
                                                    options={parties}
                                                    disabled={isSubmitting}
                                                />
                                            </div>
                                            <div className="form-group form-row">
                                                <TextField
                                                    name="legalEntityIdentifier"
                                                    label={
                                                        <Link
                                                            to={`//search.gleif.org/#/record/${values.legalEntityIdentifier}`}
                                                            target="_blank"
                                                        >
                                                            Legal entity identifier
                                                        </Link>
                                                    }
                                                    type="text"
                                                    className="col-sm-12"
                                                    disabled={isSubmitting}
                                                />
                                            </div>
                                            <div className="form-group form-row">
                                                <TextField
                                                    name="legalNumber"
                                                    label="Legal number"
                                                    type="text"
                                                    className="col-sm-12"
                                                    disabled={isSubmitting}
                                                />
                                            </div>
                                            <div className="form-group form-row">
                                                <TextField
                                                    name="regulator"
                                                    label="Regulator"
                                                    type="text"
                                                    className="col-sm-12"
                                                    disabled={isSubmitting}
                                                />
                                            </div>
                                            <div className="form-group form-row">
                                                <TextField
                                                    name="businessIdentifierCode"
                                                    label="Business Identifier Code (BIC)"
                                                    type="text"
                                                    className="col-sm-12"
                                                    disabled={isSubmitting}
                                                />
                                            </div>
                                            <div className="form-group form-row">
                                                <TextField
                                                    name="industryCode"
                                                    label="Industry code"
                                                    type="text"
                                                    className="col-sm-12"
                                                    disabled={isSubmitting}
                                                />
                                            </div>
                                            <div className="form-group form-row">
                                                <TextField
                                                    name="bloombergEquityTicker"
                                                    label="Bloomberg equity ticker"
                                                    type="text"
                                                    className="col-sm-12"
                                                    disabled={isSubmitting}
                                                />
                                            </div>
                                            <div className="form-group form-row">
                                                <TextField
                                                    name="beneficialOwner._id"
                                                    label="Beneficial owner id"
                                                    type="text"
                                                    className="col-sm-12"
                                                    disabled={isSubmitting}
                                                />
                                            </div>
                                            <div className="form-group form-row">
                                                <TextField
                                                    name="beneficialOwner.name"
                                                    label="Beneficial owner name"
                                                    type="text"
                                                    className="col-sm-12"
                                                    disabled={isSubmitting}
                                                />
                                            </div>
                                            <div className="form-group form-row">
                                                <SelectField
                                                    name="status"
                                                    label="Status"
                                                    options={Object.keys(PartyStatusEnum)}
                                                    className="col-sm-12"
                                                    disabled={isSubmitting}
                                                    size={12}
                                                />
                                            </div>
                                            <div className="form-group form-row">
                                                <TextField
                                                    name="updateTimestamp"
                                                    label="Update timestamp"
                                                    type="text"
                                                    className="col-sm-12"
                                                    disabled={true}
                                                />
                                            </div>
                                            {values.types && values.types.includes(PartyType.Client) ? (
                                                <div className="form-group form-row ms-1">
                                                    <Link to={"/transactions?clientIds=" + values._id + "&startDate=2000-01-01"}>
                                                        {"Transactions"}
                                                    </Link>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            {alert.visible ? (
                                                <Alert
                                                    style={{ marginTop: "10px" }}
                                                    variant={alert.color}
                                                    onClose={onDismissAlert}
                                                    dismissible
                                                >
                                                    {alert.message}
                                                </Alert>
                                            ) : null}
                                            <SubmitButton disabled={isSubmitting} label={isEdit ? "Update" : "Create"} />
                                        </div>
                                    </div>
                                </Form>
                            </Fragment>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};
