import React, { ReactElement, useState } from "react";
import { gql, useMutation } from "urql";
import { YesNoModal } from "../../components/YesNoModal";
import { JournalEntry } from "../../components/accounting";
import { AccountingRun } from "../../types.generated";

const UPDATE_ACCOUNTING_RUN2 = gql`
    mutation UpdateAccountingRun2($input: UpdateAccountingRunInput2!) {
        updateAccountingRun2(input: $input) {
            _id
            accountingPeriod
            number
            status
            type
            clientId
            endDate
            masterTAccountChartId
            clientTAccountChartId
            roundingDecimals
            createUserId
        }
    }
`;

interface IUpdateAccountingRunButton2 {
    input: any;
    confirmedRun: AccountingRun;
    endDate: string;
    pendingRun?: AccountingRun;
    manualJournalEntries?: JournalEntry[];
    onUpdated: () => void;
    onError: (msg: string) => void;
}

export const UpdateAccountingRunButton2 = ({
    input,
    confirmedRun,
    endDate,
    pendingRun,
    manualJournalEntries,
    onUpdated,
    onError
}: IUpdateAccountingRunButton2): ReactElement => {
    const [__stateUpdate2, executeUpdateAccountingRun2] = useMutation(UPDATE_ACCOUNTING_RUN2);
    const [modal, setModal] = useState({ showModal: false, payload: { input: null } });
    const [pendingModal, setPendingModal] = useState({
        showModal: false,
        payload: { input: null, pendingEndDate: null, manualJournalEntriesExist: false }
    });
    const disableUpdateMode = confirmedRun && confirmedRun._id && !(endDate > confirmedRun.endDate);

    return (
        <div>
            {modal.showModal ? (
                <YesNoModal
                    warningText={"Do you want to keep the manual journal entries you have created?"}
                    modal={{
                        showModal: modal.showModal,
                        payload: modal.payload
                    }}
                    noText="Keep"
                    onNo={async () => {
                        if (modal.payload.input) {
                            // Updating the accounting run and keeping the manually created journal entries
                            modal.payload.input.keepManualJournalEntries = true;
                            await executeUpdateAccountingRun2({ input: modal.payload.input })
                                .then((result) => {
                                    if (result.error) {
                                        onError(result.error.toString());
                                    } else {
                                        onUpdated();
                                    }
                                })
                                .catch((error) => {
                                    onError(error.message);
                                });
                        }
                    }}
                    setModal={setModal}
                    onYes={async () => {
                        // Updating the accounting run and keeping the manually created journal entries
                        await executeUpdateAccountingRun2({ input: modal.payload.input })
                            .then((result) => {
                                if (result.error) {
                                    console.error(result.error.toString());
                                } else {
                                    onUpdated();
                                }
                            })
                            .catch((error) => {
                                console.error(error.message);
                            });
                    }}
                />
            ) : null}
            {pendingModal.showModal ? (
                <YesNoModal
                    warningText={
                        "Are you sure you want to overwrite existing Pending accountingRun with different endDate " +
                        pendingModal.payload.pendingEndDate +
                        "?"
                    }
                    modal={{
                        showModal: pendingModal.showModal,
                        payload: pendingModal.payload
                    }}
                    setModal={setPendingModal}
                    yesText="Overwrite"
                    onYes={async () => {
                        if (pendingModal.payload.manualJournalEntriesExist) {
                            // Ask if want to keep manual journal entries
                            setModal({
                                showModal: true,
                                payload: { input }
                            });
                        } else if (pendingModal.payload.input) {
                            // Updating the accounting run
                            await executeUpdateAccountingRun2({ input: pendingModal.payload.input })
                                .then((result) => {
                                    if (result.error) {
                                        console.error(result.error.toString());
                                    } else {
                                        onUpdated();
                                    }
                                })
                                .catch((error) => {
                                    console.error(error.message);
                                });
                        }
                    }}
                />
            ) : null}
            <div className="row container">
                {disableUpdateMode ? (
                    <div>
                        <i style={{ color: "red" }}>
                            {"Confirmed accounting run exists for " + confirmedRun.endDate + ", choose a later date."}
                        </i>
                    </div>
                ) : null}
            </div>
            <div className="">
                <button
                    type="button"
                    onClick={async () => {
                        delete input.number;
                        let pendingEndDate = null;
                        let manualJournalEntriesExist = false;
                        if (endDate && pendingRun && endDate !== pendingRun.endDate) {
                            pendingEndDate = pendingRun.endDate;
                        }
                        if (manualJournalEntries && manualJournalEntries.length) {
                            manualJournalEntriesExist = true;
                        }
                        if (manualJournalEntriesExist && !pendingEndDate) {
                            setModal({
                                showModal: true,
                                payload: { input }
                            });
                        } else if (pendingEndDate) {
                            setPendingModal({
                                showModal: true,
                                payload: { input, pendingEndDate, manualJournalEntriesExist }
                            });
                        } else {
                            await executeUpdateAccountingRun2({ input })
                                .then((result) => {
                                    if (result.error) {
                                        console.error(result.error.toString());
                                    } else {
                                        onUpdated();
                                    }
                                })
                                .catch((error) => {
                                    console.error(error.message);
                                });
                        }
                    }}
                    className="save btn btn-primary btn-sm"
                    disabled={disableUpdateMode ? true : false}
                >
                    Update accounting run
                </button>
            </div>
        </div>
    );
};
