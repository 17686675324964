import React, { Fragment, ReactElement, useState, useEffect } from "react";
import { gql, useMutation } from "urql";
import { cloneDeep, keyBy, sortBy } from "lodash";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAlertTimeOut, usePrevious } from "../../common/Utils";
import stableStringify from "json-stable-stringify";

import { Svgs } from "../../../../components/src";
import { DateHelper } from "../../../../common/src";

import { AccountingRunsGrid } from "./AccountingRunsGrid";
import { ClientDateSelector2 } from "./ClientDateSelector2";
import { AccountingRunEditor2 } from "./AccountingRunEditor2";
import { YesNoModal } from "../../components/YesNoModal";
import {
    AccountingRun,
    AccountingRunStatusEnum,
    AccountingRunTypeEnum,
    Party,
    PermissionAssetEnum,
    PermissionTypeEnum
} from "../../types.generated";
import { SYSTEM_PARTY_ID } from "../../Constants";
import { ExportAccountingForm } from "../../common/accounting/ExportAccountingForm";
import { userHaveAccessTo } from "../../common/Permissions";
import {
    useGetTAccountChartsQuery,
    useGetAccountingRunsQuery,
    useGetClientsQuery,
    useGetAccountingRunsWithJournalEntriesQuery,
    GetClientsQuery
} from "./queries.generated";
import { TooltipLabel } from "../../components/TooltipLabel";
import { Alert } from "react-bootstrap";

const UPDATE_ACCOUNTING_RUN_STATUS2 = gql`
    mutation UpdateAccountingRunStatus2($_id: GraphQLObjectId!, $status: AccountingRunStatusEnum!) {
        updateAccountingRunStatus2(_id: $_id, status: $status)
    }
`;

export const ErrorOutput = ({ error }) => {
    return <div>error: {error}</div>;
};

const currentPeriod = (endDate: string): string => {
    return DateHelper.getYear(endDate).toFixed(0);
};

const getPreviousPeriod = (accountingRuns: AccountingRun[]): string => {
    //Used to check accountingFrequency on party to determine previous period, we are trying out to
    //simply look at last confirmed accountingRun instead

    //return previousAccountingPeriodYear(endDate, frequency);
    let previousDate: string = null;
    accountingRuns.forEach((ar: AccountingRun) => {
        if (ar.type !== AccountingRunTypeEnum.Transaction) {
            return;
        }
        // ar.status === "Confirmed": if create accounting in middle of month and rerun two days later
        // should not use middle of month date as startDate, should be end of last confirmed acc period
        if (previousDate === null && ar.status === AccountingRunStatusEnum.Confirmed) {
            previousDate = ar.endDate;
        }
    });
    return previousDate ? previousDate.substring(0, 4) : null;
};

const loadingResult = (id: string, endDate: string, clients: any) => {
    return (
        <div className="portfoliomanageraccounting">
            <h4>Client</h4>
            <ClientDateSelector2 id={id} endDate={endDate} clients={clients} buttonText={"None"} page={"accountingpm2"} />
            <div className="loader mt-2">
                <Svgs.Loader />
            </div>
        </div>
    );
};

export const PortfolioManagerAccounting2 = (): ReactElement => {
    const { id } = useParams<"id">();
    const { export: exportFile } = useParams<"export">();
    let { endDate } = useParams<"endDate">();

    const navigate = useNavigate();

    // default endDate
    if (!endDate) {
        endDate = new Date().toISOString().slice(0, 10);
    }

    const [modal, setModal] = useState({ showModal: false, payload: null });
    const [previousPeriod, setPreviousPeriod] = useState(null);

    // fetch clients - needed here for clientId validation
    const [{ fetching: loadingClients, error: errorClients, data: dataClients }] = useGetClientsQuery();

    // alert
    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    useAlertTimeOut(alert, setAlert, 5);

    let clientsById: Record<string, GetClientsQuery["clients"][0]> = {};
    let sortedClients: Array<GetClientsQuery["clients"][0]> = [];
    //let accountingFrequency = AccountingFrequencyEnum.None;
    if (dataClients && dataClients.clients) {
        //Check access client combined with asset accounting + transaction
        const accessClients: Array<GetClientsQuery["clients"][0]> = [];
        if (dataClients.me && dataClients.me.roles) {
            let access = false;
            for (const client of dataClients.clients) {
                if (dataClients.me.frontendRole && dataClients.me.frontendRole.assets.length === 0) {
                    access = true;
                } else {
                    for (const role of dataClients.me.roles) {
                        if (
                            role.assets.includes(PermissionAssetEnum.Accounting) &&
                            role.assets.includes(PermissionAssetEnum.Transaction) &&
                            role.clientIds.includes(client._id)
                        ) {
                            access = true;
                        }
                    }
                }
                // Exclude system party from client selection menu
                if (access && client._id !== SYSTEM_PARTY_ID) {
                    accessClients.push(client);
                }
            }
        }
        clientsById = keyBy(accessClients, "_id");
        sortedClients = sortBy(accessClients, "name");

        /*if (clientsById[id]) {
            accountingFrequency = clientsById[id].accountingFrequency;
        }*/
    }

    // fetch masterTAccountCharts
    const [{ fetching: loadingMasterTAccountCharts, error: errorMasterTAccountCharts, data: dataMasterTAccountCharts }] =
        useGetTAccountChartsQuery({
            variables: { clientId: SYSTEM_PARTY_ID }
        });

    // fetch clientTAccountCharts
    const [{ fetching: loadingClientTAccountCharts, error: errorClientTAccountCharts, data: dataClientTAccountCharts }] =
        useGetTAccountChartsQuery({
            variables: { clientId: id },
            pause: !id
        });

    // fetch previous accounting runs -
    const [{ fetching: loadingPreviousAccRuns, error: errorPreviousAccRuns, data: dataPreviousAccRuns }] =
        useGetAccountingRunsWithJournalEntriesQuery({
            variables: { clientId: id, accountingPeriod: previousPeriod },
            pause: !id || !previousPeriod,
            requestPolicy: "network-only"
        });

    const [_, executeUpdateAccountingRunStatus2] = useMutation(UPDATE_ACCOUNTING_RUN_STATUS2);

    // first fetch accounting runs and transactions
    const [{ fetching: loadingAccountingRuns, error: errorAccountingRuns, data: dataAccountingRuns }, refetchAccountingRuns] =
        useGetAccountingRunsQuery({
            variables: { clientId: id },
            pause: !id,
            requestPolicy: "network-only"
        });
    const previousDataAccountingRuns = usePrevious(dataAccountingRuns);

    useEffect(() => {
        if (dataAccountingRuns && stableStringify(dataAccountingRuns) !== stableStringify(previousDataAccountingRuns)) {
            // reorder accounting runs
            if (dataAccountingRuns.accountingRuns) {
                const accountingRuns = dataAccountingRuns.accountingRuns.slice();
                accountingRuns.sort((d1, d2) => d2.number - d1.number);
                setPreviousPeriod(getPreviousPeriod(accountingRuns));
            }
        }
    }, [dataAccountingRuns, previousDataAccountingRuns]);

    // ensure 'clients'
    if (loadingClients)
        return (
            <div className="portfoliomanageraccounting">
                <div className="loader">
                    <Svgs.Loader />
                </div>
            </div>
        );
    if (errorClients) return <ErrorOutput error={JSON.stringify(errorClients, null, 2)} />;

    // ensure 'id' & 'endDate'
    if (!id || !endDate) {
        return (
            <div className="portfoliomanageraccounting">
                <h4>Client</h4>
                <ClientDateSelector2 id={id} endDate={endDate} clients={sortedClients} buttonText={"None"} page={"accountingpm2"} />
            </div>
        );
    }

    // validate 'id'
    if (!clientsById[id]) {
        return <ErrorOutput error={`No client with ID: ${id}`} />;
    }

    // ensure MasterTAccountCharts & ClientTAccountCharts
    if (loadingMasterTAccountCharts) return loadingResult(id, endDate, dataClients.clients);
    if (loadingClientTAccountCharts) return loadingResult(id, endDate, dataClients.clients);
    if (errorMasterTAccountCharts) return <ErrorOutput error={JSON.stringify(errorMasterTAccountCharts, null, 2)} />;
    if (errorClientTAccountCharts) return <ErrorOutput error={JSON.stringify(errorClientTAccountCharts, null, 2)} />;

    let masterTAccountCharts = null;
    if (dataMasterTAccountCharts) {
        // the latest version with that name should be chosen (locked or unlocked).
        const uniqueNames = dataMasterTAccountCharts.tAccountCharts
            .map((c: any) => c.name)
            .filter((v: any, ndx: number, arr: any) => {
                return arr.indexOf(v) === ndx;
            });
        masterTAccountCharts = uniqueNames.map((n: any) => {
            return dataMasterTAccountCharts.tAccountCharts
                .filter((c: any) => c.name === n)
                .sort((a: any, b: any) => b.version - a.version)[0];
        });
        masterTAccountCharts.unshift({ _id: "000000000000000000000000", name: "None" });
    }

    let clientTAccountCharts = null;
    if (dataClientTAccountCharts) {
        // the latest version with that name should be chosen (locked or unlocked).
        const uniqueNames = dataClientTAccountCharts.tAccountCharts
            .map((c: any) => c.version)
            .filter((v: any, ndx: number, arr: any) => {
                return arr.indexOf(v) === ndx;
            });
        clientTAccountCharts = uniqueNames.map((n: any) => {
            return dataClientTAccountCharts.tAccountCharts
                .filter((c: any) => c.version === n)
                .sort((a: any, b: any) => b.version - a.version)[0];
        });
        clientTAccountCharts.unshift({ _id: "000000000000000000000000", name: "None" });
    }

    // ensure accountingRuns
    if (loadingAccountingRuns) return loadingResult(id, endDate, dataClients.clients);
    if (errorAccountingRuns) return <ErrorOutput error={JSON.stringify(errorAccountingRuns, null, 2)} />;

    // reorder accounting runs
    const accountingRuns = dataAccountingRuns.accountingRuns.slice();
    accountingRuns.sort((d1, d2) => d2.number - d1.number);

    // retrieve last accounting number
    let lastAccountingRunNumber = 0;
    let lastEndDate = null;
    accountingRuns.forEach((ar) => {
        if (ar.type === "Transaction") {
            if (lastEndDate === null || lastEndDate < ar.endDate) {
                lastEndDate = ar.endDate;
                lastAccountingRunNumber = ar.number;
            }
        }
    });

    // ensure previous accounting runs
    if (loadingPreviousAccRuns) return loadingResult(id, endDate, dataClients.clients);
    if (errorPreviousAccRuns) return <ErrorOutput error={JSON.stringify(errorPreviousAccRuns, null, 2)} />;

    const previousJournalEntries = [];
    let latestExternalNumber = 0;
    if (dataPreviousAccRuns && dataPreviousAccRuns.accountingRuns && dataPreviousAccRuns.accountingRuns.length) {
        const { accountingRuns: previousAccountingRuns } = cloneDeep(dataPreviousAccRuns);
        previousAccountingRuns.forEach((ar) => {
            if (ar.endDate !== endDate) {
                // eslint-disable-next-line prefer-spread
                previousJournalEntries.push.apply(previousJournalEntries, ar.journalEntries);
            }
        });

        const latestRunPeriod = currentPeriod(accountingRuns[0].endDate);
        const previousRunPeriod = accountingRuns.length > 1 ? currentPeriod(accountingRuns[1].endDate) : null;

        previousJournalEntries.forEach((je) => {
            if (
                je.externalNumber &&
                je.externalNumber > latestExternalNumber &&
                previousRunPeriod &&
                latestRunPeriod &&
                previousRunPeriod === latestRunPeriod
            ) {
                latestExternalNumber = je.externalNumber;
            }
            delete je.__typename;
            je.transactions.forEach((t) => {
                delete t.__typename;
                delete t.instrument;
            });
        });
    }

    // calculate pendingRun
    const pendingRun = accountingRuns.find(
        (ar: AccountingRun) =>
            ar.number === lastAccountingRunNumber &&
            ar.status === AccountingRunStatusEnum.Pending &&
            ar.type === AccountingRunTypeEnum.Transaction
    );

    // calculate preliminaryRun
    const preliminaryRun = accountingRuns.find(
        (ar: AccountingRun) => ar.status === AccountingRunStatusEnum.Preliminary && ar.type === AccountingRunTypeEnum.Transaction
    );

    // calculate confirmedRun
    const confirmedRun = accountingRuns.find(
        (ar: AccountingRun) => ar.status === AccountingRunStatusEnum.Confirmed && ar.type === AccountingRunTypeEnum.Transaction
    );

    //console.log(`id: ${id}, endDate: ${endDate}, startDate: ${startDate}`);

    //console.log("Acc runs: ", dataPreviousAccRuns.accountingRuns);

    const accountingReadWriteAccess =
        dataClients &&
        dataClients.me &&
        dataClients.me.roles &&
        userHaveAccessTo(id, PermissionAssetEnum.Accounting, dataClients.me.roles, PermissionTypeEnum.ReadWrite)
            ? true
            : false;
    return (
        <div>
            {modal.showModal ? (
                <YesNoModal
                    warningText={"Are you sure you want to delete accountingRun with id " + modal.payload + "?"}
                    modal={{
                        showModal: modal.showModal,
                        payload: modal.payload
                    }}
                    setModal={setModal}
                    onYes={() => {
                        executeUpdateAccountingRunStatus2({ _id: modal.payload, status: AccountingRunStatusEnum.Deleted })
                            .then((result) => {
                                if ("error" in result && result.error) {
                                    setAlert({ color: "danger", visible: true, message: result.error.toString() });
                                } else {
                                    refetchAccountingRuns();
                                }
                                return true;
                            })
                            .catch((error) => {
                                setAlert({ color: "danger", visible: true, message: error.message });
                            });
                    }}
                />
            ) : null}
            <Fragment>
                <div className="portfoliomanageraccounting">
                    <div id="are-buttons" className="row">
                        <div className="col-6 left">
                            <h4>Client</h4>
                            <ClientDateSelector2
                                id={id}
                                endDate={endDate}
                                clients={sortedClients}
                                buttonText={"None"}
                                page={"accountingpm2"}
                            />
                        </div>
                        <div className="col-6 right">
                            <div className="ms-auto p-2">
                                {pendingRun ? (
                                    <TooltipLabel
                                        text={
                                            <button
                                                type="button"
                                                onClick={async () => {
                                                    navigate(`${location.pathname}/newexport`);
                                                }}
                                                className="save btn btn-primary btn-sm"
                                                disabled={true}
                                            >
                                                Export accounting
                                            </button>
                                        }
                                        className={""}
                                        content="Only available when latest accounting run is Preliminary confirmed/Confirmed"
                                    ></TooltipLabel>
                                ) : (
                                    <button
                                        type="button"
                                        onClick={async () => {
                                            navigate(`${location.pathname}/newexport`);
                                        }}
                                        className="save btn btn-primary btn-sm"
                                        disabled={false}
                                    >
                                        Export accounting
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                    <p>Previous period: {previousPeriod}</p>
                    <p>Current period: {currentPeriod(endDate)}</p>
                    <AccountingRunEditor2
                        clientId={id}
                        accountingRun={pendingRun}
                        preliminaryRun={preliminaryRun}
                        confirmedRun={confirmedRun}
                        user={dataClients && dataClients.me ? dataClients.me : null}
                        lastAccountingRunNumber={lastAccountingRunNumber}
                        endDate={endDate}
                        masterTAccountCharts={masterTAccountCharts}
                        clientTAccountCharts={clientTAccountCharts}
                        accountingReadWriteAccess={accountingReadWriteAccess}
                        onUpdated={() => {
                            refetchAccountingRuns();
                        }}
                    />

                    {alert.visible ? (
                        <Alert variant={alert.color} onClose={onDismissAlert} dismissible>
                            {alert.message}
                        </Alert>
                    ) : null}

                    <AccountingRunsGrid
                        accountingRuns={accountingRuns}
                        setModal={setModal}
                        accountingReadWriteAccess={accountingReadWriteAccess}
                    />
                    {exportFile === "newexport" && accountingRuns && accountingRuns.length && (
                        <div className="exportform">
                            <Link className="close" to={`/accountingpm2/${id}/${endDate}`}>
                                x
                            </Link>
                            <ExportAccountingForm
                                clientId={id}
                                accountingRunId={accountingRuns[0]._id}
                                party={clientsById[id] as Partial<Party>}
                                latestExternalNumber={latestExternalNumber}
                            />
                        </div>
                    )}
                </div>
            </Fragment>
        </div>
    );
};
