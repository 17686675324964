import React, { Fragment, useMemo } from "react";
import { useQuery, gql } from "urql";
import { Link } from "react-router-dom";

import { ReactTable } from "../components/react-table/ReactTable";
import { Button } from "react-bootstrap";

export const QUERY_EXTENSIONS = gql`
    query extensions($filter: ExtensionsFilterInput) {
        extensions(filter: $filter) {
            _id
            clientId
            client {
                name
            }
            collection
            documentId
            status
            key
            value
            createTimestamp
            updateTimestamp
            updateUserId
            updateUserInfo {
                name
            }
        }
    }
`;

export const ExtensionsPage = (): React.ReactElement => {
    const [{ fetching, error, data }] = useQuery({ query: QUERY_EXTENSIONS });

    const columns = useMemo(() => {
        return [
            {
                header: "_id",
                accessorKey: "_id",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/extensions/${row.original._id}`}>{cellProps.getValue()}</Link>;
                },
                size: 75
            },
            {
                header: "client",
                id: "client.name",
                accessorKey: "clientId",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <Link to={`/parties/${row.original.clientId}`}>{row.original.client.name}</Link>;
                },
                size: 110
            },
            {
                header: "key",
                accessorKey: "key"
            },
            {
                header: "value",
                accessorKey: "value"
            },
            {
                header: "createTimestamp",
                accessorKey: "createTimestamp"
            },
            {
                header: "updateTimestamp",
                accessorKey: "updateTimestamp"
            },
            {
                header: "updateUser",
                accessorKey: "updateUserInfo",
                cell: (cellProps) => {
                    const { row } = cellProps;
                    return <div>{row.original.updateUserInfo.name}</div>;
                }
            }
        ];
    }, []);

    if (fetching) return <p>Loading</p>;
    if (error) return <p>error: {JSON.stringify(error, null, 2)}</p>;

    return (
        <div>
            <Fragment>
                <div className="row">
                    <div className="col">
                        <Button
                            type="button"
                            className="float-end btn btn-sm"
                            onClick={() => {
                                const win = window.open("/extensions/new", "_self");
                                win.focus();
                            }}
                        >
                            New extension
                        </Button>
                    </div>
                </div>
                <p>Number of extensions: {data.extensions.length}</p>
                <ReactTable columns={columns} data={data.extensions} />
            </Fragment>
        </div>
    );
};
