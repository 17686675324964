import React, { ReactElement, useState } from "react";
import { gql, useQuery, useMutation } from "urql";

import { DateHelper, emptyObjectId } from "../../../../common/src";
import { recursivelyRemoveKey } from "../../../../common/src/utils/FormatFunctions";

import {
    AccountingRun,
    AccountingRunStatusEnum,
    AccountingRunTypeEnum,
    CreateAccountingRunInput2,
    UpdateAccountingRunInput2
} from "../../types.generated";
import { ConfirmAccountingRunButton2 } from "./ConfirmAccountingRunButton2";
import { TAccountChartEditor } from "./TAccountChartEditor";
import { useAlertTimeOut } from "../../common/Utils";
import { getTAccountChart } from "./queries";
import { Alert } from "react-bootstrap";
import { CreateAccountingRunButton2 } from "./CreateAccountingRunButton2";
import { UpdateAccountingRunButton2 } from "./UpdateAccountingRunButton2";

const UPDATE_ACCOUNTING_RUN2 = gql`
    mutation UpdateAccountingRun2($input: UpdateAccountingRunInput2!) {
        updateAccountingRun2(input: $input) {
            _id
            accountingPeriod
            number
            status
            type
            clientId
            endDate
            masterTAccountChartId
            clientTAccountChartId
            roundingDecimals
            createUserId
        }
    }
`;

const GET_MANUAL_JOURNAL_ENTRIES = gql`
    query GetManualJournalEntries($accountingRunId: GraphQLObjectId!) {
        journalEntries(accountingRunId: $accountingRunId, batch: M) {
            _id
            batch
            number
            effectiveDate
            description
            portfolioTransactionId
            clientId
            transactions {
                amount
                tAccountNumber
                type
                instrumentId
            }
        }
    }
`;

export const currentPeriod = (endDate: string): string => {
    return DateHelper.getYear(endDate).toFixed(0);
};

interface IAccountingRunEditor2 {
    clientId: any;
    accountingRun: AccountingRun;
    preliminaryRun: AccountingRun;
    confirmedRun: AccountingRun;
    user: any;
    lastAccountingRunNumber: number;
    endDate: string;
    masterTAccountCharts: any[];
    clientTAccountCharts: any[];
    accountingReadWriteAccess: boolean;
    onUpdated: () => void;
}

export const AccountingRunEditor2 = ({
    clientId,
    accountingRun,
    preliminaryRun,
    confirmedRun,
    user,
    lastAccountingRunNumber,
    endDate,
    masterTAccountCharts,
    clientTAccountCharts,
    accountingReadWriteAccess,
    onUpdated
}: IAccountingRunEditor2): ReactElement => {
    const [masterTAccountChartId, setMasterTAccountChartId] = useState(accountingRun ? accountingRun.masterTAccountChartId : emptyObjectId);
    const [clientTAccountChartId, setClientTAccountChartId] = useState(
        accountingRun
            ? accountingRun.clientTAccountChartId
            : clientTAccountCharts && clientTAccountCharts.length
              ? clientTAccountCharts[clientTAccountCharts.length - 1]._id
              : emptyObjectId
    );

    const [__stateUpdate2, executeUpdateAccountingRun2] = useMutation(UPDATE_ACCOUNTING_RUN2);

    const isPending = !!accountingRun;

    // calculate accountingNumber
    const accountingRunNumber = accountingRun ? accountingRun.number : lastAccountingRunNumber + 1;

    // fetch masterTAccountChart
    const [{ fetching: loadingMasterTAccountChart, error: errorMasterTAccountChart, data: dataMasterTAccountChart }] = useQuery({
        query: getTAccountChart,
        variables: { id: masterTAccountChartId },
        pause: masterTAccountChartId === emptyObjectId
    });

    // fetch clientTAccountChart
    const [{ fetching: loadingClientTAccountChart, error: errorClientTAccountChart, data: dataClientTAccountChart }] = useQuery({
        query: getTAccountChart,
        variables: { id: clientTAccountChartId },
        pause: clientTAccountChartId === emptyObjectId
    });

    // fetch manual journal entries
    const [{ fetching: loadingManualJournalEntries, error: errorManualJournalEntries, data: dataManualJournalEntries }] = useQuery({
        query: GET_MANUAL_JOURNAL_ENTRIES,
        variables: { accountingRunId: accountingRun ? accountingRun._id : emptyObjectId },
        pause: isPending === false
    });

    // alert
    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    useAlertTimeOut(alert, setAlert, 5);

    // error
    if (errorMasterTAccountChart) return <div>{errorMasterTAccountChart.toString()}</div>;
    if (errorClientTAccountChart) return <div>{errorClientTAccountChart.toString()}</div>;
    if (errorManualJournalEntries) return <div>{errorManualJournalEntries.toString()}</div>;

    // loading
    if (loadingMasterTAccountChart) return <div>loading...</div>;
    if (loadingClientTAccountChart) return <div>loading...</div>;
    if (loadingManualJournalEntries) return <div>loading...</div>;

    let manualJournalEntries = [];
    if (dataManualJournalEntries && dataManualJournalEntries.journalEntries && manualJournalEntries.length === 0) {
        manualJournalEntries = dataManualJournalEntries.journalEntries;
    }

    let inputUpdate: UpdateAccountingRunInput2 = {
        _id: accountingRun ? accountingRun._id : null,
        endDate,
        type: AccountingRunTypeEnum.Transaction,
        masterTAccountChartId: masterTAccountChartId,
        clientTAccountChartId: clientTAccountChartId
    };
    let inputCreate: CreateAccountingRunInput2 = {
        clientId,
        endDate,
        type: AccountingRunTypeEnum.Transaction,
        masterTAccountChartId: masterTAccountChartId,
        clientTAccountChartId: clientTAccountChartId
    };

    //TODO: remove??!!
    inputUpdate = recursivelyRemoveKey(inputUpdate, "__typename");
    inputCreate = recursivelyRemoveKey(inputCreate, "__typename");

    return (
        <div>
            <p>Current run number: {accountingRunNumber}</p>
            <p>
                Master T-account chart:&nbsp;
                <TAccountChartEditor
                    isPendingRun={isPending}
                    actualTAccountChartId={accountingRun ? accountingRun.masterTAccountChartId : emptyObjectId}
                    tAccountChart={dataMasterTAccountChart ? dataMasterTAccountChart.tAccountChart : null}
                    allTAccountCharts={masterTAccountCharts}
                    onChange={(id) => {
                        setMasterTAccountChartId(id);
                    }}
                    onSaveClick={async () => {
                        await executeUpdateAccountingRun2({ input: inputUpdate })
                            .then((result) => {
                                if (result.error) {
                                    setAlert({
                                        color: "danger",
                                        visible: true,
                                        message: result.error.toString()
                                    });
                                } else {
                                    onUpdated();
                                }
                            })
                            .catch((error) => {
                                setAlert({
                                    color: "danger",
                                    visible: true,
                                    message: error.message
                                });
                            });
                    }}
                />
            </p>
            <p>
                Client T-account chart:&nbsp;{" "}
                <TAccountChartEditor
                    isPendingRun={isPending}
                    actualTAccountChartId={accountingRun ? accountingRun.clientTAccountChartId : emptyObjectId}
                    tAccountChart={dataClientTAccountChart ? dataClientTAccountChart.tAccountChart : null}
                    allTAccountCharts={clientTAccountCharts}
                    onChange={(id) => {
                        setClientTAccountChartId(id);
                    }}
                    onSaveClick={async () => {
                        await executeUpdateAccountingRun2({ input: inputUpdate })
                            .then((result) => {
                                if (result.error) {
                                    setAlert({ color: "danger", visible: true, message: result.error.toString() });
                                } else {
                                    onUpdated();
                                }
                            })
                            .catch((error) => {
                                setAlert({ color: "danger", visible: true, message: error.message });
                            });
                    }}
                />
            </p>
            <p>Pending id: {isPending ? accountingRun._id : "(none)"}</p>

            <div id="are-buttons" className="row">
                <div className="col-12">
                    {isPending && accountingReadWriteAccess ? (
                        <UpdateAccountingRunButton2
                            input={inputUpdate}
                            confirmedRun={confirmedRun}
                            endDate={endDate}
                            pendingRun={isPending && accountingRun ? accountingRun : null}
                            manualJournalEntries={manualJournalEntries}
                            onUpdated={() => {
                                onUpdated();
                            }}
                            onError={(message) => {
                                setAlert({ color: "danger", visible: true, message });
                            }}
                        />
                    ) : !isPending && !preliminaryRun && accountingReadWriteAccess ? (
                        <CreateAccountingRunButton2
                            input={inputCreate}
                            confirmedRun={confirmedRun}
                            onCreated={() => {
                                onUpdated();
                            }}
                            onError={(message) => {
                                setAlert({ color: "danger", visible: true, message });
                            }}
                        />
                    ) : null}
                    &nbsp;
                    {(isPending && accountingReadWriteAccess) || preliminaryRun ? (
                        <ConfirmAccountingRunButton2
                            accountingRun={isPending ? accountingRun : preliminaryRun}
                            user={user}
                            updateToStatus={
                                isPending ? AccountingRunStatusEnum.Preliminary : preliminaryRun ? AccountingRunStatusEnum.Confirmed : null
                            }
                            onConfirmed={() => {
                                onUpdated();
                            }}
                            onError={(message) => {
                                setAlert({ color: "danger", visible: true, message });
                            }}
                        />
                    ) : null}
                </div>
            </div>

            {alert.visible ? (
                <Alert variant={alert.color} onClose={onDismissAlert} dismissible>
                    {alert.message}
                </Alert>
            ) : null}
        </div>
    );
};
