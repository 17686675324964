import React, { Fragment, useState, ReactElement } from "react";
import { gql, useMutation } from "urql";
import { cloneDeep, sortBy } from "lodash";
import { Formik, Form, FieldHookConfig, useField, useFormikContext, FieldArray } from "formik";
import { Alert, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import { formikUrqlErrorFormater } from "../../common/formik-urql-error-helper";
import { SubmitButton, TextField, SelectField, CheckboxItem, SearchListField, NumberField, DateField } from "../../components/form";
import {
    CurrencyEnum,
    DefaultCashInstrument,
    PartyAccountType,
    PartyBenchmark,
    PartyAccount,
    PartyAccountWeight,
    ModelIndexBenchmark
} from "../../types.generated";
import { emptyObjectId } from "../../../../common/src";
import { useAlertTimeOut } from "../../common/Utils";

const CREATE_PARTY_ACCOUNT = gql`
    mutation createPartyAccount($partyId: GraphQLObjectId!, $input: PartyAccountInput!) {
        party: createPartyAccount(partyId: $partyId, input: $input) {
            _id
        }
    }
`;

const UPDATE_PARTY_ACCOUNT = gql`
    mutation updatePartyAccount($partyId: GraphQLObjectId!, $input: PartyAccountInput!) {
        party: updatePartyAccount(partyId: $partyId, input: $input) {
            _id
        }
    }
`;

export const defaultBenchmark: PartyBenchmark = {
    __typename: "PartyBenchmark",
    instrumentId: null,
    offset: 0,
    currency: CurrencyEnum.SEK,
    comment: "",
    showBenchmark: false,
    showExternal: false,
    mainBenchmark: false
};

export const defaultWeight: PartyAccountWeight = {
    __typename: "PartyAccountWeight",
    date: new Date().toISOString().slice(0, 10),
    expectedReturn: 0,
    min: 0,
    max: 0,
    model: 0
};

interface IPartyInstrumentInput {
    value: any;
    partyInstruments: any[];
    onDelete: any;
    key: string;
}
const PartyInstrumentInput = ({ value, partyInstruments, onDelete }: IPartyInstrumentInput) => {
    const [currentValue, setCurrentValue] = useState(value);
    const handleChange = (e) => {
        const val = {
            partyInstrumentId: e.target.value,
            currency: ""
        };
        value.partyInstrumentId = e.target.value;
        const instr = partyInstruments.find((i) => i._id === e.target.value);
        if (instr) {
            value.currency = instr.currency;
            val.currency = instr.currency;
        }
        setCurrentValue(val);
    };

    const remove = () => {
        onDelete(value);
    };
    return (
        <div className="form-group form-row">
            <div className="col-11">
                <select
                    name="type"
                    value={currentValue.partyInstrumentId}
                    onChange={handleChange}
                    disabled={false}
                    className="form-control form-select"
                >
                    {partyInstruments.map((i) => (
                        <option key={i._id} value={i._id}>
                            {i.name}
                        </option>
                    ))}
                </select>
            </div>
            <div className="col-1">
                <button type="button" onClick={remove} className="btn btn-danger btn-sm">
                    x
                </button>
            </div>
        </div>
    );
};

type CashInstrumentsFieldPropTypes = FieldHookConfig<any[]> & {
    label: string;
    className: string;
    disabled: boolean;
    partyInstruments: any[];
};

const CashInstrumentsField = ({ label, className, partyInstruments, ...props }: CashInstrumentsFieldPropTypes): React.ReactElement => {
    const [field] = useField<any[]>(props);
    const [values, setValues] = useState(field.value || []);
    const { setFieldValue } = useFormikContext();

    const onAddNew = () => {
        const available = partyInstruments.filter((n) => !values.map((v) => v.partyInstrumentId).includes(n._id));
        if (available.length > 0) {
            values.push({ partyInstrumentId: available[0]._id, currency: available[0].currency });
            setValues(values);
            setFieldValue(field.name, values);
        }
    };

    const onDelete = (e) => {
        const item = values.find((v) => v.partyInstrumentId.toString() === e.partyInstrumentId.toString() && v.currency === e.currency);
        const ndx = values.indexOf(item);
        if (ndx > -1) {
            values.splice(ndx, 1);
            setValues(values);
            setFieldValue(field.name, values);
        }
    };

    return (
        <div className={"form-group" + (className ? " " + className : "")}>
            <label>{label}</label> &nbsp;
            <button type="button" onClick={onAddNew} className="btn btn-secondary btn-sm">
                +
            </button>
            {values.map((instr) => (
                <PartyInstrumentInput
                    key={"pii-" + instr.partyInstrumentId.toString()}
                    value={instr}
                    partyInstruments={partyInstruments}
                    onDelete={onDelete}
                />
            ))}
        </div>
    );
};

interface IPartyAccountForm {
    partyId: string;
    account: {
        _id: string;
        defaultCashInstruments: DefaultCashInstrument[];
        defaultDebtInstruments: DefaultCashInstrument[];
        defaultAccruedInstruments: DefaultCashInstrument[];
        transientCashInstruments: DefaultCashInstrument[];
        benchmarks: PartyBenchmark[];
        childrenAccounts: PartyAccount[];
        weights: PartyAccountWeight[];
        name: string;
        type: PartyAccountType;
        modelIndexBenchmark: ModelIndexBenchmark;
    };
    externalAccounts: {
        _id: string;
        name: string;
    }[];
    accounts: {
        _id: string;
        name: string;
        description: string;
        type: PartyAccountType;
    }[];
    partyInstruments: {
        _id: string;
        name: string;
    }[];
    instruments: {
        _id: string;
        name: string;
    }[];
    onCreate: any;
    onUpdate: any;
}

export const PartyAccountForm = ({
    partyId,
    account,
    externalAccounts,
    accounts,
    partyInstruments,
    instruments,
    onCreate,
    onUpdate
}: IPartyAccountForm): ReactElement => {
    const isEditMode = !!account;
    const newAccount = {
        _id: emptyObjectId, // this will be removed by resolver in backend
        defaultAccount: false,
        name: "",
        description: "",
        securityExternalAccountId: null,
        defaultCashInstruments: [],
        defaultAccruedInstruments: [],
        defaultDebtInstruments: [],
        transientCashInstruments: [],
        type: PartyAccountType.Physical,
        parentAccountId: emptyObjectId,
        benchmarks: [],
        childrenAccounts: [],
        weights: [],
        modelIndexBenchmark: null
    };
    const [currentAccount] = useState(account ? cloneDeep(account) : newAccount);
    const [_, updatePartyAccount] = useMutation(UPDATE_PARTY_ACCOUNT);
    const [__, createPartyAccount] = useMutation(CREATE_PARTY_ACCOUNT);
    const [alert, setAlert] = useState({ color: "info", visible: false, message: "" });
    const onDismissAlert = () => setAlert({ color: "info", visible: false, message: "" });

    useAlertTimeOut(alert, setAlert, 5);

    const securityExternalAccountOptions = externalAccounts.map((d) => ({ key: d._id, value: d.name }));
    securityExternalAccountOptions.unshift({ key: "000000000000000000000000", value: "None" });
    if (!newAccount.securityExternalAccountId && securityExternalAccountOptions && securityExternalAccountOptions.length) {
        newAccount.securityExternalAccountId = securityExternalAccountOptions[0].key;
    }

    return (
        <div id="partyaccountform" className="partyaccountform form">
            <Formik
                enableReinitialize={true}
                initialValues={currentAccount}
                onSubmit={async (submitValues, { setSubmitting, setErrors }) => {
                    //console.log("submitValues: ", submitValues);
                    const input: any = cloneDeep(submitValues);
                    delete input.__typename;
                    input.defaultCashInstruments.forEach((t) => {
                        delete t.__typename;
                    });
                    input.defaultDebtInstruments.forEach((t) => {
                        delete t.__typename;
                    });
                    input.defaultAccruedInstruments.forEach((t) => {
                        delete t.__typename;
                    });
                    input.transientCashInstruments.forEach((t) => {
                        delete t.__typename;
                    });
                    input.benchmarks.forEach((t) => {
                        delete t.__typename;
                    });
                    input.weights.forEach((weight) => {
                        delete weight.__typename;
                        if (weight.date instanceof Date) {
                            weight.date = weight.date.toISOString().slice(0, 10);
                        }
                    });
                    if (input.modelIndexBenchmark) {
                        delete input.modelIndexBenchmark.__typename;
                    }

                    delete input.childrenAccounts;

                    if (isEditMode) {
                        await updatePartyAccount({ partyId, input })
                            .then((result) => {
                                if ("error" in result && result.error) {
                                    const message = formikUrqlErrorFormater(result.error, setErrors);
                                    setAlert({ color: "danger", visible: true, message });
                                } else {
                                    setAlert({ color: "success", visible: true, message: `The account has been updated successfully!` });
                                    onUpdate(result.data.party._id);
                                }
                            })
                            .catch((error) => {
                                setAlert({ color: "danger", visible: true, message: error.toString() });
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    } else {
                        await createPartyAccount({ partyId, input })
                            .then((result) => {
                                if ("error" in result && result.error) {
                                    const message = formikUrqlErrorFormater(result.error, setErrors);
                                    setAlert({ color: "danger", visible: true, message });
                                } else {
                                    setAlert({ color: "success", visible: true, message: `New account has been created successfully!` });
                                    setSubmitting(false);
                                    onCreate(result.data.party._id);
                                }
                            })
                            .catch((error) => {
                                setAlert({ color: "danger", visible: true, message: error.toString() });
                            })
                            .finally(() => {
                                setSubmitting(false);
                            });
                    }
                }}
            >
                {({ isSubmitting, values }) => {
                    return (
                        <Fragment>
                            <div className="row">
                                <div className="col">
                                    <h4>Party Account</h4>
                                    <Form autoComplete="off">
                                        <div className="form-row">
                                            {isEditMode ? (
                                                <TextField name="number" label="Number" type="text" className="col-2" disabled={true} />
                                            ) : null}
                                            {isEditMode ? (
                                                <TextField name="name" label="Name" type="text" className="col-4" disabled={isSubmitting} />
                                            ) : null}
                                            {isEditMode ? (
                                                <TextField
                                                    name="description"
                                                    label="Description"
                                                    type="text"
                                                    className="col-6"
                                                    disabled={isSubmitting}
                                                />
                                            ) : null}
                                        </div>
                                        <div className="form-row">
                                            {!isEditMode ? (
                                                <TextField
                                                    name="description"
                                                    label="Description"
                                                    type="text"
                                                    className="col-4"
                                                    disabled={isSubmitting}
                                                />
                                            ) : null}
                                            <SelectField
                                                name="type"
                                                label="Type"
                                                options={Object.keys(PartyAccountType)}
                                                className={isEditMode ? "col-6" : "col-4"}
                                                disabled={isSubmitting}
                                                size={12}
                                            />
                                            <SelectField
                                                name="parentAccountId"
                                                label="Parent account"
                                                options={accounts
                                                    .filter(function (account) {
                                                        if (
                                                            (values._id && values._id.toString() === account._id.toString()) ||
                                                            account.type !== PartyAccountType.Sum
                                                        ) {
                                                            return false;
                                                        }
                                                        return true;
                                                    })
                                                    .map(function (account) {
                                                        return { key: account._id, value: `${account.name} ${account.description}` };
                                                    })
                                                    .concat([{ key: emptyObjectId, value: `None` }])}
                                                className={isEditMode ? "col-6" : "col-4"}
                                                disabled={isSubmitting}
                                                size={12}
                                            />
                                        </div>
                                        <div className="form-row">
                                            {securityExternalAccountOptions && securityExternalAccountOptions.length ? (
                                                <SelectField
                                                    name="securityExternalAccountId"
                                                    label="Security external account"
                                                    options={securityExternalAccountOptions}
                                                    className="col-6"
                                                    disabled={isSubmitting}
                                                    size={12}
                                                />
                                            ) : null}
                                            <CheckboxItem
                                                className=""
                                                name="defaultAccount"
                                                label="Default account"
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                        <div className="form-row">
                                            <CashInstrumentsField
                                                name="defaultCashInstruments"
                                                className="col-6"
                                                label="Default cash instruments"
                                                partyInstruments={partyInstruments}
                                                disabled={false}
                                            />
                                            <CashInstrumentsField
                                                name="transientCashInstruments"
                                                className="col-6"
                                                label="Transient cash instruments"
                                                partyInstruments={partyInstruments}
                                                disabled={false}
                                            />
                                        </div>
                                        <div className="form-row">
                                            <CashInstrumentsField
                                                name="defaultDebtInstruments"
                                                className="col-6"
                                                label="Default debt instruments"
                                                partyInstruments={partyInstruments}
                                                disabled={false}
                                            />
                                            <TextField
                                                name="modelIndexBenchmark.name"
                                                label="Model index benchmark name"
                                                type="text"
                                                className="col-6"
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                        <div className="form-row">
                                            <CashInstrumentsField
                                                name="defaultAccruedInstruments"
                                                className="col-6"
                                                label="Default accrued instruments"
                                                partyInstruments={partyInstruments}
                                                disabled={false}
                                            />
                                        </div>
                                        {isEditMode ? (
                                            <FieldArray
                                                name="childrenAccounts"
                                                render={() => (
                                                    <div className="form row">
                                                        {values.childrenAccounts && values.childrenAccounts.length > 0
                                                            ? values.childrenAccounts.map((childAccount, index) => {
                                                                  childAccount.nameDescription =
                                                                      childAccount.name + " " + childAccount.description;
                                                                  return (
                                                                      <div key={index} className="col-6">
                                                                          <TextField
                                                                              className=""
                                                                              name={`childrenAccounts[${index}].nameDescription`}
                                                                              label="Child account"
                                                                              disabled={true}
                                                                          />
                                                                      </div>
                                                                  );
                                                              })
                                                            : null}
                                                    </div>
                                                )}
                                            />
                                        ) : null}

                                        <div className="row">
                                            <h6 className="mt-2">Benchmarks</h6>
                                            <FieldArray
                                                name="benchmarks"
                                                render={(arrayHelpers) => (
                                                    <div className="form col-6">
                                                        {values.benchmarks && values.benchmarks.length > 0 ? (
                                                            values.benchmarks.map((benchmark, index) => (
                                                                <div key={index}>
                                                                    <SearchListField
                                                                        className=""
                                                                        name={`benchmarks[${index}].instrumentId`}
                                                                        label={
                                                                            <Link to={"/instruments/" + benchmark.instrumentId}>
                                                                                Instrument
                                                                            </Link>
                                                                        }
                                                                        options={sortBy(instruments, "name")}
                                                                        disabled={false}
                                                                    />
                                                                    <NumberField
                                                                        className=""
                                                                        name={`benchmarks[${index}].offset`}
                                                                        label="Offset"
                                                                        disabled={false}
                                                                    />
                                                                    <SelectField
                                                                        className=""
                                                                        name={`benchmarks[${index}].currency`}
                                                                        label="Currency"
                                                                        options={Object.values(CurrencyEnum).sort()}
                                                                        disabled={false}
                                                                    />
                                                                    <TextField
                                                                        className=""
                                                                        name={`benchmarks[${index}].comment`}
                                                                        label="Comment"
                                                                        disabled={false}
                                                                    />
                                                                    <CheckboxItem
                                                                        className=""
                                                                        name={`benchmarks[${index}].showBenchmark`}
                                                                        label="Show benchmark"
                                                                        disabled={false}
                                                                    />
                                                                    <CheckboxItem
                                                                        className=""
                                                                        name={`benchmarks[${index}].mainBenchmark`}
                                                                        label="Main benchmark"
                                                                        disabled={false}
                                                                    />

                                                                    <CheckboxItem
                                                                        className=""
                                                                        name={`benchmarks[${index}].showExternal`}
                                                                        label="Show external"
                                                                        disabled={false}
                                                                    />
                                                                    <Button
                                                                        className="btn-sm me-1"
                                                                        type="button"
                                                                        onClick={() => arrayHelpers.remove(index)}
                                                                    >
                                                                        -
                                                                    </Button>
                                                                    <Button
                                                                        className="btn-sm"
                                                                        type="button"
                                                                        onClick={() =>
                                                                            arrayHelpers.insert(index, cloneDeep(defaultBenchmark))
                                                                        }
                                                                    >
                                                                        +
                                                                    </Button>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <Button
                                                                className=""
                                                                type="button"
                                                                onClick={() => arrayHelpers.push(cloneDeep(defaultBenchmark))}
                                                            >
                                                                {/* show this when user has removed all items */}
                                                                Add a benchmark
                                                            </Button>
                                                        )}
                                                    </div>
                                                )}
                                            />
                                        </div>

                                        <div className="row">
                                            <h6 className="mt-2">Weights</h6>
                                            <FieldArray
                                                name="weights"
                                                render={(arrayHelpers) => (
                                                    <div className="form col-6">
                                                        {values.weights && values.weights.length > 0 ? (
                                                            values.weights.map((weight, index) => (
                                                                <div key={index}>
                                                                    <DateField
                                                                        name={`weights[${index}].date`}
                                                                        label="Date"
                                                                        className=""
                                                                        disabled={false}
                                                                    />
                                                                    <NumberField
                                                                        className=""
                                                                        name={`weights[${index}].model`}
                                                                        label="Model"
                                                                        disabled={false}
                                                                    />
                                                                    <NumberField
                                                                        className=""
                                                                        name={`weights[${index}].min`}
                                                                        label="Min"
                                                                        disabled={false}
                                                                    />
                                                                    <NumberField
                                                                        className=""
                                                                        name={`weights[${index}].max`}
                                                                        label="Max"
                                                                        disabled={false}
                                                                    />
                                                                    <NumberField
                                                                        className=""
                                                                        name={`weights[${index}].expectedReturn`}
                                                                        label="Expected return"
                                                                        disabled={false}
                                                                    />
                                                                    <Button
                                                                        className="btn-sm me-1"
                                                                        type="button"
                                                                        onClick={() => arrayHelpers.remove(index)}
                                                                    >
                                                                        -
                                                                    </Button>
                                                                    <Button
                                                                        className="btn-sm"
                                                                        type="button"
                                                                        onClick={() => arrayHelpers.insert(index, cloneDeep(defaultWeight))}
                                                                    >
                                                                        +
                                                                    </Button>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <Button
                                                                className=""
                                                                type="button"
                                                                onClick={() => arrayHelpers.push(cloneDeep(defaultWeight))}
                                                            >
                                                                {/* show this when user has removed all items */}
                                                                Add a weight
                                                            </Button>
                                                        )}
                                                    </div>
                                                )}
                                            />
                                        </div>
                                        {alert.visible ? (
                                            <Alert style={{ marginTop: "10px" }} variant={alert.color} onClose={onDismissAlert} dismissible>
                                                {alert.message}
                                            </Alert>
                                        ) : null}
                                        <div className="d-inline-flex align-items-baseline">
                                            <SubmitButton disabled={isSubmitting} label={isEditMode ? "Update" : "Create"} />

                                            <a
                                                href={`/transactionitems?clientIds=${partyId}&account.name=${currentAccount.name}`}
                                                className="ms-4"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Transactions
                                            </a>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </Fragment>
                    );
                }}
            </Formik>
        </div>
    );
};
